import LoginIcon from '@mui/icons-material/Login';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import MenuIcon from '@mui/icons-material/Menu';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import SupportIcon from '@mui/icons-material/Support';
import {
  IconButton, ListItemIcon, Menu, MenuItem, Typography,
} from '@mui/material';
import { MouseEvent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { OfflineDialogsContext } from '../../../providers/OfflineDialogsContext.ts';

const HeaderOfflineMenu = () => {
  const { t } = useTranslation();
  const { openLoginDialog, openRegisterDialog } = useContext(OfflineDialogsContext);
  const navigate = useNavigate();
  const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id="offline-menu-button"
        onClick={handleClick}
        size="small"
        aria-controls={open ? 'offline-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <MenuIcon style={{ color: 'white' }} fontSize="large" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="offline-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div className="all-menu-offline">
          <MenuItem id="menu-item-login-button" onClick={openLoginDialog}>
            <ListItemIcon>
              <LoginIcon color="primary" />
            </ListItemIcon>
            <Typography fontWeight="bold">
              { t('buttons.login') }
              &nbsp;👋
            </Typography>
          </MenuItem>

          <MenuItem onClick={openRegisterDialog}>
            <ListItemIcon>
              <RocketLaunchIcon color="primary" />
            </ListItemIcon>
            <Typography fontWeight="bold">{ t('buttons.register') }</Typography>
          </MenuItem>

          <MenuItem onClick={() => navigate('/')}>
            <ListItemIcon>
              <DirectionsCarIcon />
            </ListItemIcon>
            <Typography>{t('menu.marketplace')}</Typography>
          </MenuItem>
        </div>
        <MenuItem
          onClick={() => {
            (window as any).Tawk_API?.maximize();
          }}
        >
          <ListItemIcon>
            <SupportIcon fontSize="small" />
          </ListItemIcon>
          {t('buttons.contact-our-support')}
        </MenuItem>
      </Menu>
    </>
  );
};

export default HeaderOfflineMenu;
