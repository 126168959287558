import { ReactNode, useEffect } from 'react';
import useLang from '../../hooks/useLang.ts';

interface Props {
  children: ReactNode,
}

const TawktoChatProvider = ({ children } : Props) => {
  const lang = useLang();

  useEffect(() => {
    (function () {
      const id = lang === 'fr' ? '1i84hlmkc' : '1i84inu4v';
      const s1 = document.createElement('script');
      const s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = `https://embed.tawk.to/66ebcd87e5982d6c7bb08b9d/${id}`;
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      (s0 as any).parentNode.insertBefore(s1, s0);
    }());
  }, []);

  useEffect(() => {
    (function () {
      (window as any).Tawk_API?.switchWidget?.({
        propertyId: '66ebcd87e5982d6c7bb08b9d',
        widgetId: lang === 'fr' ? '1i84hlmkc' : '1i84inu4v',
      });
    }());
  }, [ lang ]);

  return (
    <>
      { children }
    </>
  );
};

export default TawktoChatProvider;
