import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useReactiveUserConnected from '../../hooks/data/useReactiveUserConnected.ts';
import PageLoader from '../ui-kit/utils/PageLoader.tsx';

interface Props {
  children: ReactNode,
}

const LoggedInGuard = ({ children }: Props) => {
  const { isConnected, user } = useReactiveUserConnected();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isConnected) {
      navigate('/login');
    }
  }, [ isConnected, user ]);

  if (!isConnected) {
    return <PageLoader />;
  }

  return (
    <>
      { children }
    </>
  );
};

export default LoggedInGuard;
