import { useReactiveVar } from '@apollo/client';
import {
  loggedIn, useMeLazyQuery, User, userConnected, UserSubscriptionStatus,
} from '../../graphql';
import useValidateUserData from './useValidateUserData.ts';

export default function useReactiveUserConnected() {
  const user = useReactiveVar(userConnected);
  const isConnected = useReactiveVar(loggedIn);
  const validateUserData = useValidateUserData();
  const [ fetchCurrentUser, { refetch } ] = useMeLazyQuery();

  const refetchMe = async () => {
    if (user) {
      const { data } = await refetch();

      if (data) {
        userConnected(data.getLoggedUser as User);
      }

      return;
    }

    const { data } = await fetchCurrentUser();

    if (data) {
      userConnected(data.getLoggedUser as User);
    }
  };

  return {
    isConnected,
    isUserDataValid: user ? validateUserData(user) : false,
    isUserAccountValidated: user?.validatedAt !== null,
    isSeller: user && user.latestSellerSubscription && user.latestSellerSubscription.status === UserSubscriptionStatus.Active,
    isClient: user && user.latestClientSubscription && user.latestClientSubscription.status === UserSubscriptionStatus.Active,
    hasActiveSubscription: user && ((user.latestClientSubscription && user.latestClientSubscription.status === UserSubscriptionStatus.Active) || (user.latestSellerSubscription && user.latestSellerSubscription.status === UserSubscriptionStatus.Active)),
    clientSubscription: user?.latestClientSubscription,
    sellerSubscription: user?.latestSellerSubscription,
    isAdmin: user && user.roles.includes('ADMIN'),
    user,
    dealer: user && user.dealer ? user.dealer : null,
    kpi: user && user.kpi ? user.kpi : null,
    refetchMe,
  };
}
