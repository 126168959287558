import {
  Alert, MenuItem, TextField, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SaveUserDto, User, UserGender } from '../../../../../graphql';
import VStack from '../../../../ui-kit/layout/VStack.tsx';

function createDto(user?: User): SaveUserDto {
  return {
    id: user?.id || 0,
    email: user?.email || '',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    gender: user?.gender || UserGender.Mr,
    phoneNumber: user?.phoneNumber || '',
  };
}

interface Props {
  user: User,
  onChange: (dto: SaveUserDto|null) => void,
}

const RegisterFormUpdateProfile = ({ user, onChange }: Props) => {
  const { t } = useTranslation();
  const [ dto, setDto ] = useState<SaveUserDto>(createDto(user));

  useEffect(() => {
    onChange(dto);
  }, [ dto ]);

  return (
    <VStack alignItems="center" mt={5} px={5}>

      <VStack width="100%" maxWidth={650} mb={4}>
        <Alert severity="warning">
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: t('offline.registration.steps.my-account.account-already-created') }} />
        </Alert>
      </VStack>

      <VStack width="100%" maxWidth={425}>
        <Typography fontWeight="bolder">
          {t('form.labels.email')}
          <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          sx={{ width: '100%' }}
          value={dto.email}
          onChange={({ target: { value: email } }) => setDto({ ...dto, email: email.trim() })}
          disabled
        />
      </VStack>

      <VStack width="100%" maxWidth={425} mt={2}>
        <Typography fontWeight="bolder">
          {t('form.labels.civility')}
          <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          select
          variant="outlined"
          size="small"
          sx={{ width: '100%' }}
          value={dto.gender}
          onChange={({ target: { value: gender } }) => setDto({ ...dto, gender: gender as UserGender })}
        >
          {
            [ UserGender.Mr, UserGender.Mrs, UserGender.Miss ].map((gender) => (
              <MenuItem value={gender} key={gender}>{ t(`enums.civilities.${gender}`) }</MenuItem>
            ))
          }
        </TextField>

        <VStack width="100%" maxWidth={425} mt={2}>
          <Typography fontWeight="bolder">
            {t('form.labels.lastname')}
            <span style={{ color: 'red' }}>*</span>
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            sx={{ width: '100%' }}
            value={dto.lastName}
            onChange={({ target: { value: lastName } }) => setDto({ ...dto, lastName })}
          />
        </VStack>

        <VStack width="100%" maxWidth={425} mt={2}>
          <Typography fontWeight="bolder">
            {t('form.labels.firstname')}
            <span style={{ color: 'red' }}>*</span>
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            sx={{ width: '100%' }}
            value={dto.firstName}
            onChange={({ target: { value: firstName } }) => setDto({ ...dto, firstName })}
          />
        </VStack>

        <VStack width="100%" maxWidth={425} mt={2}>
          <Typography fontWeight="bolder">
            {t('form.labels.phone-number')}
            <span style={{ color: 'red' }}>*</span>
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            sx={{ width: '100%' }}
            value={dto.phoneNumber}
            onChange={({ target: { value: phoneNumber } }) => setDto({ ...dto, phoneNumber: phoneNumber.trim() })}
          />
        </VStack>
      </VStack>
    </VStack>
  );
};

export default RegisterFormUpdateProfile;
