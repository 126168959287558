import VStack from '../ui-kit/layout/VStack.tsx';

const PrivacyAppFacebook = () => (
  <VStack position="relative" justifyContent="center" alignItems="center" height="100vh" width="100%" bgcolor="white">
    <h1>Politique de confidentialité</h1>
    <p>Dernière mise à jour : 12-10-2024</p>

    <p>
      Cette politique de confidentialité décrit comment nous gérons les informations collectées via notre application
      Facebook, utilisée uniquement à des fins internes pour publier des véhicules sur notre page Facebook.
    </p>

    <h2>1. Informations collectées</h2>
    <p>
      Notre application ne collecte aucune information personnelle identifiable. L'usage de Facebook est limité à
      l'obtention d'un jeton d'accès (access token) nécessaire pour la publication automatique de contenus sur notre
      page Facebook.
    </p>

    <h2>2. Utilisation des jetons d'accès</h2>
    <p>
      Le seul type de données collectées est le jeton d'accès (access token), qui permet de publier des véhicules
      depuis notre plateforme sur notre page Facebook. Ces jetons sont obtenus directement via l'intégration avec
      Facebook et sont utilisés exclusivement pour cette fonction.
    </p>

    <h2>3. Stockage et sécurité des données</h2>
    <p>
      Les jetons d'accès sont encryptés et stockés de manière sécurisée dans notre base de données. Nous mettons en
      œuvre des mesures de sécurité adéquates pour protéger ces données contre tout accès non autorisé ou toute
      divulgation.
    </p>

    <h2>4. Partage des données</h2>
    <p>
      Nous ne partageons aucun jeton d'accès ou autre information avec des tiers. Les données sont strictement
      utilisées à des fins internes et pour l'interaction avec l'API Facebook afin de publier du contenu.
    </p>

    <h2>5. Droits des utilisateurs</h2>
    <p>
      Étant donné que notre application n'interagit pas directement avec les données personnelles des utilisateurs
      finaux, ces derniers ne sont pas concernés par des demandes d'accès, de modification ou de suppression de
      données personnelles.
    </p>

    <h2>6. Utilisation de cookies</h2>
    <p>Notre application n'utilise pas de cookies ou de technologies similaires pour suivre les utilisateurs.</p>

    <h2>7. Modifications de la politique de confidentialité</h2>
    <p>
      Nous nous réservons le droit de mettre à jour cette politique de confidentialité à tout moment. En cas de
      modifications, nous publierons la nouvelle version sur cette page avec la date de la dernière mise à jour.
    </p>

    <h2>8. Contact</h2>
    <p>Pour toute question concernant cette politique de confidentialité, vous pouvez nous contacter à l'adresse suivante : contact@autoconnect-solutions.com.</p>
  </VStack>
);

export default PrivacyAppFacebook;
