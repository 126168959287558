import { ApolloProvider } from '@apollo/client';
import { createTheme, ThemeProvider } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import router from '../config/router';
import { createWebtuneTheme } from '../config/theming.ts';
import { getApolloClient, langVar } from '../graphql';
import useLocalStorage from '../hooks/useLocalStorage';
import useLocationQueryParameters from '../hooks/useLocationQueryParameters.ts';
import i18n from '../i18n.ts';
import TawktoChatProvider from './providers/TawktoChatProvider.tsx';
import { UiThemeContext } from './UiThemeContext';
import '../styles/main.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-photo-view/dist/react-photo-view.css';

const App = () => {
  const { get } = useLocalStorage();
  const { parameters, getQueryParamByKey } = useLocationQueryParameters();
  const [ localTheme, setLocalTheme ] = useState(createWebtuneTheme());

  const context = useMemo(() => ({
    theme: localTheme,
    setTheme: (mode: 'light' | 'dark') => setLocalTheme(createTheme({
      palette: {
        mode,
      },
    })),
  }), [ localTheme ]);

  useEffect(() => {
    if (getQueryParamByKey('lang')) {
      (async () => {
        await i18n.changeLanguage(getQueryParamByKey('lang'));
        langVar(getQueryParamByKey('lang'));
      })();
    }
  }, [ parameters ]);

  return (
    // @ts-ignore
    <ApolloProvider client={getApolloClient(get(import.meta.env.VITE_TOKEN_KEYNAME, ''))}>
      <UiThemeContext.Provider value={context}>
        <ThemeProvider theme={context.theme}>
          <TawktoChatProvider>
            <RouterProvider router={router} />
          </TawktoChatProvider>
        </ThemeProvider>
      </UiThemeContext.Provider>

      <ToastContainer />
    </ApolloProvider>
  );
};

export default App;
