import { LoadingButton } from '@mui/lab';
import { Checkbox, Dialog, FormControlLabel } from '@mui/material';
import { useState } from 'react';
import {
  usePublishVehicleOnSocialMediaRandomlyMutation,
  VehicleSocialMediaPublicationType,
} from '../../../../../graphql';
import useUiToast from '../../../../../hooks/useUiToast.ts';
import CloseButton from '../../../../ui-kit/buttons/CloseButton.tsx';
import DialogTitle from '../../../../ui-kit/dialog/DialogTitle.tsx';
import HStack from '../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../ui-kit/layout/VStack.tsx';

interface Props {
  onClose: () => void,
}

const PublishOnSocialMediaDialog = ({
  onClose,
}: Props) => {
  const [ target, setTarget ] = useState<VehicleSocialMediaPublicationType[]>([ VehicleSocialMediaPublicationType.Facebook, VehicleSocialMediaPublicationType.Linkedin ]);
  const [ publishVehicleOnSocialMediaRandomly, { loading: isPublishingVehicleOnSocialMedia } ] = usePublishVehicleOnSocialMediaRandomlyMutation();
  const { success } = useUiToast();

  const changeTarget = (type: VehicleSocialMediaPublicationType) => setTarget(target.includes(type) ? target.filter((t) => t !== type) : [ ...target, type ]);

  const handlePublish = async () => {
    await publishVehicleOnSocialMediaRandomly({ variables: { target } });
    success(`Un véhicule a été publié sur ${target.join(', ').toLowerCase()} avec succès.`);
    onClose();
  };

  return (
    <Dialog
      id="publish-on-social-media-dialog"
      onClose={onClose}
      open
      scroll="body"
      // @ts-ignore
      maxWidth="md"
      disableEscapeKeyDown
    >
      <VStack width={450} p={5}>
        <HStack justifyContent="space-between" alignItems="center">
          <DialogTitle
            text="Publier un véhicule"
          />
          <CloseButton onClose={onClose} />
        </HStack>

        <FormControlLabel control={<Checkbox checked={target.includes(VehicleSocialMediaPublicationType.Facebook)} onChange={() => changeTarget(VehicleSocialMediaPublicationType.Facebook)} />} label="Facebook" />
        <FormControlLabel control={<Checkbox checked={target.includes(VehicleSocialMediaPublicationType.Linkedin)} onChange={() => changeTarget(VehicleSocialMediaPublicationType.Linkedin)} />} label="Linkedin" />

        <HStack justifyContent="flex-end">
          <LoadingButton loading={isPublishingVehicleOnSocialMedia} variant="contained" color="secondary" onClick={handlePublish}>
            Publier
          </LoadingButton>
        </HStack>
      </VStack>
    </Dialog>
  );
};

export default PublishOnSocialMediaDialog;
