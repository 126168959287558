import {
  Alert, Button, TextField, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dealer, SaveDealerDto, User } from '../../../../../graphql';
import HStack from '../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../ui-kit/layout/VStack.tsx';

function createDto(dealer?: Dealer, shorten?: boolean): SaveDealerDto {
  return {
    id: dealer?.id || null,
    name: dealer?.name || '',
    email: dealer?.email || '',
    phoneNumber: dealer?.phoneNumber || '',
    vatNumber: dealer?.vatNumber || '',
    iban: dealer?.iban || '',
    shorten: shorten!,
  };
}

interface Props {
  user: User,
  dealer?: Dealer,
  isShort: boolean,
  onChange: (dto: SaveDealerDto) => void,
}

const RegisterFormDealer = ({
  user, isShort, dealer = undefined, onChange,
}: Props) => {
  const { t } = useTranslation();
  const [ dto, setDto ] = useState<SaveDealerDto>(createDto(dealer, isShort));

  useEffect(() => {
    onChange(dto);
  }, [ dto ]);

  return (
    <VStack alignItems="center" mt={5} px={5}>
      {
        dealer && (
          <VStack width="100%" maxWidth={650} mb={4}>
            <Alert severity="warning">
              {t('offline.registration.steps.my-company.account-already-created')}
            </Alert>
          </VStack>
        )
      }

      <VStack width="100%" maxWidth={425} mt={2}>
        <Typography fontWeight="bolder">
          {t('form.labels.company-name')}
          <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          sx={{ width: '100%', mb: 2 }}
          value={dto.name}
          onChange={({ target: { value: name } }) => setDto({ ...dto, name })}
        />
      </VStack>

      {
        !isShort && (
          <>
            <VStack width="100%" maxWidth={425} mt={2}>
              <Typography fontWeight="bolder">
                {t('form.labels.company-email')}
                <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                sx={{ width: '100%' }}
                value={dto.email}
                onChange={({ target: { value: email } }) => setDto({ ...dto, email: email.trim() })}
              />

              <HStack justifyContent="flex-end" mt={0.5}>
                <Button onClick={() => setDto({ ...dto, email: user.email })} size="small">{t('buttons.use-my-email')}</Button>
              </HStack>
            </VStack>

            <VStack width="100%" maxWidth={425} mt={2}>
              <Typography fontWeight="bolder">
                {t('form.labels.phone-number')}
                <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                sx={{ width: '100%' }}
                value={dto.phoneNumber}
                onChange={({ target: { value: phoneNumber } }) => setDto({ ...dto, phoneNumber: phoneNumber.trim() })}
              />

              <HStack justifyContent="flex-end" mt={0.5}>
                <Button onClick={() => setDto({ ...dto, phoneNumber: user.phoneNumber })} size="small">{t('buttons.use-my-phone')}</Button>
              </HStack>
            </VStack>

            <VStack width="100%" maxWidth={425} mt={2}>
              <Typography fontWeight="bolder">
                {t('form.labels.company-vat-number')}
                <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                sx={{ width: '100%', mb: 2 }}
                value={dto.vatNumber}
                onChange={({ target: { value: vatNumber } }) => setDto({ ...dto, vatNumber: vatNumber.trim() })}
                placeholder={t('form.placeholders.vat-number')}
              />
            </VStack>

            <VStack width="100%" maxWidth={425} mt={2}>
              <Typography fontWeight="bolder">
                {t('form.labels.company-iban')}
                <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                sx={{
                  width: '100%', mb: 2,
                }}
                value={dto.iban}
                onChange={({ target: { value: iban } }) => setDto({ ...dto, iban: iban.trim() })}
                placeholder="BE71096123456769"
              />
            </VStack>
          </>
        )
      }
    </VStack>
  );
};

export default RegisterFormDealer;
