import WarningIcon from '@mui/icons-material/Warning';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AirlineSeatReclineExtraIcon from '@mui/icons-material/AirlineSeatReclineExtra';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import CategoryIcon from '@mui/icons-material/Category';
import PeopleIcon from '@mui/icons-material/People';
import SellIcon from '@mui/icons-material/Sell';
import { Card, CircularProgress, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useGetSocialMediaTokenExpirationsQuery,
  useRefreshPublishedVehiclesInCacheMutation,
  useResetFacebookAccessTokenMutation,
  useResetLinkedinAccessTokenMutation, useRunImportsMutation, useRunWebsiteVehicleMediaGenerationJobMutation,
} from '../../../../graphql';
import useReactiveUserConnected from '../../../../hooks/data/useReactiveUserConnected.ts';
import useLocationQueryParameters from '../../../../hooks/useLocationQueryParameters.ts';
import useUiToast from '../../../../hooks/useUiToast.ts';
import HStack from '../../../ui-kit/layout/HStack.tsx';
import Page from '../../../ui-kit/layout/Page.tsx';
import PublishOnSocialMediaDialog
  from '../../partials/dialogs/publish-vehicle-on-social-media/PublishOnSocialMediaDialog.tsx';

const AdministrationDashboardPage = () => {
  const { user } = useReactiveUserConnected();
  const [ isPublishOnSocialMediaDialogOpen, setIsPublishOnSocialMediaDialogOpen ] = useState(false);
  const navigate = useNavigate();
  const { data } = useGetSocialMediaTokenExpirationsQuery();
  const diffDaysUntilFacebookTokenExpiration = useMemo(() => (data?.getSocialMediaTokenExpirations?.facebookExpiresAt ? Math.ceil((new Date(data.getSocialMediaTokenExpirations.facebookExpiresAt).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) : null), [ data ]);
  const diffDaysUntilLinkedinTokenExpiration = useMemo(() => (data?.getSocialMediaTokenExpirations?.linkedinExpiresAt ? Math.ceil((new Date(data.getSocialMediaTokenExpirations.linkedinExpiresAt).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) : null), [ data ]);
  const [ refreshPublishedVehiclesInCache, { data: refreshPublishedVehiclesInCacheData, loading: isRefreshingCache } ] = useRefreshPublishedVehiclesInCacheMutation();
  const [ resetFacebookAccessToken, { data: resetFacebookAccessTokenData, loading: isResettingFacebookAccessToken } ] = useResetFacebookAccessTokenMutation();
  const [ resetLinkedinAccessToken, { data: resetLinkedinAccessTokenData, loading: isResettingLinkedinAccessToken } ] = useResetLinkedinAccessTokenMutation();
  const [ runImportMcaImport, { loading: isRunningMcAutomobileImport } ] = useRunImportsMutation({ variables: { imports: [ 'McAutomobile' ] } });
  const [ runWebsiteVehicleMediaGenerationJob, { loading: isRunningWebsiteVehicleMediaGenerationJob } ] = useRunWebsiteVehicleMediaGenerationJobMutation();
  const { success } = useUiToast();
  const queryParameters = useLocationQueryParameters();

  const handleResetFacebookAccessToken = () => {
    (window as any).FB.login((response: any) => {
      if (response.status === 'connected') {
        resetFacebookAccessToken({ variables: { accessToken: response.authResponse.accessToken } });
      }
    }, { scope: 'pages_manage_posts,business_management,instagram_content_publish' });
  };

  const handleRedirectToLinkedinAuth = () => window.location.replace(import.meta.env.VITE_LINKEDIN_AUTH_URL);

  useEffect(() => {
    if (refreshPublishedVehiclesInCacheData && refreshPublishedVehiclesInCacheData.refreshPublishedVehiclesInCache) {
      success('Le cache a été rafraîchi avec succès.');
    }
  }, [ refreshPublishedVehiclesInCacheData ]);

  useEffect(() => {
    if (resetFacebookAccessTokenData && resetFacebookAccessTokenData.resetFacebookAccessToken) {
      success('Access Token Facebook a été réinitialisé avec succès.');
    }
  }, [ resetFacebookAccessTokenData ]);

  useEffect(() => {
    if (resetLinkedinAccessTokenData && resetLinkedinAccessTokenData.resetLinkedinAccessToken) {
      success('Access Token Linkedin a été réinitialisé avec succès.');
    }
  }, [ resetLinkedinAccessTokenData ]);

  useEffect(() => {
    if (queryParameters.getQueryParamByKey('code') && queryParameters.getQueryParamByKey('state') === 'linkedin') {
      resetLinkedinAccessToken({ variables: { code: queryParameters.getQueryParamByKey('code')! } });
    }
  }, [ queryParameters.getQueryParamByKey('code'), queryParameters.getQueryParamByKey('state') ]);

  return (
    <Page>
      <h1>Administration</h1>

      {
        user?.email !== 'test@facebook.com' && (
          <>
            <h3>Gestion de base</h3>
            <HStack mb={4} gap={2} flexWrap="wrap">
              {/* <Card sx={{
          padding: 2, cursor: 'pointer', alignItems: 'center', display: 'flex', gap: 1,
        }}
        >
          <GroupIcon />
          Gestion des utilisateurs
        </Card> */}
              <Card
                sx={{
                  padding: 2,
                  cursor: 'pointer',
                  alignItems: 'center',
                  display: 'flex',
                  gap: 1,
                }}
                onClick={() => navigate('/admin/brands')}
              >
                <SellIcon />
                Gestion des marques
              </Card>
              <Card
                sx={{
                  padding: 2,
                  cursor: 'pointer',
                  alignItems: 'center',
                  display: 'flex',
                  gap: 1,
                }}
                onClick={() => navigate('/admin/attributes')}
              >
                <CategoryIcon />
                Gestion des attributs
              </Card>
              <Card
                sx={{
                  padding: 2,
                  cursor: 'pointer',
                  alignItems: 'center',
                  display: 'flex',
                  gap: 1,
                }}
                onClick={() => navigate('/admin/equipments')}
              >
                <AirlineSeatReclineExtraIcon />
                Gestion des équipements
              </Card>
              <Card
                sx={{
                  padding: 2,
                  cursor: 'pointer',
                  alignItems: 'center',
                  display: 'flex',
                  gap: 1,
                }}
                onClick={() => navigate('/admin/damages')}
              >
                <CarCrashIcon />
                Gestion des dégâts
              </Card>
              <Card
                sx={{
                  padding: 2,
                  cursor: 'pointer',
                  alignItems: 'center',
                  display: 'flex',
                  gap: 1,
                }}
                onClick={() => navigate('/admin/users')}
              >
                <PeopleIcon />
                Gestion des utilisateurs
              </Card>
              <Card
                sx={{
                  padding: 2,
                  cursor: 'pointer',
                  alignItems: 'center',
                  display: 'flex',
                  gap: 1,
                }}
                onClick={() => refreshPublishedVehiclesInCache()}
              >
                {isRefreshingCache && <CircularProgress size="15px" />}
                Rafraîchir le cache
              </Card>
              {/* <Card sx={{
          padding: 2, cursor: 'pointer', alignItems: 'center', display: 'flex', gap: 1,
        }}
        >
          <DirectionsCarIcon />
          Gestion des véhicules
        </Card> */}
            </HStack>

            <h3>Tâches</h3>
            <HStack gap={2}>
              <Card
                sx={{
                  padding: 2,
                  cursor: 'pointer',
                  alignItems: 'center',
                  display: 'flex',
                  gap: 1,
                }}
                onClick={() => runImportMcaImport()}
              >
                {isRunningMcAutomobileImport && <CircularProgress size="15px" />}
                Lancer Import MCA
              </Card>
              <Card
                sx={{
                  padding: 2,
                  cursor: 'pointer',
                  alignItems: 'center',
                  display: 'flex',
                  gap: 1,
                }}
                onClick={() => runWebsiteVehicleMediaGenerationJob()}
              >
                {isRunningWebsiteVehicleMediaGenerationJob && <CircularProgress size="15px" />}
                Générer les médias du site web
              </Card>
            </HStack>
          </>
        )
      }

      <h3>Social Media</h3>
      <HStack gap={2} flexWrap="wrap">
        <Card
          sx={{
            padding: 2,
            cursor: 'pointer',
            alignItems: 'center',
            display: 'flex',
            gap: 1,
          }}
          onClick={() => setIsPublishOnSocialMediaDialogOpen(true)}
        >
          Publier un véhicule aléatoirement
        </Card>
        <Card
          sx={{
            padding: 2,
            cursor: 'pointer',
            alignItems: 'center',
            display: 'flex',
            gap: 1,
          }}
          onClick={() => handleResetFacebookAccessToken()}
        >
          {isResettingFacebookAccessToken && <CircularProgress size="15px" />}
          {diffDaysUntilFacebookTokenExpiration === null
            && (
            <Tooltip title="Aucun token en cours d'utilisation">
              <CancelIcon
                fontSize="small"
                color="secondary"
              />
            </Tooltip>
            )}
          {diffDaysUntilFacebookTokenExpiration !== null && diffDaysUntilFacebookTokenExpiration > 15 && (
          <Tooltip
            title={`Le token expire le ${dayjs(data?.getSocialMediaTokenExpirations.facebookExpiresAt)
              .format('DD MMMM YYYY')}`}
          >
            <CheckCircleIcon fontSize="small" color="success" />
          </Tooltip>
          )}
          {diffDaysUntilFacebookTokenExpiration !== null && diffDaysUntilFacebookTokenExpiration <= 15 && (
          <Tooltip
            title={`Le token expire le ${dayjs(data?.getSocialMediaTokenExpirations.facebookExpiresAt)
              .format('DD MMMM YYYY')}`}
          >
            <WarningIcon fontSize="small" color="warning" />
          </Tooltip>
          )}
          Reset Facebook Access Token
        </Card>

        {
          user?.email !== 'test@facebook.com' && (
            <Card
              sx={{
                padding: 2,
                cursor: 'pointer',
                alignItems: 'center',
                display: 'flex',
                gap: 1,
              }}
              onClick={() => handleRedirectToLinkedinAuth()}
            >
              {isResettingLinkedinAccessToken && <CircularProgress size="15px" />}
              {diffDaysUntilLinkedinTokenExpiration === null
                && (
                <Tooltip title="Aucun token en cours d'utilisation">
                  <CancelIcon
                    fontSize="small"
                    color="secondary"
                  />
                </Tooltip>
                )}
              {diffDaysUntilLinkedinTokenExpiration !== null && diffDaysUntilLinkedinTokenExpiration > 15 && (
              <Tooltip
                title={`Le token expire le ${dayjs(data?.getSocialMediaTokenExpirations.linkedinExpiresAt)
                  .format('DD MMMM YYYY')}`}
              >
                <CheckCircleIcon fontSize="small" color="success" />
              </Tooltip>
              )}
              {diffDaysUntilLinkedinTokenExpiration !== null && diffDaysUntilLinkedinTokenExpiration <= 15
                && <Tooltip title={`Le token expire le ${dayjs(data?.getSocialMediaTokenExpirations.linkedinExpiresAt).format('DD MMMM YYYY')}`}><WarningIcon fontSize="small" color="warning" /></Tooltip> }
              Reset Linkedin Access Token
            </Card>
          )
        }
      </HStack>

      { isPublishOnSocialMediaDialogOpen && <PublishOnSocialMediaDialog onClose={() => setIsPublishOnSocialMediaDialogOpen(false)} /> }
    </Page>
  );
};

export default AdministrationDashboardPage;
