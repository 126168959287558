import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import StarIcon from '@mui/icons-material/StarPurple500';
import WarningIcon from '@mui/icons-material/Warning';
import {
  AppBar, Button, Rating, Tooltip, Typography,
} from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useReactiveUserConnected from '../../../hooks/data/useReactiveUserConnected.ts';
import HeaderLanguagesMenu from '../../pages/partials/menus/HeaderLanguagesMenu.tsx';
import HeaderMainMenu from '../../pages/partials/menus/HeaderMainMenu.tsx';
import HeaderNotificationMenu from '../../pages/partials/menus/HeaderNotificationMenu.tsx';
import HeaderOfflineMenu from '../../pages/partials/menus/HeaderOfflineMenu.tsx';
import { OfflineDialogsContext } from '../../providers/OfflineDialogsContext.ts';
import HStack from './HStack.tsx';
import '../../../styles/layout/header.scss';
import VStack from './VStack.tsx';

const Header = () => {
  const { t } = useTranslation();
  const {
    user, dealer, isConnected, isUserAccountValidated, isUserDataValid, hasActiveSubscription,
  } = useReactiveUserConnected();
  const { openLoginDialog } = useContext(OfflineDialogsContext);

  return (
    <AppBar
      className="header"
      position="static"
      color="inherit"
      sx={{
        width: '100%',
        backgroundColor: '#030b21',
        border: 0,
        height: 100,
        boxShadow: 'none',
        padding: '0 2rem',
        justifyContent: 'center',
        flexDirection: 'row',
        position: 'fixed',
        top: 0,
        zIndex: 1000,
      }}
    >
      <VStack className="logo" width="20%" height="100%" alignItems="center" justifyContent="center">
        <img src="/assets/images/logo.png" width={112} height={80} />
      </VStack>
      <HStack flex={1} height="100%" alignItems="center">
        {
          isConnected && (
            <VStack
              justifyContent="center"
              style={{
                color: 'white',
                flex: '1',
              }}
            >
              <HStack alignItems="center" gap={1}>
                { !isUserAccountValidated && <Tooltip title={t('tooltips.account-not-validated')}><WarningIcon color="error" /></Tooltip> }
                { isUserAccountValidated && !isUserDataValid && <Tooltip title={t('tooltips.account-data-uncompleted')}><WarningIcon color="warning" /></Tooltip> }
                { isUserAccountValidated && isUserDataValid && !hasActiveSubscription && <Tooltip title={t('tooltips.account-valid-no-subscription')}><HourglassEmptyIcon color="info" /></Tooltip> }
                { isUserAccountValidated && isUserDataValid && hasActiveSubscription && <Tooltip title={t('tooltips.account-valid-and-active')}><CheckCircleIcon style={{ color: '#6ed26e' }} /></Tooltip> }
                <Typography variant="h6">
                  { dealer?.name ?? user!.firstName }
                </Typography>
              </HStack>
              <HStack maxWidth={120}>
                <Rating
                  size="small"
                  value={user?.averageRatingScore ?? 0}
                  precision={0.5}
                  emptyIcon={<StarIcon style={{ opacity: 1, color: 'grey' }} fontSize="inherit" />}
                  onMouseOver={(event) => event.stopPropagation()}
                  readOnly
                />
              </HStack>
            </VStack>
          )
        }

        {
          !isConnected && (
            <HStack flex={1}>
              <HeaderOfflineMenu />
            </HStack>
          )
        }

        <HStack alignItems="center">
          { !isConnected && (
          <Button id="login-button" onClick={openLoginDialog} size="medium" variant="outlined" color="primary" sx={{ borderColor: 'white', color: 'white', '&:hover': { background: 'rgba(207, 168, 14, 0.1)', borderColor: 'gold' } }}>
            { t('buttons.login') }
            &nbsp;
            👋
          </Button>
          )}
          <HeaderLanguagesMenu />
          { isConnected && <HeaderNotificationMenu /> }
          { isConnected && <HeaderMainMenu /> }
        </HStack>
      </HStack>
    </AppBar>
  );
};

export default Header;
