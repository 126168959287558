import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String']['output'];
  country: Country;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  number: Scalars['String']['output'];
  street: Scalars['String']['output'];
  type: AddressType;
  updatedAt: Scalars['DateTime']['output'];
  zip: Scalars['String']['output'];
};

export enum AddressType {
  Billing = 'BILLING',
  Collection = 'COLLECTION',
  Headquarter = 'HEADQUARTER',
  Shipping = 'SHIPPING'
}

export type AdminTask = {
  __typename?: 'AdminTask';
  output?: Maybe<Scalars['JSON']['output']>;
};

export type Attribute = {
  __typename?: 'Attribute';
  category?: Maybe<AttributeCategory>;
  categoryId: Scalars['Int']['output'];
  config?: Maybe<AttributeConfig>;
  createdAt: Scalars['DateTime']['output'];
  displayOrder: Scalars['Int']['output'];
  filterable: Scalars['Boolean']['output'];
  filters?: Maybe<Array<AttributeFilter>>;
  id: Scalars['Int']['output'];
  internalCode?: Maybe<Scalars['String']['output']>;
  mandatory: Scalars['Boolean']['output'];
  name: Scalars['JSON']['output'];
  type: AttributeType;
  updatedAt: Scalars['DateTime']['output'];
  values?: Maybe<Array<AttributeValue>>;
};

export type AttributeCategory = {
  __typename?: 'AttributeCategory';
  attributeId: Scalars['Int']['output'];
  attributes?: Maybe<Array<Attribute>>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['JSON']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AttributeConfig = {
  __typename?: 'AttributeConfig';
  form: AttributeFormConfig;
};

export type AttributeFilter = {
  __typename?: 'AttributeFilter';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['JSON']['output'];
  type: AttributeFilterType;
  updatedAt: Scalars['DateTime']['output'];
  values?: Maybe<Array<AttributeFilterValue>>;
};

export enum AttributeFilterRule {
  Equal = 'EQUAL',
  Higher = 'HIGHER',
  HigherOrEqual = 'HIGHER_OR_EQUAL',
  Lower = 'LOWER',
  LowerOrEqual = 'LOWER_OR_EQUAL'
}

export enum AttributeFilterType {
  BoolTrue = 'BOOL_TRUE',
  Equality = 'EQUALITY',
  Range = 'RANGE'
}

export type AttributeFilterValue = {
  __typename?: 'AttributeFilterValue';
  attributeFilterId: Scalars['Int']['output'];
  attributeId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  filter?: Maybe<AttributeFilter>;
  id: Scalars['Int']['output'];
  label: Scalars['JSON']['output'];
  rule: AttributeFilterRule;
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['JSON']['output'];
};

export type AttributeFormConfig = {
  __typename?: 'AttributeFormConfig';
  placeholder: Scalars['JSON']['output'];
};

export enum AttributeType {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Number = 'NUMBER',
  Select = 'SELECT',
  Text = 'TEXT'
}

export type AttributeValue = {
  __typename?: 'AttributeValue';
  attribute?: Maybe<Attribute>;
  attributeId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['JSON']['output'];
};

export type AuthenticationDto = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Brand = {
  __typename?: 'Brand';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  models?: Maybe<Array<Model>>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vehicles?: Maybe<Array<Vehicle>>;
};

export type CancelVehicleDto = {
  reason?: InputMaybe<Scalars['String']['input']>;
  vehicleId: Scalars['Int']['input'];
};

export type ChangeMyPasswordDto = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export enum Country {
  Be = 'BE',
  De = 'DE',
  Es = 'ES',
  Fr = 'FR',
  Gr = 'GR',
  It = 'IT',
  Lu = 'LU',
  Nl = 'NL',
  Pl = 'PL'
}

export type Damage = {
  __typename?: 'Damage';
  createdAt: Scalars['DateTime']['output'];
  dealerId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['JSON']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type DamageLocation = {
  __typename?: 'DamageLocation';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['JSON']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Dealer = {
  __typename?: 'Dealer';
  addresses?: Maybe<Array<Address>>;
  contacts?: Maybe<Array<DealerContact>>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  iban: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  logo?: Maybe<File>;
  logoFileId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  userId: Scalars['Int']['output'];
  vatNumber: Scalars['String']['output'];
  vehicles?: Maybe<Array<Vehicle>>;
};

export type DealerContact = {
  __typename?: 'DealerContact';
  createdAt: Scalars['DateTime']['output'];
  dealer?: Maybe<Dealer>;
  dealerId: Scalars['Int']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type DeclineOfferDto = {
  offerId: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
};

export type Equipment = {
  __typename?: 'Equipment';
  createdAt: Scalars['DateTime']['output'];
  dealerId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['JSON']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type File = {
  __typename?: 'File';
  createdAt: Scalars['DateTime']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  initialFilename: Scalars['String']['output'];
  path: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uri: Scalars['String']['output'];
};

export type GenerateVehicleMediaThumbnailDto = {
  mediaIds: Array<Scalars['Int']['input']>;
  vehicleId?: InputMaybe<Scalars['Int']['input']>;
};

export type GetMyPaginatedNotificationsOrderByDto = {
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type GetMyPaginatedVehiclesDto = {
  filters: Array<VehicleFilterDto>;
  from?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<GetMyPaginatedVehiclesOrderByDto>;
  size?: InputMaybe<Scalars['Int']['input']>;
  statuses: Array<VehicleStatus>;
};

export type GetMyPaginatedVehiclesOrderByDto = {
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type GetPaginatedInAppNotificationsDto = {
  from?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<GetMyPaginatedNotificationsOrderByDto>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type GetPaginatedOpenedVehiclesDto = {
  filters: Array<VehicleFilterDto>;
  from?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<GetPaginatedOpenedVehiclesOrderByDto>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type GetPaginatedOpenedVehiclesOrderByDto = {
  publishedAt?: InputMaybe<SortOrder>;
};

export type GetPaginatedPublishedVehiclesByDealerDto = {
  dealerId: Scalars['Int']['input'];
  filters: Array<VehicleFilterDto>;
  from?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<GetPaginatedPublishedVehiclesByDealerOrderByDto>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type GetPaginatedPublishedVehiclesByDealerOrderByDto = {
  publishedAt?: InputMaybe<SortOrder>;
};

export type GetPaginatedPublishedVehiclesDto = {
  filters: Array<VehicleFilterDto>;
  from?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<GetPaginatedPublishedVehiclesOrderByDto>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type GetPaginatedPublishedVehiclesOrderByDto = {
  publishedAt?: InputMaybe<SortOrder>;
};

export type GetPaginatedVehiclesFromMyFavoritesDto = {
  filters: Array<VehicleFilterDto>;
  from?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<GetPaginatedVehiclesWithMyOffersOrderByDto>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type GetPaginatedVehiclesFromMyOffersDto = {
  filterByOfferStatusTypes: Array<OfferStatusType>;
  filters: Array<VehicleFilterDto>;
  from?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<GetPaginatedVehiclesFromMyOffersOrderByDto>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type GetPaginatedVehiclesFromMyOffersOrderByDto = {
  updatedAt?: InputMaybe<SortOrder>;
};

export type GetPaginatedVehiclesWithMyOffersOrderByDto = {
  updatedAt?: InputMaybe<SortOrder>;
};

export enum Language {
  De = 'DE',
  El = 'EL',
  En = 'EN',
  Es = 'ES',
  Fr = 'FR',
  It = 'IT',
  Nl = 'NL',
  Pl = 'PL'
}

export type ManageUserSubscriptionDto = {
  endAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  startAt?: InputMaybe<Scalars['DateTime']['input']>;
  status: UserSubscriptionStatus;
  type: UserSubscriptionType;
  userId: Scalars['Int']['input'];
};

export type Model = {
  __typename?: 'Model';
  brand?: Maybe<Brand>;
  brandId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  dealerId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vehicles?: Maybe<Array<Vehicle>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptOffer: Offer;
  addUserHiddenVehicle: UserSubscription;
  addUserOpenedVehicle: UserOpenedVehicle;
  addVehicleToFavorites: UserVehicleFavorite;
  askRecoveryPasswordCode: Scalars['Boolean']['output'];
  askToBecomeClient: UserSubscription;
  askToBecomeSeller: UserSubscription;
  cancelOffer: Offer;
  cancelVehicle: Vehicle;
  changeLanguage: Scalars['Boolean']['output'];
  changePassword: Scalars['Boolean']['output'];
  declineOffer: Offer;
  deleteAttribute: Scalars['Boolean']['output'];
  deleteAttributeValue: Scalars['Boolean']['output'];
  deleteDamage: Scalars['Boolean']['output'];
  deleteDamageLocation: Scalars['Boolean']['output'];
  deleteEquipment: Scalars['Boolean']['output'];
  deleteUserAsAdmin: Scalars['Boolean']['output'];
  deleteVehicleFromFavorites: Scalars['Boolean']['output'];
  deleteVehicleMedia: Scalars['Boolean']['output'];
  finalizeVehicleSale: Vehicle;
  generateVehicleMediaThumbnails: Scalars['Boolean']['output'];
  manageUserSubscription: UserSubscription;
  markInAppNotificationsAsRead: Scalars['Boolean']['output'];
  publishVehicle: Vehicle;
  publishVehicleOnSocialMediaRandomly: Scalars['Boolean']['output'];
  rateSomeone: UserSubscription;
  recoverPassword: UserRecoveryPassword;
  refreshPublishedVehiclesInCache: AdminTask;
  register: User;
  republishVehicle: Vehicle;
  resetFacebookAccessToken: Scalars['Boolean']['output'];
  resetLinkedinAccessToken: Scalars['Boolean']['output'];
  runImports: Scalars['Boolean']['output'];
  runWebsiteVehicleMediaGenerationJobUseCase: Scalars['Boolean']['output'];
  saveAttribute: Attribute;
  saveAttributeCategory: AttributeCategory;
  saveBrand: Brand;
  saveDamage: Damage;
  saveDamageLocation: DamageLocation;
  saveDealer: Dealer;
  saveDealerAddress: Dealer;
  saveDealerContact: Dealer;
  saveDealerLogo: Dealer;
  saveEquipment: Equipment;
  saveModel: Model;
  saveOffer: Offer;
  saveUser: User;
  saveVehicle: Vehicle;
  saveVehicleMedias: Array<VehicleMedia>;
  saveWebsiteEvent: WebsiteEvent;
  subscribeToNewsletter: NewsletterSubscription;
  validateUser: Scalars['Boolean']['output'];
};


export type MutationAcceptOfferArgs = {
  offerId: Scalars['Int']['input'];
};


export type MutationAddUserHiddenVehicleArgs = {
  vehicleId: Scalars['Int']['input'];
};


export type MutationAddUserOpenedVehicleArgs = {
  vehicleId: Scalars['Int']['input'];
};


export type MutationAddVehicleToFavoritesArgs = {
  vehicleId: Scalars['Int']['input'];
};


export type MutationAskRecoveryPasswordCodeArgs = {
  email: Scalars['String']['input'];
};


export type MutationCancelOfferArgs = {
  offerId: Scalars['Int']['input'];
};


export type MutationCancelVehicleArgs = {
  dto: CancelVehicleDto;
};


export type MutationChangeLanguageArgs = {
  language: Language;
};


export type MutationChangePasswordArgs = {
  dto: ChangeMyPasswordDto;
};


export type MutationDeclineOfferArgs = {
  dto: DeclineOfferDto;
};


export type MutationDeleteAttributeArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteAttributeValueArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteDamageArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteDamageLocationArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteEquipmentArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteUserAsAdminArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteVehicleFromFavoritesArgs = {
  vehicleId: Scalars['Int']['input'];
};


export type MutationDeleteVehicleMediaArgs = {
  mediaId: Scalars['Int']['input'];
};


export type MutationFinalizeVehicleSaleArgs = {
  id: Scalars['Int']['input'];
};


export type MutationGenerateVehicleMediaThumbnailsArgs = {
  dto: GenerateVehicleMediaThumbnailDto;
};


export type MutationManageUserSubscriptionArgs = {
  dto: ManageUserSubscriptionDto;
};


export type MutationPublishVehicleArgs = {
  id: Scalars['Int']['input'];
};


export type MutationPublishVehicleOnSocialMediaRandomlyArgs = {
  target: Array<VehicleSocialMediaPublicationType>;
};


export type MutationRateSomeoneArgs = {
  dto: RateSomeoneDto;
};


export type MutationRecoverPasswordArgs = {
  dto: RecoverPasswordDto;
};


export type MutationRegisterArgs = {
  dto: RegisterDto;
};


export type MutationRepublishVehicleArgs = {
  id: Scalars['Int']['input'];
};


export type MutationResetFacebookAccessTokenArgs = {
  accessToken: Scalars['String']['input'];
};


export type MutationResetLinkedinAccessTokenArgs = {
  code: Scalars['String']['input'];
};


export type MutationRunImportsArgs = {
  imports: Array<Scalars['String']['input']>;
};


export type MutationSaveAttributeArgs = {
  dto: SaveAttributeDto;
};


export type MutationSaveAttributeCategoryArgs = {
  dto: SaveAttributeCategoryDto;
};


export type MutationSaveBrandArgs = {
  dto: SaveBrandDto;
};


export type MutationSaveDamageArgs = {
  dto: SaveDamageDto;
};


export type MutationSaveDamageLocationArgs = {
  dto: SaveDamageLocationDto;
};


export type MutationSaveDealerArgs = {
  dto: SaveDealerDto;
};


export type MutationSaveDealerAddressArgs = {
  dto: SaveDealerAddressDto;
};


export type MutationSaveDealerContactArgs = {
  dto: SaveDealerContactDto;
};


export type MutationSaveDealerLogoArgs = {
  dto: SaveDealerLogoDto;
};


export type MutationSaveEquipmentArgs = {
  dto: SaveEquipmentDto;
};


export type MutationSaveModelArgs = {
  dto: SaveModelDto;
};


export type MutationSaveOfferArgs = {
  dto: SaveOfferDto;
};


export type MutationSaveUserArgs = {
  dto: SaveUserDto;
};


export type MutationSaveVehicleArgs = {
  dto: SaveVehicleDto;
};


export type MutationSaveVehicleMediasArgs = {
  dto: SaveVehicleMediasDto;
};


export type MutationSaveWebsiteEventArgs = {
  dto: SaveEventDto;
};


export type MutationSubscribeToNewsletterArgs = {
  email: Scalars['String']['input'];
};


export type MutationValidateUserArgs = {
  dto: ValidateUserAccountDto;
};

export type NewsletterSubscription = {
  __typename?: 'NewsletterSubscription';
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  subscribedAt: Scalars['DateTime']['output'];
};

export type Notification = {
  __typename?: 'Notification';
  channel: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  payload: Scalars['JSON']['output'];
  readAt?: Maybe<Scalars['DateTime']['output']>;
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
};

export type Offer = {
  __typename?: 'Offer';
  amount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  statuses?: Maybe<Array<OfferStatus>>;
  type: OfferType;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  userId: Scalars['Int']['output'];
  vehicle?: Maybe<Vehicle>;
  vehicleId: Scalars['Int']['output'];
};

export type OfferStatus = {
  __typename?: 'OfferStatus';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  offer?: Maybe<Offer>;
  offerId: Scalars['Int']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  type: OfferStatusType;
  user?: Maybe<User>;
  userId: Scalars['Int']['output'];
};

export enum OfferStatusType {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Declined = 'DECLINED',
  Waiting = 'WAITING',
  Won = 'WON'
}

export enum OfferType {
  ImmediatePurchase = 'IMMEDIATE_PURCHASE',
  Normal = 'NORMAL'
}

export type PaginatedNotification = {
  __typename?: 'PaginatedNotification';
  results: Array<Notification>;
  total: Scalars['Int']['output'];
};

export type PaginatedVehicle = {
  __typename?: 'PaginatedVehicle';
  results: Array<Vehicle>;
  total: Scalars['Int']['output'];
};

export type PublicDealer = {
  __typename?: 'PublicDealer';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  numberOfPublishedVehicles: Scalars['Float']['output'];
};

export type Query = {
  __typename?: 'Query';
  allAttributeCategories: Array<AttributeCategory>;
  allAttributes: Array<Attribute>;
  allBrands: Array<Brand>;
  allDamageLocations: Array<DamageLocation>;
  allDamages: Array<Damage>;
  allEquipments: Array<Equipment>;
  attribute: Attribute;
  getAllUsersForAdmin: Array<User>;
  getLoggedUser: User;
  getSocialMediaTokenExpirations: SocialMediaTokenExpiration;
  login: Scalars['String']['output'];
  loginUnderUser: Scalars['String']['output'];
  myDealer: Dealer;
  myOffersForVehicles: Array<Offer>;
  myPaginatedNotifications: PaginatedNotification;
  myPaginatedVehicles: PaginatedVehicle;
  offersOfMyVehicle: Array<Offer>;
  paginatedOpenedVehicles: PaginatedVehicle;
  paginatedPublishedVehicles: PaginatedVehicle;
  paginatedPublishedVehiclesByDealer: PaginatedVehicle;
  paginatedVehiclesFromMyFavorites: PaginatedVehicle;
  paginatedVehiclesFromMyOffers: PaginatedVehicle;
  publicDealer: PublicDealer;
  refreshToken: Scalars['String']['output'];
  validateRecoveryPasswordCode: UserRecoveryPassword;
  validateToken: Scalars['Boolean']['output'];
  vehicleById: Vehicle;
  vehicleSaleOrderForm: Scalars['String']['output'];
};


export type QueryAttributeArgs = {
  id: Scalars['Int']['input'];
};


export type QueryLoginArgs = {
  dto: AuthenticationDto;
};


export type QueryLoginUnderUserArgs = {
  id: Scalars['Int']['input'];
};


export type QueryMyOffersForVehiclesArgs = {
  vehicleIds: Array<Scalars['Int']['input']>;
};


export type QueryMyPaginatedNotificationsArgs = {
  dto: GetPaginatedInAppNotificationsDto;
};


export type QueryMyPaginatedVehiclesArgs = {
  dto: GetMyPaginatedVehiclesDto;
};


export type QueryOffersOfMyVehicleArgs = {
  vehicleId: Scalars['Int']['input'];
};


export type QueryPaginatedOpenedVehiclesArgs = {
  dto: GetPaginatedOpenedVehiclesDto;
};


export type QueryPaginatedPublishedVehiclesArgs = {
  dto: GetPaginatedPublishedVehiclesDto;
};


export type QueryPaginatedPublishedVehiclesByDealerArgs = {
  dto: GetPaginatedPublishedVehiclesByDealerDto;
};


export type QueryPaginatedVehiclesFromMyFavoritesArgs = {
  dto: GetPaginatedVehiclesFromMyFavoritesDto;
};


export type QueryPaginatedVehiclesFromMyOffersArgs = {
  dto: GetPaginatedVehiclesFromMyOffersDto;
};


export type QueryPublicDealerArgs = {
  dealerId: Scalars['Int']['input'];
};


export type QueryValidateRecoveryPasswordCodeArgs = {
  dto: ValidateRecoveryPasswordCodeDto;
};


export type QueryValidateTokenArgs = {
  dto: TokenValidationDto;
};


export type QueryVehicleByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryVehicleSaleOrderFormArgs = {
  id: Scalars['Int']['input'];
};

export type RateSomeoneDto = {
  comment?: InputMaybe<Scalars['String']['input']>;
  targetedUserId: Scalars['Int']['input'];
  type: UserRatingType;
  value: Scalars['Float']['input'];
  vehicleId: Scalars['Int']['input'];
};

export type RecoverPasswordDto = {
  code: Scalars['Float']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type RegisterDto = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gender: UserGender;
  hasAcceptedPrivacyPolicy: Scalars['Boolean']['input'];
  hasAcceptedTermsAndConditions: Scalars['Boolean']['input'];
  lastName: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  selectedSubscriptions?: InputMaybe<Array<UserSubscriptionType>>;
};

export type SaveAttributeCategoryDto = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['JSON']['input'];
};

export type SaveAttributeDto = {
  categoryId: Scalars['Int']['input'];
  filterable: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  mandatory: Scalars['Boolean']['input'];
  name: Scalars['JSON']['input'];
  type: AttributeType;
  values: Array<SaveAttributeWithValueDto>;
};

export type SaveAttributeWithValueDto = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['JSON']['input'];
};

export type SaveBrandDto = {
  id?: InputMaybe<Scalars['Int']['input']>;
  models: Array<SaveBrandModelDto>;
  name: Scalars['String']['input'];
};

export type SaveBrandModelDto = {
  brandId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
};

export type SaveDamageDto = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['JSON']['input'];
};

export type SaveDamageLocationDto = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['JSON']['input'];
};

export type SaveDealerAddressDto = {
  city: Scalars['String']['input'];
  country: Country;
  id?: InputMaybe<Scalars['Int']['input']>;
  number: Scalars['String']['input'];
  street: Scalars['String']['input'];
  type: AddressType;
  zip: Scalars['String']['input'];
};

export type SaveDealerContactDto = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type SaveDealerDto = {
  email: Scalars['String']['input'];
  iban: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  shorten: Scalars['Boolean']['input'];
  vatNumber: Scalars['String']['input'];
};

export type SaveDealerLogoDto = {
  id?: InputMaybe<Scalars['Int']['input']>;
  logoFileId: Scalars['Float']['input'];
};

export type SaveEquipmentDto = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['JSON']['input'];
};

export type SaveEventDto = {
  name: Scalars['String']['input'];
  payload: Scalars['JSON']['input'];
};

export type SaveModelDto = {
  brandId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
};

export type SaveOfferDto = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  type: OfferType;
  vehicleId: Scalars['Int']['input'];
};

export type SaveSingleVehicleMediaDto = {
  damageId?: InputMaybe<Scalars['Int']['input']>;
  equipmentId?: InputMaybe<Scalars['Int']['input']>;
  fileId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  tag: VehicleMediaTag;
  type: VehicleMediaType;
};

export type SaveUserDto = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gender: UserGender;
  id: Scalars['Int']['input'];
  lastName: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
  selectedSubscriptions?: InputMaybe<Array<UserSubscriptionType>>;
};

export type SaveVehicleAttributeDto = {
  attributeId: Scalars['Int']['input'];
  attributeValueId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export type SaveVehicleDamageDto = {
  damageId: Scalars['Int']['input'];
};

export type SaveVehicleDto = {
  addressId: Scalars['Int']['input'];
  attributes: Array<SaveVehicleAttributeDto>;
  availableAt: Scalars['DateTime']['input'];
  brandId: Scalars['Int']['input'];
  contactId?: InputMaybe<Scalars['Int']['input']>;
  damages: Array<SaveVehicleDamageDto>;
  dealerId: Scalars['Int']['input'];
  equipments: Array<SaveVehicleEquipmentDto>;
  id?: InputMaybe<Scalars['Int']['input']>;
  immediatePurchase: SaveVehicleImmediatePurchaseDto;
  modelId: Scalars['Int']['input'];
};

export type SaveVehicleEquipmentDto = {
  equipmentId: Scalars['Int']['input'];
};

export type SaveVehicleImmediatePurchaseDto = {
  enabled: Scalars['Boolean']['input'];
  isVatExcluded: Scalars['Boolean']['input'];
  price: Scalars['Float']['input'];
};

export type SaveVehicleMediasDto = {
  medias: Array<SaveSingleVehicleMediaDto>;
  vehicleId: Scalars['Int']['input'];
};

export type SocialMediaTokenExpiration = {
  __typename?: 'SocialMediaTokenExpiration';
  facebookExpiresAt?: Maybe<Scalars['DateTime']['output']>;
  linkedinExpiresAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Subscription = {
  __typename?: 'Subscription';
  notificationFeed: PaginatedNotification;
};

export type TokenValidationDto = {
  token: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  activeSubscriptions: Array<UserSubscription>;
  addresses: Array<Address>;
  averageRatingScore?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dealer?: Maybe<Dealer>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  gender: UserGender;
  id: Scalars['Int']['output'];
  kpi?: Maybe<UserKpi>;
  language: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  latestClientSubscription?: Maybe<UserSubscription>;
  latestSellerSubscription?: Maybe<UserSubscription>;
  latestSubscriptions?: Maybe<Array<UserSubscription>>;
  phoneNumber: Scalars['String']['output'];
  roles: Array<Scalars['String']['output']>;
  subscriptions: Array<UserSubscription>;
  updatedAt: Scalars['DateTime']['output'];
  validatedAt?: Maybe<Scalars['DateTime']['output']>;
  vehicleIdsInFavorites?: Maybe<Array<Scalars['Int']['output']>>;
};

export enum UserGender {
  Miss = 'MISS',
  Mr = 'MR',
  Mrs = 'MRS'
}

export type UserKpi = {
  __typename?: 'UserKpi';
  averageRatingScore?: Maybe<Scalars['Float']['output']>;
  numberOfMyAcceptedOffers?: Maybe<Scalars['Int']['output']>;
  numberOfMyDeclinedOffers?: Maybe<Scalars['Int']['output']>;
  numberOfMyDeletedVehicles?: Maybe<Scalars['Int']['output']>;
  numberOfMyDraftVehicles?: Maybe<Scalars['Int']['output']>;
  numberOfMyExpiredVehicles?: Maybe<Scalars['Int']['output']>;
  numberOfMyFavorites?: Maybe<Scalars['Int']['output']>;
  numberOfMyFinishedVehicles?: Maybe<Scalars['Int']['output']>;
  numberOfMyHiddenVehicles?: Maybe<Scalars['Int']['output']>;
  numberOfMyOffers?: Maybe<Scalars['Int']['output']>;
  numberOfMyOpenedVehicles?: Maybe<Scalars['Int']['output']>;
  numberOfMyPublishedVehicles?: Maybe<Scalars['Int']['output']>;
  numberOfMyValidatedVehicles?: Maybe<Scalars['Int']['output']>;
  numberOfMyVehicles?: Maybe<Scalars['Int']['output']>;
  numberOfMyWaitingOffers?: Maybe<Scalars['Int']['output']>;
  numberOfMyWonOffers?: Maybe<Scalars['Int']['output']>;
  numberOfVehiclesOnSale?: Maybe<Scalars['Int']['output']>;
};

export type UserOpenedVehicle = {
  __typename?: 'UserOpenedVehicle';
  count: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
  vehicle?: Maybe<Vehicle>;
  vehicleId: Scalars['Int']['output'];
};

export enum UserRatingType {
  Buyer = 'BUYER',
  Seller = 'SELLER'
}

export type UserRecoveryPassword = {
  __typename?: 'UserRecoveryPassword';
  code: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  usedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserSubscription = {
  __typename?: 'UserSubscription';
  createdAt: Scalars['DateTime']['output'];
  endAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  startAt?: Maybe<Scalars['DateTime']['output']>;
  status: UserSubscriptionStatus;
  type: UserSubscriptionType;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  userId: Scalars['Int']['output'];
};

export enum UserSubscriptionStatus {
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
  Waiting = 'WAITING'
}

export enum UserSubscriptionType {
  Client = 'CLIENT',
  Seller = 'SELLER'
}

export type UserVehicleFavorite = {
  __typename?: 'UserVehicleFavorite';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  userId: Scalars['Int']['output'];
  vehicle?: Maybe<Vehicle>;
  vehicleId: Scalars['Int']['output'];
};

export type ValidateRecoveryPasswordCodeDto = {
  code: Scalars['Float']['input'];
  email: Scalars['String']['input'];
};

export type ValidateUserAccountDto = {
  code: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

export type Vehicle = {
  __typename?: 'Vehicle';
  abandonedAt?: Maybe<Scalars['DateTime']['output']>;
  addedByUser?: Maybe<User>;
  addedByUserId: Scalars['Int']['output'];
  addressId: Scalars['Int']['output'];
  addressToCollect?: Maybe<Address>;
  attributes?: Maybe<Array<VehicleAttribute>>;
  availableAt: Scalars['DateTime']['output'];
  brand?: Maybe<Brand>;
  brandId: Scalars['Int']['output'];
  contact?: Maybe<DealerContact>;
  contactId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  damages?: Maybe<Array<VehicleDamage>>;
  dealer?: Maybe<Dealer>;
  dealerId: Scalars['Int']['output'];
  equipments?: Maybe<Array<VehicleEquipment>>;
  expertiseDocumentMedia?: Maybe<VehicleMedia>;
  expiredAt?: Maybe<Scalars['DateTime']['output']>;
  finishedAt?: Maybe<Scalars['DateTime']['output']>;
  frontSideMedia?: Maybe<VehicleMedia>;
  frontSideThumbnailMedia?: Maybe<VehicleMedia>;
  id: Scalars['Int']['output'];
  immediatePurchase: VehicleImmediatePurchase;
  medias?: Maybe<Array<VehicleMedia>>;
  model?: Maybe<Model>;
  modelId: Scalars['Int']['output'];
  numberOfOffers: Scalars['Int']['output'];
  offers?: Maybe<Array<Offer>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  rating?: Maybe<VehicleRating>;
  status: VehicleStatus;
  updatedAt: Scalars['DateTime']['output'];
  validatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type VehicleAttribute = {
  __typename?: 'VehicleAttribute';
  attribute?: Maybe<Attribute>;
  attributeId: Scalars['Int']['output'];
  attributeValue?: Maybe<AttributeValue>;
  attributeValueId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  value?: Maybe<Scalars['JSON']['output']>;
  vehicle?: Maybe<Vehicle>;
  vehicleId: Scalars['Int']['output'];
};

export type VehicleDamage = {
  __typename?: 'VehicleDamage';
  damage?: Maybe<Damage>;
  damageId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  medias?: Maybe<Array<VehicleMedia>>;
  vehicle?: Maybe<Vehicle>;
  vehicleId: Scalars['Int']['output'];
};

export type VehicleEquipment = {
  __typename?: 'VehicleEquipment';
  equipment?: Maybe<Equipment>;
  equipmentId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  vehicle?: Maybe<Vehicle>;
  vehicleId: Scalars['Int']['output'];
};

export type VehicleFilterDto = {
  attributeId?: InputMaybe<Scalars['Int']['input']>;
  attributeValueId?: InputMaybe<Scalars['Int']['input']>;
  brandId?: InputMaybe<Scalars['JSON']['input']>;
  id?: InputMaybe<Scalars['JSON']['input']>;
  modelId?: InputMaybe<Scalars['JSON']['input']>;
  notInIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  notOfUserId?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<VehicleFilterValueDto>;
};

export type VehicleFilterValueDto = {
  equals?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  range?: InputMaybe<VehicleFilterValueRangeDto>;
};

export type VehicleFilterValueRangeDto = {
  from?: InputMaybe<Scalars['JSON']['input']>;
  to?: InputMaybe<Scalars['JSON']['input']>;
};

export type VehicleImmediatePurchase = {
  __typename?: 'VehicleImmediatePurchase';
  enabled: Scalars['Boolean']['output'];
  isVatExcluded: Scalars['Boolean']['output'];
  price: Scalars['Float']['output'];
};

export type VehicleMedia = {
  __typename?: 'VehicleMedia';
  createdAt: Scalars['DateTime']['output'];
  damage?: Maybe<VehicleDamage>;
  equipment?: Maybe<VehicleEquipment>;
  file?: Maybe<File>;
  fileId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  tag: VehicleMediaTag;
  type: VehicleMediaType;
  vehicle?: Maybe<Vehicle>;
  vehicleDamageId?: Maybe<Scalars['Int']['output']>;
  vehicleEquipmentId?: Maybe<Scalars['Int']['output']>;
  vehicleId: Scalars['Int']['output'];
};

export enum VehicleMediaTag {
  BackSide = 'BACK_SIDE',
  BackSideThumbnail = 'BACK_SIDE_THUMBNAIL',
  Damage = 'DAMAGE',
  Equipment = 'EQUIPMENT',
  ExpertiseDocument = 'EXPERTISE_DOCUMENT',
  FrontSide = 'FRONT_SIDE',
  FrontSideThumbnail = 'FRONT_SIDE_THUMBNAIL',
  Interior = 'INTERIOR',
  LeftBackCorner = 'LEFT_BACK_CORNER',
  LeftBackCornerThumbnail = 'LEFT_BACK_CORNER_THUMBNAIL',
  LeftFrontCorner = 'LEFT_FRONT_CORNER',
  LeftFrontCornerThumbnail = 'LEFT_FRONT_CORNER_THUMBNAIL',
  LeftSide = 'LEFT_SIDE',
  LeftSideThumbnail = 'LEFT_SIDE_THUMBNAIL',
  None = 'NONE',
  RightBackCorner = 'RIGHT_BACK_CORNER',
  RightBackCornerThumbnail = 'RIGHT_BACK_CORNER_THUMBNAIL',
  RightFrontCorner = 'RIGHT_FRONT_CORNER',
  RightFrontCornerThumbnail = 'RIGHT_FRONT_CORNER_THUMBNAIL',
  RightSide = 'RIGHT_SIDE',
  RightSideThumbnail = 'RIGHT_SIDE_THUMBNAIL',
  WebsiteDetailsThumbnail = 'WEBSITE_DETAILS_THUMBNAIL',
  WebsiteListing = 'WEBSITE_LISTING',
  WebsiteMain = 'WEBSITE_MAIN'
}

export enum VehicleMediaType {
  Image = 'IMAGE',
  Pdf = 'PDF',
  Video = 'VIDEO'
}

export type VehicleRating = {
  __typename?: 'VehicleRating';
  isBuyerRated?: Maybe<Scalars['Boolean']['output']>;
  isSellerRated?: Maybe<Scalars['Boolean']['output']>;
};

export enum VehicleSocialMediaPublicationType {
  Facebook = 'FACEBOOK',
  Linkedin = 'LINKEDIN'
}

export enum VehicleStatus {
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  Finished = 'FINISHED',
  Imported = 'IMPORTED',
  Published = 'PUBLISHED',
  Validated = 'VALIDATED'
}

export type WebsiteEvent = {
  __typename?: 'WebsiteEvent';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  payload: Scalars['JSON']['output'];
};

export type DeleteUserAsAdminMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteUserAsAdminMutation = { __typename?: 'Mutation', deleteUserAsAdmin: boolean };

export type GetAllUsersForAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUsersForAdminQuery = { __typename?: 'Query', getAllUsersForAdmin: Array<{ __typename?: 'User', id: number, email: string, firstName: string, lastName: string, phoneNumber: string, roles: Array<string>, dealer?: { __typename?: 'Dealer', id: number, name: string, email: string, phoneNumber: string, vatNumber: string, iban: string, addresses?: Array<{ __typename?: 'Address', type: AddressType, street: string, number: string, city: string, zip: string, country: Country }> | null } | null, latestClientSubscription?: { __typename?: 'UserSubscription', id: number, status: UserSubscriptionStatus, startAt?: any | null, endAt?: any | null } | null, latestSellerSubscription?: { __typename?: 'UserSubscription', id: number, status: UserSubscriptionStatus, startAt?: any | null, endAt?: any | null } | null }> };

export type GetSocialMediaTokenExpirationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSocialMediaTokenExpirationsQuery = { __typename?: 'Query', getSocialMediaTokenExpirations: { __typename?: 'SocialMediaTokenExpiration', facebookExpiresAt?: any | null, linkedinExpiresAt?: any | null } };

export type ManageUserSubscriptionMutationVariables = Exact<{
  dto: ManageUserSubscriptionDto;
}>;


export type ManageUserSubscriptionMutation = { __typename?: 'Mutation', manageUserSubscription: { __typename?: 'UserSubscription', id: number } };

export type PublishVehicleOnSocialMediaRandomlyMutationVariables = Exact<{
  target: Array<VehicleSocialMediaPublicationType> | VehicleSocialMediaPublicationType;
}>;


export type PublishVehicleOnSocialMediaRandomlyMutation = { __typename?: 'Mutation', publishVehicleOnSocialMediaRandomly: boolean };

export type RefreshPublishedVehiclesInCacheMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshPublishedVehiclesInCacheMutation = { __typename?: 'Mutation', refreshPublishedVehiclesInCache: { __typename?: 'AdminTask', output?: any | null } };

export type ResetFacebookAccessTokenMutationVariables = Exact<{
  accessToken: Scalars['String']['input'];
}>;


export type ResetFacebookAccessTokenMutation = { __typename?: 'Mutation', resetFacebookAccessToken: boolean };

export type ResetLinkedinAccessTokenMutationVariables = Exact<{
  code: Scalars['String']['input'];
}>;


export type ResetLinkedinAccessTokenMutation = { __typename?: 'Mutation', resetLinkedinAccessToken: boolean };

export type RunImportsMutationVariables = Exact<{
  imports: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type RunImportsMutation = { __typename?: 'Mutation', runImports: boolean };

export type RunWebsiteVehicleMediaGenerationJobMutationVariables = Exact<{ [key: string]: never; }>;


export type RunWebsiteVehicleMediaGenerationJobMutation = { __typename?: 'Mutation', runWebsiteVehicleMediaGenerationJobUseCase: boolean };

export type DeleteAttributeMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteAttributeMutation = { __typename?: 'Mutation', deleteAttribute: boolean };

export type DeleteAttributeValueMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteAttributeValueMutation = { __typename?: 'Mutation', deleteAttributeValue: boolean };

export type GetAllAttributeCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllAttributeCategoriesQuery = { __typename?: 'Query', allAttributeCategories: Array<{ __typename?: 'AttributeCategory', id: number, name: any }> };

export type GetAllAttributesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllAttributesQuery = { __typename?: 'Query', allAttributes: Array<{ __typename?: 'Attribute', id: number, name: any, type: AttributeType, categoryId: number, mandatory: boolean, filterable: boolean, internalCode?: string | null, displayOrder: number, values?: Array<{ __typename?: 'AttributeValue', id: number, value: any }> | null, config?: { __typename?: 'AttributeConfig', form: { __typename?: 'AttributeFormConfig', placeholder: any } } | null }> };

export type GetAttributeQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetAttributeQuery = { __typename?: 'Query', attribute: { __typename?: 'Attribute', id: number, name: any, type: AttributeType, categoryId: number, mandatory: boolean, filterable: boolean, values?: Array<{ __typename?: 'AttributeValue', id: number, value: any }> | null } };

export type SaveAttributeMutationVariables = Exact<{
  dto: SaveAttributeDto;
}>;


export type SaveAttributeMutation = { __typename?: 'Mutation', saveAttribute: { __typename?: 'Attribute', id: number, categoryId: number, name: any, type: AttributeType, mandatory: boolean, filterable: boolean, values?: Array<{ __typename?: 'AttributeValue', id: number, value: any }> | null } };

export type SaveAttributeCategoryMutationVariables = Exact<{
  dto: SaveAttributeCategoryDto;
}>;


export type SaveAttributeCategoryMutation = { __typename?: 'Mutation', saveAttributeCategory: { __typename?: 'AttributeCategory', id: number, name: any } };

export type LoginQueryVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginQuery = { __typename?: 'Query', login: string };

export type LoginUnderUserQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type LoginUnderUserQuery = { __typename?: 'Query', loginUnderUser: string };

export type ValidateTokenQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type ValidateTokenQuery = { __typename?: 'Query', validateToken: boolean };

export type GetAllBrandsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllBrandsQuery = { __typename?: 'Query', allBrands: Array<{ __typename?: 'Brand', id: number, name: string, models?: Array<{ __typename?: 'Model', id: number, name: string, brandId: number, dealerId?: number | null }> | null }> };

export type SaveBrandMutationVariables = Exact<{
  dto: SaveBrandDto;
}>;


export type SaveBrandMutation = { __typename?: 'Mutation', saveBrand: { __typename?: 'Brand', id: number, name: string, models?: Array<{ __typename?: 'Model', id: number, name: string, brandId: number }> | null } };

export type DeleteDamageMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteDamageMutation = { __typename?: 'Mutation', deleteDamage: boolean };

export type DeleteDamageLocationMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteDamageLocationMutation = { __typename?: 'Mutation', deleteDamageLocation: boolean };

export type GetAllDamageLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllDamageLocationsQuery = { __typename?: 'Query', allDamageLocations: Array<{ __typename?: 'DamageLocation', id: number, name: any }> };

export type GetAllDamagesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllDamagesQuery = { __typename?: 'Query', allDamages: Array<{ __typename?: 'Damage', id: number, name: any, dealerId?: number | null }> };

export type SaveDamageMutationVariables = Exact<{
  dto: SaveDamageDto;
}>;


export type SaveDamageMutation = { __typename?: 'Mutation', saveDamage: { __typename?: 'Damage', id: number, name: any } };

export type SaveDamageLocationMutationVariables = Exact<{
  dto: SaveDamageLocationDto;
}>;


export type SaveDamageLocationMutation = { __typename?: 'Mutation', saveDamageLocation: { __typename?: 'DamageLocation', id: number, name: any } };

export type GetMyDealerQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyDealerQuery = { __typename?: 'Query', myDealer: { __typename?: 'Dealer', id: number, email: string, phoneNumber: string, vatNumber: string, addresses?: Array<{ __typename?: 'Address', id: number, street: string, number: string, city: string, zip: string, country: Country, type: AddressType }> | null, contacts?: Array<{ __typename?: 'DealerContact', id: number, email?: string | null, firstName: string, lastName: string, phoneNumber?: string | null }> | null } };

export type GetOnePublicDealerQueryVariables = Exact<{
  dealerId: Scalars['Int']['input'];
}>;


export type GetOnePublicDealerQuery = { __typename?: 'Query', publicDealer: { __typename?: 'PublicDealer', name: string, numberOfPublishedVehicles: number } };

export type SaveDealerMutationVariables = Exact<{
  dto: SaveDealerDto;
}>;


export type SaveDealerMutation = { __typename?: 'Mutation', saveDealer: { __typename?: 'Dealer', id: number, name: string, email: string, phoneNumber: string, vatNumber: string, iban: string } };

export type SaveDealerAddressMutationVariables = Exact<{
  dto: SaveDealerAddressDto;
}>;


export type SaveDealerAddressMutation = { __typename?: 'Mutation', saveDealerAddress: { __typename?: 'Dealer', id: number } };

export type SaveDealerContactMutationVariables = Exact<{
  dto: SaveDealerContactDto;
}>;


export type SaveDealerContactMutation = { __typename?: 'Mutation', saveDealerContact: { __typename?: 'Dealer', id: number } };

export type SaveDealerLogoMutationVariables = Exact<{
  dto: SaveDealerLogoDto;
}>;


export type SaveDealerLogoMutation = { __typename?: 'Mutation', saveDealerLogo: { __typename?: 'Dealer', id: number } };

export type DeleteEquipmentMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteEquipmentMutation = { __typename?: 'Mutation', deleteEquipment: boolean };

export type GetAllEquipmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllEquipmentsQuery = { __typename?: 'Query', allEquipments: Array<{ __typename?: 'Equipment', id: number, name: any, dealerId?: number | null }> };

export type SaveEquipmentMutationVariables = Exact<{
  dto: SaveEquipmentDto;
}>;


export type SaveEquipmentMutation = { __typename?: 'Mutation', saveEquipment: { __typename?: 'Equipment', id: number, name: any } };

export type SaveModelMutationVariables = Exact<{
  dto: SaveModelDto;
}>;


export type SaveModelMutation = { __typename?: 'Mutation', saveModel: { __typename?: 'Model', id: number, name: string, brandId: number } };

export type MarkInAppNotificationsAsReadMutationVariables = Exact<{ [key: string]: never; }>;


export type MarkInAppNotificationsAsReadMutation = { __typename?: 'Mutation', markInAppNotificationsAsRead: boolean };

export type AcceptOfferMutationVariables = Exact<{
  offerId: Scalars['Int']['input'];
}>;


export type AcceptOfferMutation = { __typename?: 'Mutation', acceptOffer: { __typename?: 'Offer', id: number } };

export type CancelOfferMutationVariables = Exact<{
  offerId: Scalars['Int']['input'];
}>;


export type CancelOfferMutation = { __typename?: 'Mutation', cancelOffer: { __typename?: 'Offer', id: number } };

export type DeclineOfferMutationVariables = Exact<{
  dto: DeclineOfferDto;
}>;


export type DeclineOfferMutation = { __typename?: 'Mutation', declineOffer: { __typename?: 'Offer', id: number } };

export type GetAllOffersOfMyVehicleQueryVariables = Exact<{
  vehicleId: Scalars['Int']['input'];
}>;


export type GetAllOffersOfMyVehicleQuery = { __typename?: 'Query', offersOfMyVehicle: Array<{ __typename?: 'Offer', id: number, type: OfferType, amount: number, userId: number, statuses?: Array<{ __typename?: 'OfferStatus', id: number, type: OfferStatusType, reason?: string | null, createdAt: any }> | null, user?: { __typename?: 'User', id: number, lastName: string, firstName: string, averageRatingScore?: number | null, dealer?: { __typename?: 'Dealer', name: string, phoneNumber: string } | null } | null }> };

export type GetMyOffersForVehiclesQueryVariables = Exact<{
  vehicleIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type GetMyOffersForVehiclesQuery = { __typename?: 'Query', myOffersForVehicles: Array<{ __typename?: 'Offer', id: number, vehicleId: number, type: OfferType, amount: number, statuses?: Array<{ __typename?: 'OfferStatus', id: number, type: OfferStatusType, reason?: string | null }> | null }> };

export type SaveOfferMutationVariables = Exact<{
  dto: SaveOfferDto;
}>;


export type SaveOfferMutation = { __typename?: 'Mutation', saveOffer: { __typename?: 'Offer', id: number } };

export type AskToBecomeClientMutationVariables = Exact<{ [key: string]: never; }>;


export type AskToBecomeClientMutation = { __typename?: 'Mutation', askToBecomeClient: { __typename?: 'UserSubscription', id: number } };

export type AskToBecomeSellerMutationVariables = Exact<{ [key: string]: never; }>;


export type AskToBecomeSellerMutation = { __typename?: 'Mutation', askToBecomeSeller: { __typename?: 'UserSubscription', id: number } };

export type ChangeMyLanguageMutationVariables = Exact<{
  language: Language;
}>;


export type ChangeMyLanguageMutation = { __typename?: 'Mutation', changeLanguage: boolean };

export type ChangeMyPasswordMutationVariables = Exact<{
  dto: ChangeMyPasswordDto;
}>;


export type ChangeMyPasswordMutation = { __typename?: 'Mutation', changePassword: boolean };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', getLoggedUser: { __typename?: 'User', id: number, email: string, firstName: string, lastName: string, phoneNumber: string, gender: UserGender, roles: Array<string>, validatedAt?: any | null, vehicleIdsInFavorites?: Array<number> | null, averageRatingScore?: number | null, language: string, kpi?: { __typename?: 'UserKpi', numberOfMyFavorites?: number | null, numberOfMyOffers?: number | null, numberOfMyAcceptedOffers?: number | null, numberOfMyWonOffers?: number | null, numberOfMyDeclinedOffers?: number | null, numberOfMyWaitingOffers?: number | null, numberOfMyVehicles?: number | null, numberOfMyDraftVehicles?: number | null, numberOfMyPublishedVehicles?: number | null, numberOfMyExpiredVehicles?: number | null, numberOfMyValidatedVehicles?: number | null, numberOfMyFinishedVehicles?: number | null, numberOfMyDeletedVehicles?: number | null, numberOfVehiclesOnSale?: number | null, numberOfMyHiddenVehicles?: number | null, numberOfMyOpenedVehicles?: number | null, averageRatingScore?: number | null } | null, dealer?: { __typename?: 'Dealer', id: number, name: string, vatNumber: string, phoneNumber: string, email: string, iban: string, logo?: { __typename?: 'File', id: number, uri: string } | null, addresses?: Array<{ __typename?: 'Address', id: number, street: string, number: string, city: string, zip: string, country: Country, type: AddressType }> | null, contacts?: Array<{ __typename?: 'DealerContact', id: number, firstName: string, lastName: string, phoneNumber?: string | null, email?: string | null }> | null } | null, latestClientSubscription?: { __typename?: 'UserSubscription', id: number, userId: number, startAt?: any | null, endAt?: any | null, status: UserSubscriptionStatus } | null, latestSellerSubscription?: { __typename?: 'UserSubscription', id: number, userId: number, startAt?: any | null, endAt?: any | null, status: UserSubscriptionStatus } | null } };

export type RegisterMutationVariables = Exact<{
  dto: RegisterDto;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'User', id: number, email: string, firstName: string, lastName: string, phoneNumber: string, gender: UserGender } };

export type SaveUserMutationVariables = Exact<{
  dto: SaveUserDto;
}>;


export type SaveUserMutation = { __typename?: 'Mutation', saveUser: { __typename?: 'User', id: number, email: string, firstName: string, lastName: string, phoneNumber: string, gender: UserGender } };

export type ValidateUserAccountMutationVariables = Exact<{
  dto: ValidateUserAccountDto;
}>;


export type ValidateUserAccountMutation = { __typename?: 'Mutation', validateUser: boolean };

export type AddVehicleToFavoritesMutationVariables = Exact<{
  vehicleId: Scalars['Int']['input'];
}>;


export type AddVehicleToFavoritesMutation = { __typename?: 'Mutation', addVehicleToFavorites: { __typename?: 'UserVehicleFavorite', id: number } };

export type DeleteVehicleFromFavoritesMutationVariables = Exact<{
  vehicleId: Scalars['Int']['input'];
}>;


export type DeleteVehicleFromFavoritesMutation = { __typename?: 'Mutation', deleteVehicleFromFavorites: boolean };

export type AddUserHiddenVehicleMutationVariables = Exact<{
  vehicleId: Scalars['Int']['input'];
}>;


export type AddUserHiddenVehicleMutation = { __typename?: 'Mutation', addUserHiddenVehicle: { __typename?: 'UserSubscription', id: number } };

export type AddUserOpenedVehicleMutationVariables = Exact<{
  vehicleId: Scalars['Int']['input'];
}>;


export type AddUserOpenedVehicleMutation = { __typename?: 'Mutation', addUserOpenedVehicle: { __typename?: 'UserOpenedVehicle', id: number } };

export type RateSomeoneMutationVariables = Exact<{
  dto: RateSomeoneDto;
}>;


export type RateSomeoneMutation = { __typename?: 'Mutation', rateSomeone: { __typename?: 'UserSubscription', id: number } };

export type AskRecoveryPasswordCodeMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type AskRecoveryPasswordCodeMutation = { __typename?: 'Mutation', askRecoveryPasswordCode: boolean };

export type RecoverPasswordMutationVariables = Exact<{
  dto: RecoverPasswordDto;
}>;


export type RecoverPasswordMutation = { __typename?: 'Mutation', recoverPassword: { __typename?: 'UserRecoveryPassword', id: number } };

export type ValidateRecoveryPasswordCodeQueryVariables = Exact<{
  dto: ValidateRecoveryPasswordCodeDto;
}>;


export type ValidateRecoveryPasswordCodeQuery = { __typename?: 'Query', validateRecoveryPasswordCode: { __typename?: 'UserRecoveryPassword', id: number } };

export type CancelVehicleMutationVariables = Exact<{
  dto: CancelVehicleDto;
}>;


export type CancelVehicleMutation = { __typename?: 'Mutation', cancelVehicle: { __typename?: 'Vehicle', id: number } };

export type DeleteOneVehicleMediaMutationVariables = Exact<{
  mediaId: Scalars['Int']['input'];
}>;


export type DeleteOneVehicleMediaMutation = { __typename?: 'Mutation', deleteVehicleMedia: boolean };

export type FinalizeVehicleSaleMutationVariables = Exact<{
  vehicleId: Scalars['Int']['input'];
}>;


export type FinalizeVehicleSaleMutation = { __typename?: 'Mutation', finalizeVehicleSale: { __typename?: 'Vehicle', id: number } };

export type GenerateVehicleMediaThumbnailsMutationVariables = Exact<{
  dto: GenerateVehicleMediaThumbnailDto;
}>;


export type GenerateVehicleMediaThumbnailsMutation = { __typename?: 'Mutation', generateVehicleMediaThumbnails: boolean };

export type GetMyPaginatedVehiclesQueryVariables = Exact<{
  dto: GetMyPaginatedVehiclesDto;
}>;


export type GetMyPaginatedVehiclesQuery = { __typename?: 'Query', myPaginatedVehicles: { __typename?: 'PaginatedVehicle', total: number, results: Array<{ __typename?: 'Vehicle', id: number, status: VehicleStatus, numberOfOffers: number, addedByUserId: number, publishedAt?: any | null, availableAt: any, dealer?: { __typename?: 'Dealer', id: number, name: string } | null, immediatePurchase: { __typename?: 'VehicleImmediatePurchase', enabled: boolean, price: number }, brand?: { __typename?: 'Brand', name: string } | null, model?: { __typename?: 'Model', name: string } | null, attributes?: Array<{ __typename?: 'VehicleAttribute', attributeId: number, value?: any | null, attributeValueId?: number | null }> | null, frontSideThumbnailMedia?: { __typename?: 'VehicleMedia', file?: { __typename?: 'File', uri: string } | null } | null, addressToCollect?: { __typename?: 'Address', city: string, country: Country } | null, rating?: { __typename?: 'VehicleRating', isBuyerRated?: boolean | null } | null }> } };

export type GetOneVehicleQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetOneVehicleQuery = { __typename?: 'Query', vehicleById: { __typename?: 'Vehicle', id: number, status: VehicleStatus, addedByUserId: number, addressId: number, contactId?: number | null, brandId: number, modelId: number, availableAt: any, addedByUser?: { __typename?: 'User', firstName: string, lastName: string, averageRatingScore?: number | null, dealer?: { __typename?: 'Dealer', id: number, name: string, logo?: { __typename?: 'File', uri: string } | null } | null } | null, immediatePurchase: { __typename?: 'VehicleImmediatePurchase', enabled: boolean, isVatExcluded: boolean, price: number }, brand?: { __typename?: 'Brand', name: string } | null, model?: { __typename?: 'Model', name: string } | null, addressToCollect?: { __typename?: 'Address', street: string, number: string, city: string, zip: string, country: Country } | null, contact?: { __typename?: 'DealerContact', lastName: string, firstName: string, email?: string | null, phoneNumber?: string | null } | null, attributes?: Array<{ __typename?: 'VehicleAttribute', id: number, attributeId: number, value?: any | null, attributeValueId?: number | null, attribute?: { __typename?: 'Attribute', name: any, type: AttributeType, categoryId: number } | null, attributeValue?: { __typename?: 'AttributeValue', value: any } | null }> | null, equipments?: Array<{ __typename?: 'VehicleEquipment', id: number, equipmentId: number, equipment?: { __typename?: 'Equipment', name: any } | null }> | null, damages?: Array<{ __typename?: 'VehicleDamage', id: number, damageId: number, damage?: { __typename?: 'Damage', name: any } | null }> | null, medias?: Array<{ __typename?: 'VehicleMedia', id: number, fileId: number, type: VehicleMediaType, tag: VehicleMediaTag, vehicleDamageId?: number | null, vehicleEquipmentId?: number | null, file?: { __typename?: 'File', id: number, uri: string } | null }> | null, expertiseDocumentMedia?: { __typename?: 'VehicleMedia', file?: { __typename?: 'File', uri: string } | null } | null } };

export type GetPaginatedOpenedVehiclesQueryVariables = Exact<{
  dto: GetPaginatedOpenedVehiclesDto;
}>;


export type GetPaginatedOpenedVehiclesQuery = { __typename?: 'Query', paginatedOpenedVehicles: { __typename?: 'PaginatedVehicle', total: number, results: Array<{ __typename?: 'Vehicle', id: number, status: VehicleStatus, numberOfOffers: number, addedByUserId: number, publishedAt?: any | null, availableAt: any, dealer?: { __typename?: 'Dealer', id: number, name: string } | null, immediatePurchase: { __typename?: 'VehicleImmediatePurchase', enabled: boolean, price: number }, brand?: { __typename?: 'Brand', name: string } | null, model?: { __typename?: 'Model', name: string } | null, attributes?: Array<{ __typename?: 'VehicleAttribute', attributeId: number, value?: any | null, attributeValueId?: number | null }> | null, frontSideThumbnailMedia?: { __typename?: 'VehicleMedia', file?: { __typename?: 'File', uri: string } | null } | null, addressToCollect?: { __typename?: 'Address', city: string, country: Country } | null }> } };

export type GetPaginatedPublishedVehiclesQueryVariables = Exact<{
  dto: GetPaginatedPublishedVehiclesDto;
}>;


export type GetPaginatedPublishedVehiclesQuery = { __typename?: 'Query', paginatedPublishedVehicles: { __typename?: 'PaginatedVehicle', total: number, results: Array<{ __typename?: 'Vehicle', id: number, status: VehicleStatus, numberOfOffers: number, addedByUserId: number, publishedAt?: any | null, availableAt: any, dealer?: { __typename?: 'Dealer', id: number, name: string } | null, immediatePurchase: { __typename?: 'VehicleImmediatePurchase', enabled: boolean, price: number }, brand?: { __typename?: 'Brand', name: string } | null, model?: { __typename?: 'Model', name: string } | null, attributes?: Array<{ __typename?: 'VehicleAttribute', attributeId: number, value?: any | null, attributeValueId?: number | null }> | null, frontSideThumbnailMedia?: { __typename?: 'VehicleMedia', file?: { __typename?: 'File', uri: string } | null } | null, addressToCollect?: { __typename?: 'Address', city: string, country: Country } | null }> } };

export type GetPaginatedPublishedVehiclesByDealerQueryVariables = Exact<{
  dto: GetPaginatedPublishedVehiclesByDealerDto;
}>;


export type GetPaginatedPublishedVehiclesByDealerQuery = { __typename?: 'Query', paginatedPublishedVehiclesByDealer: { __typename?: 'PaginatedVehicle', total: number, results: Array<{ __typename?: 'Vehicle', id: number, status: VehicleStatus, numberOfOffers: number, addedByUserId: number, publishedAt?: any | null, availableAt: any, dealer?: { __typename?: 'Dealer', id: number, name: string } | null, immediatePurchase: { __typename?: 'VehicleImmediatePurchase', enabled: boolean, price: number }, brand?: { __typename?: 'Brand', name: string } | null, model?: { __typename?: 'Model', name: string } | null, attributes?: Array<{ __typename?: 'VehicleAttribute', attributeId: number, value?: any | null, attributeValueId?: number | null }> | null, frontSideThumbnailMedia?: { __typename?: 'VehicleMedia', file?: { __typename?: 'File', uri: string } | null } | null, addressToCollect?: { __typename?: 'Address', city: string, country: Country } | null }> } };

export type GetPaginatedVehiclesFromMyFavoritesQueryVariables = Exact<{
  dto: GetPaginatedVehiclesFromMyFavoritesDto;
}>;


export type GetPaginatedVehiclesFromMyFavoritesQuery = { __typename?: 'Query', paginatedVehiclesFromMyFavorites: { __typename?: 'PaginatedVehicle', total: number, results: Array<{ __typename?: 'Vehicle', id: number, status: VehicleStatus, numberOfOffers: number, addedByUserId: number, publishedAt?: any | null, availableAt: any, dealer?: { __typename?: 'Dealer', id: number, name: string } | null, immediatePurchase: { __typename?: 'VehicleImmediatePurchase', enabled: boolean, price: number }, brand?: { __typename?: 'Brand', name: string } | null, model?: { __typename?: 'Model', name: string } | null, attributes?: Array<{ __typename?: 'VehicleAttribute', attributeId: number, value?: any | null, attributeValueId?: number | null }> | null, frontSideThumbnailMedia?: { __typename?: 'VehicleMedia', file?: { __typename?: 'File', uri: string } | null } | null, addressToCollect?: { __typename?: 'Address', city: string, country: Country } | null }> } };

export type GetPaginatedVehiclesFromMyOffersQueryVariables = Exact<{
  dto: GetPaginatedVehiclesFromMyOffersDto;
}>;


export type GetPaginatedVehiclesFromMyOffersQuery = { __typename?: 'Query', paginatedVehiclesFromMyOffers: { __typename?: 'PaginatedVehicle', total: number, results: Array<{ __typename?: 'Vehicle', id: number, status: VehicleStatus, numberOfOffers: number, addedByUserId: number, publishedAt?: any | null, availableAt: any, dealer?: { __typename?: 'Dealer', id: number, name: string } | null, immediatePurchase: { __typename?: 'VehicleImmediatePurchase', enabled: boolean, price: number }, brand?: { __typename?: 'Brand', name: string } | null, model?: { __typename?: 'Model', name: string } | null, attributes?: Array<{ __typename?: 'VehicleAttribute', attributeId: number, value?: any | null, attributeValueId?: number | null }> | null, frontSideThumbnailMedia?: { __typename?: 'VehicleMedia', file?: { __typename?: 'File', uri: string } | null } | null, addressToCollect?: { __typename?: 'Address', city: string, country: Country } | null }> } };

export type GetVehicleSaleOrderFormQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetVehicleSaleOrderFormQuery = { __typename?: 'Query', vehicleSaleOrderForm: string };

export type GetVehicleToUpdateQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetVehicleToUpdateQuery = { __typename?: 'Query', vehicleById: { __typename?: 'Vehicle', id: number, status: VehicleStatus, addedByUserId: number, addressId: number, contactId?: number | null, brandId: number, modelId: number, availableAt: any, immediatePurchase: { __typename?: 'VehicleImmediatePurchase', enabled: boolean, isVatExcluded: boolean, price: number }, attributes?: Array<{ __typename?: 'VehicleAttribute', id: number, attributeId: number, value?: any | null, attributeValueId?: number | null }> | null, equipments?: Array<{ __typename?: 'VehicleEquipment', id: number, equipmentId: number }> | null, damages?: Array<{ __typename?: 'VehicleDamage', id: number, damageId: number }> | null, medias?: Array<{ __typename?: 'VehicleMedia', id: number, fileId: number, type: VehicleMediaType, tag: VehicleMediaTag, vehicleDamageId?: number | null, vehicleEquipmentId?: number | null, file?: { __typename?: 'File', id: number, uri: string } | null }> | null } };

export type PublishVehicleMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type PublishVehicleMutation = { __typename?: 'Mutation', publishVehicle: { __typename?: 'Vehicle', id: number } };

export type RepublishVehicleMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type RepublishVehicleMutation = { __typename?: 'Mutation', republishVehicle: { __typename?: 'Vehicle', id: number } };

export type SaveVehicleMutationVariables = Exact<{
  dto: SaveVehicleDto;
}>;


export type SaveVehicleMutation = { __typename?: 'Mutation', saveVehicle: { __typename?: 'Vehicle', id: number, damages?: Array<{ __typename?: 'VehicleDamage', id: number, damageId: number }> | null, equipments?: Array<{ __typename?: 'VehicleEquipment', id: number, equipmentId: number }> | null } };

export type SaveVehicleMediasMutationVariables = Exact<{
  dto: SaveVehicleMediasDto;
}>;


export type SaveVehicleMediasMutation = { __typename?: 'Mutation', saveVehicleMedias: Array<{ __typename?: 'VehicleMedia', id: number }> };

export type SaveWebsiteEventMutationVariables = Exact<{
  dto: SaveEventDto;
}>;


export type SaveWebsiteEventMutation = { __typename?: 'Mutation', saveWebsiteEvent: { __typename?: 'WebsiteEvent', id: number } };

export type NotificationFeedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NotificationFeedSubscription = { __typename?: 'Subscription', notificationFeed: { __typename?: 'PaginatedNotification', total: number, results: Array<{ __typename?: 'Notification', id: number, type: string, status: string, channel: string, payload: any, userId: number, readAt?: any | null, createdAt: any }> } };


export const DeleteUserAsAdminDocument = gql`
    mutation DeleteUserAsAdmin($id: Int!) {
  deleteUserAsAdmin(id: $id)
}
    `;
export type DeleteUserAsAdminMutationFn = Apollo.MutationFunction<DeleteUserAsAdminMutation, DeleteUserAsAdminMutationVariables>;

/**
 * __useDeleteUserAsAdminMutation__
 *
 * To run a mutation, you first call `useDeleteUserAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserAsAdminMutation, { data, loading, error }] = useDeleteUserAsAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserAsAdminMutation, DeleteUserAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserAsAdminMutation, DeleteUserAsAdminMutationVariables>(DeleteUserAsAdminDocument, options);
      }
export type DeleteUserAsAdminMutationHookResult = ReturnType<typeof useDeleteUserAsAdminMutation>;
export type DeleteUserAsAdminMutationResult = Apollo.MutationResult<DeleteUserAsAdminMutation>;
export type DeleteUserAsAdminMutationOptions = Apollo.BaseMutationOptions<DeleteUserAsAdminMutation, DeleteUserAsAdminMutationVariables>;
export const GetAllUsersForAdminDocument = gql`
    query GetAllUsersForAdmin {
  getAllUsersForAdmin {
    id
    email
    firstName
    lastName
    phoneNumber
    roles
    dealer {
      id
      name
      email
      phoneNumber
      vatNumber
      iban
      addresses {
        type
        street
        number
        city
        zip
        country
      }
    }
    latestClientSubscription {
      id
      status
      startAt
      endAt
    }
    latestSellerSubscription {
      id
      status
      startAt
      endAt
    }
  }
}
    `;

/**
 * __useGetAllUsersForAdminQuery__
 *
 * To run a query within a React component, call `useGetAllUsersForAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersForAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersForAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUsersForAdminQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUsersForAdminQuery, GetAllUsersForAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUsersForAdminQuery, GetAllUsersForAdminQueryVariables>(GetAllUsersForAdminDocument, options);
      }
export function useGetAllUsersForAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersForAdminQuery, GetAllUsersForAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUsersForAdminQuery, GetAllUsersForAdminQueryVariables>(GetAllUsersForAdminDocument, options);
        }
export function useGetAllUsersForAdminSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllUsersForAdminQuery, GetAllUsersForAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllUsersForAdminQuery, GetAllUsersForAdminQueryVariables>(GetAllUsersForAdminDocument, options);
        }
export type GetAllUsersForAdminQueryHookResult = ReturnType<typeof useGetAllUsersForAdminQuery>;
export type GetAllUsersForAdminLazyQueryHookResult = ReturnType<typeof useGetAllUsersForAdminLazyQuery>;
export type GetAllUsersForAdminSuspenseQueryHookResult = ReturnType<typeof useGetAllUsersForAdminSuspenseQuery>;
export type GetAllUsersForAdminQueryResult = Apollo.QueryResult<GetAllUsersForAdminQuery, GetAllUsersForAdminQueryVariables>;
export const GetSocialMediaTokenExpirationsDocument = gql`
    query GetSocialMediaTokenExpirations {
  getSocialMediaTokenExpirations {
    facebookExpiresAt
    linkedinExpiresAt
  }
}
    `;

/**
 * __useGetSocialMediaTokenExpirationsQuery__
 *
 * To run a query within a React component, call `useGetSocialMediaTokenExpirationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSocialMediaTokenExpirationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSocialMediaTokenExpirationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSocialMediaTokenExpirationsQuery(baseOptions?: Apollo.QueryHookOptions<GetSocialMediaTokenExpirationsQuery, GetSocialMediaTokenExpirationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSocialMediaTokenExpirationsQuery, GetSocialMediaTokenExpirationsQueryVariables>(GetSocialMediaTokenExpirationsDocument, options);
      }
export function useGetSocialMediaTokenExpirationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSocialMediaTokenExpirationsQuery, GetSocialMediaTokenExpirationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSocialMediaTokenExpirationsQuery, GetSocialMediaTokenExpirationsQueryVariables>(GetSocialMediaTokenExpirationsDocument, options);
        }
export function useGetSocialMediaTokenExpirationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSocialMediaTokenExpirationsQuery, GetSocialMediaTokenExpirationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSocialMediaTokenExpirationsQuery, GetSocialMediaTokenExpirationsQueryVariables>(GetSocialMediaTokenExpirationsDocument, options);
        }
export type GetSocialMediaTokenExpirationsQueryHookResult = ReturnType<typeof useGetSocialMediaTokenExpirationsQuery>;
export type GetSocialMediaTokenExpirationsLazyQueryHookResult = ReturnType<typeof useGetSocialMediaTokenExpirationsLazyQuery>;
export type GetSocialMediaTokenExpirationsSuspenseQueryHookResult = ReturnType<typeof useGetSocialMediaTokenExpirationsSuspenseQuery>;
export type GetSocialMediaTokenExpirationsQueryResult = Apollo.QueryResult<GetSocialMediaTokenExpirationsQuery, GetSocialMediaTokenExpirationsQueryVariables>;
export const ManageUserSubscriptionDocument = gql`
    mutation ManageUserSubscription($dto: ManageUserSubscriptionDto!) {
  manageUserSubscription(dto: $dto) {
    id
  }
}
    `;
export type ManageUserSubscriptionMutationFn = Apollo.MutationFunction<ManageUserSubscriptionMutation, ManageUserSubscriptionMutationVariables>;

/**
 * __useManageUserSubscriptionMutation__
 *
 * To run a mutation, you first call `useManageUserSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageUserSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageUserSubscriptionMutation, { data, loading, error }] = useManageUserSubscriptionMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useManageUserSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<ManageUserSubscriptionMutation, ManageUserSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManageUserSubscriptionMutation, ManageUserSubscriptionMutationVariables>(ManageUserSubscriptionDocument, options);
      }
export type ManageUserSubscriptionMutationHookResult = ReturnType<typeof useManageUserSubscriptionMutation>;
export type ManageUserSubscriptionMutationResult = Apollo.MutationResult<ManageUserSubscriptionMutation>;
export type ManageUserSubscriptionMutationOptions = Apollo.BaseMutationOptions<ManageUserSubscriptionMutation, ManageUserSubscriptionMutationVariables>;
export const PublishVehicleOnSocialMediaRandomlyDocument = gql`
    mutation PublishVehicleOnSocialMediaRandomly($target: [VehicleSocialMediaPublicationType!]!) {
  publishVehicleOnSocialMediaRandomly(target: $target)
}
    `;
export type PublishVehicleOnSocialMediaRandomlyMutationFn = Apollo.MutationFunction<PublishVehicleOnSocialMediaRandomlyMutation, PublishVehicleOnSocialMediaRandomlyMutationVariables>;

/**
 * __usePublishVehicleOnSocialMediaRandomlyMutation__
 *
 * To run a mutation, you first call `usePublishVehicleOnSocialMediaRandomlyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishVehicleOnSocialMediaRandomlyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishVehicleOnSocialMediaRandomlyMutation, { data, loading, error }] = usePublishVehicleOnSocialMediaRandomlyMutation({
 *   variables: {
 *      target: // value for 'target'
 *   },
 * });
 */
export function usePublishVehicleOnSocialMediaRandomlyMutation(baseOptions?: Apollo.MutationHookOptions<PublishVehicleOnSocialMediaRandomlyMutation, PublishVehicleOnSocialMediaRandomlyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishVehicleOnSocialMediaRandomlyMutation, PublishVehicleOnSocialMediaRandomlyMutationVariables>(PublishVehicleOnSocialMediaRandomlyDocument, options);
      }
export type PublishVehicleOnSocialMediaRandomlyMutationHookResult = ReturnType<typeof usePublishVehicleOnSocialMediaRandomlyMutation>;
export type PublishVehicleOnSocialMediaRandomlyMutationResult = Apollo.MutationResult<PublishVehicleOnSocialMediaRandomlyMutation>;
export type PublishVehicleOnSocialMediaRandomlyMutationOptions = Apollo.BaseMutationOptions<PublishVehicleOnSocialMediaRandomlyMutation, PublishVehicleOnSocialMediaRandomlyMutationVariables>;
export const RefreshPublishedVehiclesInCacheDocument = gql`
    mutation RefreshPublishedVehiclesInCache {
  refreshPublishedVehiclesInCache {
    output
  }
}
    `;
export type RefreshPublishedVehiclesInCacheMutationFn = Apollo.MutationFunction<RefreshPublishedVehiclesInCacheMutation, RefreshPublishedVehiclesInCacheMutationVariables>;

/**
 * __useRefreshPublishedVehiclesInCacheMutation__
 *
 * To run a mutation, you first call `useRefreshPublishedVehiclesInCacheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshPublishedVehiclesInCacheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshPublishedVehiclesInCacheMutation, { data, loading, error }] = useRefreshPublishedVehiclesInCacheMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshPublishedVehiclesInCacheMutation(baseOptions?: Apollo.MutationHookOptions<RefreshPublishedVehiclesInCacheMutation, RefreshPublishedVehiclesInCacheMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshPublishedVehiclesInCacheMutation, RefreshPublishedVehiclesInCacheMutationVariables>(RefreshPublishedVehiclesInCacheDocument, options);
      }
export type RefreshPublishedVehiclesInCacheMutationHookResult = ReturnType<typeof useRefreshPublishedVehiclesInCacheMutation>;
export type RefreshPublishedVehiclesInCacheMutationResult = Apollo.MutationResult<RefreshPublishedVehiclesInCacheMutation>;
export type RefreshPublishedVehiclesInCacheMutationOptions = Apollo.BaseMutationOptions<RefreshPublishedVehiclesInCacheMutation, RefreshPublishedVehiclesInCacheMutationVariables>;
export const ResetFacebookAccessTokenDocument = gql`
    mutation ResetFacebookAccessToken($accessToken: String!) {
  resetFacebookAccessToken(accessToken: $accessToken)
}
    `;
export type ResetFacebookAccessTokenMutationFn = Apollo.MutationFunction<ResetFacebookAccessTokenMutation, ResetFacebookAccessTokenMutationVariables>;

/**
 * __useResetFacebookAccessTokenMutation__
 *
 * To run a mutation, you first call `useResetFacebookAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetFacebookAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetFacebookAccessTokenMutation, { data, loading, error }] = useResetFacebookAccessTokenMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useResetFacebookAccessTokenMutation(baseOptions?: Apollo.MutationHookOptions<ResetFacebookAccessTokenMutation, ResetFacebookAccessTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetFacebookAccessTokenMutation, ResetFacebookAccessTokenMutationVariables>(ResetFacebookAccessTokenDocument, options);
      }
export type ResetFacebookAccessTokenMutationHookResult = ReturnType<typeof useResetFacebookAccessTokenMutation>;
export type ResetFacebookAccessTokenMutationResult = Apollo.MutationResult<ResetFacebookAccessTokenMutation>;
export type ResetFacebookAccessTokenMutationOptions = Apollo.BaseMutationOptions<ResetFacebookAccessTokenMutation, ResetFacebookAccessTokenMutationVariables>;
export const ResetLinkedinAccessTokenDocument = gql`
    mutation ResetLinkedinAccessToken($code: String!) {
  resetLinkedinAccessToken(code: $code)
}
    `;
export type ResetLinkedinAccessTokenMutationFn = Apollo.MutationFunction<ResetLinkedinAccessTokenMutation, ResetLinkedinAccessTokenMutationVariables>;

/**
 * __useResetLinkedinAccessTokenMutation__
 *
 * To run a mutation, you first call `useResetLinkedinAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetLinkedinAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetLinkedinAccessTokenMutation, { data, loading, error }] = useResetLinkedinAccessTokenMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useResetLinkedinAccessTokenMutation(baseOptions?: Apollo.MutationHookOptions<ResetLinkedinAccessTokenMutation, ResetLinkedinAccessTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetLinkedinAccessTokenMutation, ResetLinkedinAccessTokenMutationVariables>(ResetLinkedinAccessTokenDocument, options);
      }
export type ResetLinkedinAccessTokenMutationHookResult = ReturnType<typeof useResetLinkedinAccessTokenMutation>;
export type ResetLinkedinAccessTokenMutationResult = Apollo.MutationResult<ResetLinkedinAccessTokenMutation>;
export type ResetLinkedinAccessTokenMutationOptions = Apollo.BaseMutationOptions<ResetLinkedinAccessTokenMutation, ResetLinkedinAccessTokenMutationVariables>;
export const RunImportsDocument = gql`
    mutation RunImports($imports: [String!]!) {
  runImports(imports: $imports)
}
    `;
export type RunImportsMutationFn = Apollo.MutationFunction<RunImportsMutation, RunImportsMutationVariables>;

/**
 * __useRunImportsMutation__
 *
 * To run a mutation, you first call `useRunImportsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunImportsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runImportsMutation, { data, loading, error }] = useRunImportsMutation({
 *   variables: {
 *      imports: // value for 'imports'
 *   },
 * });
 */
export function useRunImportsMutation(baseOptions?: Apollo.MutationHookOptions<RunImportsMutation, RunImportsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunImportsMutation, RunImportsMutationVariables>(RunImportsDocument, options);
      }
export type RunImportsMutationHookResult = ReturnType<typeof useRunImportsMutation>;
export type RunImportsMutationResult = Apollo.MutationResult<RunImportsMutation>;
export type RunImportsMutationOptions = Apollo.BaseMutationOptions<RunImportsMutation, RunImportsMutationVariables>;
export const RunWebsiteVehicleMediaGenerationJobDocument = gql`
    mutation RunWebsiteVehicleMediaGenerationJob {
  runWebsiteVehicleMediaGenerationJobUseCase
}
    `;
export type RunWebsiteVehicleMediaGenerationJobMutationFn = Apollo.MutationFunction<RunWebsiteVehicleMediaGenerationJobMutation, RunWebsiteVehicleMediaGenerationJobMutationVariables>;

/**
 * __useRunWebsiteVehicleMediaGenerationJobMutation__
 *
 * To run a mutation, you first call `useRunWebsiteVehicleMediaGenerationJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunWebsiteVehicleMediaGenerationJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runWebsiteVehicleMediaGenerationJobMutation, { data, loading, error }] = useRunWebsiteVehicleMediaGenerationJobMutation({
 *   variables: {
 *   },
 * });
 */
export function useRunWebsiteVehicleMediaGenerationJobMutation(baseOptions?: Apollo.MutationHookOptions<RunWebsiteVehicleMediaGenerationJobMutation, RunWebsiteVehicleMediaGenerationJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunWebsiteVehicleMediaGenerationJobMutation, RunWebsiteVehicleMediaGenerationJobMutationVariables>(RunWebsiteVehicleMediaGenerationJobDocument, options);
      }
export type RunWebsiteVehicleMediaGenerationJobMutationHookResult = ReturnType<typeof useRunWebsiteVehicleMediaGenerationJobMutation>;
export type RunWebsiteVehicleMediaGenerationJobMutationResult = Apollo.MutationResult<RunWebsiteVehicleMediaGenerationJobMutation>;
export type RunWebsiteVehicleMediaGenerationJobMutationOptions = Apollo.BaseMutationOptions<RunWebsiteVehicleMediaGenerationJobMutation, RunWebsiteVehicleMediaGenerationJobMutationVariables>;
export const DeleteAttributeDocument = gql`
    mutation DeleteAttribute($id: Int!) {
  deleteAttribute(id: $id)
}
    `;
export type DeleteAttributeMutationFn = Apollo.MutationFunction<DeleteAttributeMutation, DeleteAttributeMutationVariables>;

/**
 * __useDeleteAttributeMutation__
 *
 * To run a mutation, you first call `useDeleteAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAttributeMutation, { data, loading, error }] = useDeleteAttributeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAttributeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAttributeMutation, DeleteAttributeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAttributeMutation, DeleteAttributeMutationVariables>(DeleteAttributeDocument, options);
      }
export type DeleteAttributeMutationHookResult = ReturnType<typeof useDeleteAttributeMutation>;
export type DeleteAttributeMutationResult = Apollo.MutationResult<DeleteAttributeMutation>;
export type DeleteAttributeMutationOptions = Apollo.BaseMutationOptions<DeleteAttributeMutation, DeleteAttributeMutationVariables>;
export const DeleteAttributeValueDocument = gql`
    mutation DeleteAttributeValue($id: Int!) {
  deleteAttributeValue(id: $id)
}
    `;
export type DeleteAttributeValueMutationFn = Apollo.MutationFunction<DeleteAttributeValueMutation, DeleteAttributeValueMutationVariables>;

/**
 * __useDeleteAttributeValueMutation__
 *
 * To run a mutation, you first call `useDeleteAttributeValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttributeValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAttributeValueMutation, { data, loading, error }] = useDeleteAttributeValueMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAttributeValueMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAttributeValueMutation, DeleteAttributeValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAttributeValueMutation, DeleteAttributeValueMutationVariables>(DeleteAttributeValueDocument, options);
      }
export type DeleteAttributeValueMutationHookResult = ReturnType<typeof useDeleteAttributeValueMutation>;
export type DeleteAttributeValueMutationResult = Apollo.MutationResult<DeleteAttributeValueMutation>;
export type DeleteAttributeValueMutationOptions = Apollo.BaseMutationOptions<DeleteAttributeValueMutation, DeleteAttributeValueMutationVariables>;
export const GetAllAttributeCategoriesDocument = gql`
    query GetAllAttributeCategories {
  allAttributeCategories {
    id
    name
  }
}
    `;

/**
 * __useGetAllAttributeCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAllAttributeCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAttributeCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAttributeCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllAttributeCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllAttributeCategoriesQuery, GetAllAttributeCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAttributeCategoriesQuery, GetAllAttributeCategoriesQueryVariables>(GetAllAttributeCategoriesDocument, options);
      }
export function useGetAllAttributeCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAttributeCategoriesQuery, GetAllAttributeCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAttributeCategoriesQuery, GetAllAttributeCategoriesQueryVariables>(GetAllAttributeCategoriesDocument, options);
        }
export function useGetAllAttributeCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllAttributeCategoriesQuery, GetAllAttributeCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllAttributeCategoriesQuery, GetAllAttributeCategoriesQueryVariables>(GetAllAttributeCategoriesDocument, options);
        }
export type GetAllAttributeCategoriesQueryHookResult = ReturnType<typeof useGetAllAttributeCategoriesQuery>;
export type GetAllAttributeCategoriesLazyQueryHookResult = ReturnType<typeof useGetAllAttributeCategoriesLazyQuery>;
export type GetAllAttributeCategoriesSuspenseQueryHookResult = ReturnType<typeof useGetAllAttributeCategoriesSuspenseQuery>;
export type GetAllAttributeCategoriesQueryResult = Apollo.QueryResult<GetAllAttributeCategoriesQuery, GetAllAttributeCategoriesQueryVariables>;
export const GetAllAttributesDocument = gql`
    query GetAllAttributes {
  allAttributes {
    id
    name
    type
    categoryId
    mandatory
    filterable
    internalCode
    displayOrder
    values {
      id
      value
    }
    config {
      form {
        placeholder
      }
    }
  }
}
    `;

/**
 * __useGetAllAttributesQuery__
 *
 * To run a query within a React component, call `useGetAllAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAttributesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllAttributesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllAttributesQuery, GetAllAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAttributesQuery, GetAllAttributesQueryVariables>(GetAllAttributesDocument, options);
      }
export function useGetAllAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAttributesQuery, GetAllAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAttributesQuery, GetAllAttributesQueryVariables>(GetAllAttributesDocument, options);
        }
export function useGetAllAttributesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllAttributesQuery, GetAllAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllAttributesQuery, GetAllAttributesQueryVariables>(GetAllAttributesDocument, options);
        }
export type GetAllAttributesQueryHookResult = ReturnType<typeof useGetAllAttributesQuery>;
export type GetAllAttributesLazyQueryHookResult = ReturnType<typeof useGetAllAttributesLazyQuery>;
export type GetAllAttributesSuspenseQueryHookResult = ReturnType<typeof useGetAllAttributesSuspenseQuery>;
export type GetAllAttributesQueryResult = Apollo.QueryResult<GetAllAttributesQuery, GetAllAttributesQueryVariables>;
export const GetAttributeDocument = gql`
    query GetAttribute($id: Int!) {
  attribute(id: $id) {
    id
    name
    type
    categoryId
    mandatory
    filterable
    values {
      id
      value
    }
  }
}
    `;

/**
 * __useGetAttributeQuery__
 *
 * To run a query within a React component, call `useGetAttributeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttributeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttributeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAttributeQuery(baseOptions: Apollo.QueryHookOptions<GetAttributeQuery, GetAttributeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAttributeQuery, GetAttributeQueryVariables>(GetAttributeDocument, options);
      }
export function useGetAttributeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAttributeQuery, GetAttributeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAttributeQuery, GetAttributeQueryVariables>(GetAttributeDocument, options);
        }
export function useGetAttributeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAttributeQuery, GetAttributeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAttributeQuery, GetAttributeQueryVariables>(GetAttributeDocument, options);
        }
export type GetAttributeQueryHookResult = ReturnType<typeof useGetAttributeQuery>;
export type GetAttributeLazyQueryHookResult = ReturnType<typeof useGetAttributeLazyQuery>;
export type GetAttributeSuspenseQueryHookResult = ReturnType<typeof useGetAttributeSuspenseQuery>;
export type GetAttributeQueryResult = Apollo.QueryResult<GetAttributeQuery, GetAttributeQueryVariables>;
export const SaveAttributeDocument = gql`
    mutation SaveAttribute($dto: SaveAttributeDto!) {
  saveAttribute(dto: $dto) {
    id
    categoryId
    name
    type
    mandatory
    filterable
    values {
      id
      value
    }
  }
}
    `;
export type SaveAttributeMutationFn = Apollo.MutationFunction<SaveAttributeMutation, SaveAttributeMutationVariables>;

/**
 * __useSaveAttributeMutation__
 *
 * To run a mutation, you first call `useSaveAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAttributeMutation, { data, loading, error }] = useSaveAttributeMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useSaveAttributeMutation(baseOptions?: Apollo.MutationHookOptions<SaveAttributeMutation, SaveAttributeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveAttributeMutation, SaveAttributeMutationVariables>(SaveAttributeDocument, options);
      }
export type SaveAttributeMutationHookResult = ReturnType<typeof useSaveAttributeMutation>;
export type SaveAttributeMutationResult = Apollo.MutationResult<SaveAttributeMutation>;
export type SaveAttributeMutationOptions = Apollo.BaseMutationOptions<SaveAttributeMutation, SaveAttributeMutationVariables>;
export const SaveAttributeCategoryDocument = gql`
    mutation SaveAttributeCategory($dto: SaveAttributeCategoryDto!) {
  saveAttributeCategory(dto: $dto) {
    id
    name
  }
}
    `;
export type SaveAttributeCategoryMutationFn = Apollo.MutationFunction<SaveAttributeCategoryMutation, SaveAttributeCategoryMutationVariables>;

/**
 * __useSaveAttributeCategoryMutation__
 *
 * To run a mutation, you first call `useSaveAttributeCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAttributeCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAttributeCategoryMutation, { data, loading, error }] = useSaveAttributeCategoryMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useSaveAttributeCategoryMutation(baseOptions?: Apollo.MutationHookOptions<SaveAttributeCategoryMutation, SaveAttributeCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveAttributeCategoryMutation, SaveAttributeCategoryMutationVariables>(SaveAttributeCategoryDocument, options);
      }
export type SaveAttributeCategoryMutationHookResult = ReturnType<typeof useSaveAttributeCategoryMutation>;
export type SaveAttributeCategoryMutationResult = Apollo.MutationResult<SaveAttributeCategoryMutation>;
export type SaveAttributeCategoryMutationOptions = Apollo.BaseMutationOptions<SaveAttributeCategoryMutation, SaveAttributeCategoryMutationVariables>;
export const LoginDocument = gql`
    query login($email: String!, $password: String!) {
  login(dto: {email: $email, password: $password})
}
    `;

/**
 * __useLoginQuery__
 *
 * To run a query within a React component, call `useLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginQuery({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginQuery(baseOptions: Apollo.QueryHookOptions<LoginQuery, LoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
      }
export function useLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoginQuery, LoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
        }
export function useLoginSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LoginQuery, LoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
        }
export type LoginQueryHookResult = ReturnType<typeof useLoginQuery>;
export type LoginLazyQueryHookResult = ReturnType<typeof useLoginLazyQuery>;
export type LoginSuspenseQueryHookResult = ReturnType<typeof useLoginSuspenseQuery>;
export type LoginQueryResult = Apollo.QueryResult<LoginQuery, LoginQueryVariables>;
export const LoginUnderUserDocument = gql`
    query LoginUnderUser($id: Int!) {
  loginUnderUser(id: $id)
}
    `;

/**
 * __useLoginUnderUserQuery__
 *
 * To run a query within a React component, call `useLoginUnderUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginUnderUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginUnderUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoginUnderUserQuery(baseOptions: Apollo.QueryHookOptions<LoginUnderUserQuery, LoginUnderUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoginUnderUserQuery, LoginUnderUserQueryVariables>(LoginUnderUserDocument, options);
      }
export function useLoginUnderUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoginUnderUserQuery, LoginUnderUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoginUnderUserQuery, LoginUnderUserQueryVariables>(LoginUnderUserDocument, options);
        }
export function useLoginUnderUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LoginUnderUserQuery, LoginUnderUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LoginUnderUserQuery, LoginUnderUserQueryVariables>(LoginUnderUserDocument, options);
        }
export type LoginUnderUserQueryHookResult = ReturnType<typeof useLoginUnderUserQuery>;
export type LoginUnderUserLazyQueryHookResult = ReturnType<typeof useLoginUnderUserLazyQuery>;
export type LoginUnderUserSuspenseQueryHookResult = ReturnType<typeof useLoginUnderUserSuspenseQuery>;
export type LoginUnderUserQueryResult = Apollo.QueryResult<LoginUnderUserQuery, LoginUnderUserQueryVariables>;
export const ValidateTokenDocument = gql`
    query validateToken($token: String!) {
  validateToken(dto: {token: $token})
}
    `;

/**
 * __useValidateTokenQuery__
 *
 * To run a query within a React component, call `useValidateTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useValidateTokenQuery(baseOptions: Apollo.QueryHookOptions<ValidateTokenQuery, ValidateTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateTokenQuery, ValidateTokenQueryVariables>(ValidateTokenDocument, options);
      }
export function useValidateTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateTokenQuery, ValidateTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateTokenQuery, ValidateTokenQueryVariables>(ValidateTokenDocument, options);
        }
export function useValidateTokenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ValidateTokenQuery, ValidateTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ValidateTokenQuery, ValidateTokenQueryVariables>(ValidateTokenDocument, options);
        }
export type ValidateTokenQueryHookResult = ReturnType<typeof useValidateTokenQuery>;
export type ValidateTokenLazyQueryHookResult = ReturnType<typeof useValidateTokenLazyQuery>;
export type ValidateTokenSuspenseQueryHookResult = ReturnType<typeof useValidateTokenSuspenseQuery>;
export type ValidateTokenQueryResult = Apollo.QueryResult<ValidateTokenQuery, ValidateTokenQueryVariables>;
export const GetAllBrandsDocument = gql`
    query GetAllBrands {
  allBrands {
    id
    name
    models {
      id
      name
      brandId
      dealerId
    }
  }
}
    `;

/**
 * __useGetAllBrandsQuery__
 *
 * To run a query within a React component, call `useGetAllBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBrandsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllBrandsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllBrandsQuery, GetAllBrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllBrandsQuery, GetAllBrandsQueryVariables>(GetAllBrandsDocument, options);
      }
export function useGetAllBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllBrandsQuery, GetAllBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllBrandsQuery, GetAllBrandsQueryVariables>(GetAllBrandsDocument, options);
        }
export function useGetAllBrandsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllBrandsQuery, GetAllBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllBrandsQuery, GetAllBrandsQueryVariables>(GetAllBrandsDocument, options);
        }
export type GetAllBrandsQueryHookResult = ReturnType<typeof useGetAllBrandsQuery>;
export type GetAllBrandsLazyQueryHookResult = ReturnType<typeof useGetAllBrandsLazyQuery>;
export type GetAllBrandsSuspenseQueryHookResult = ReturnType<typeof useGetAllBrandsSuspenseQuery>;
export type GetAllBrandsQueryResult = Apollo.QueryResult<GetAllBrandsQuery, GetAllBrandsQueryVariables>;
export const SaveBrandDocument = gql`
    mutation SaveBrand($dto: SaveBrandDto!) {
  saveBrand(dto: $dto) {
    id
    name
    models {
      id
      name
      brandId
    }
  }
}
    `;
export type SaveBrandMutationFn = Apollo.MutationFunction<SaveBrandMutation, SaveBrandMutationVariables>;

/**
 * __useSaveBrandMutation__
 *
 * To run a mutation, you first call `useSaveBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveBrandMutation, { data, loading, error }] = useSaveBrandMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useSaveBrandMutation(baseOptions?: Apollo.MutationHookOptions<SaveBrandMutation, SaveBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveBrandMutation, SaveBrandMutationVariables>(SaveBrandDocument, options);
      }
export type SaveBrandMutationHookResult = ReturnType<typeof useSaveBrandMutation>;
export type SaveBrandMutationResult = Apollo.MutationResult<SaveBrandMutation>;
export type SaveBrandMutationOptions = Apollo.BaseMutationOptions<SaveBrandMutation, SaveBrandMutationVariables>;
export const DeleteDamageDocument = gql`
    mutation DeleteDamage($id: Int!) {
  deleteDamage(id: $id)
}
    `;
export type DeleteDamageMutationFn = Apollo.MutationFunction<DeleteDamageMutation, DeleteDamageMutationVariables>;

/**
 * __useDeleteDamageMutation__
 *
 * To run a mutation, you first call `useDeleteDamageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDamageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDamageMutation, { data, loading, error }] = useDeleteDamageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDamageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDamageMutation, DeleteDamageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDamageMutation, DeleteDamageMutationVariables>(DeleteDamageDocument, options);
      }
export type DeleteDamageMutationHookResult = ReturnType<typeof useDeleteDamageMutation>;
export type DeleteDamageMutationResult = Apollo.MutationResult<DeleteDamageMutation>;
export type DeleteDamageMutationOptions = Apollo.BaseMutationOptions<DeleteDamageMutation, DeleteDamageMutationVariables>;
export const DeleteDamageLocationDocument = gql`
    mutation DeleteDamageLocation($id: Int!) {
  deleteDamageLocation(id: $id)
}
    `;
export type DeleteDamageLocationMutationFn = Apollo.MutationFunction<DeleteDamageLocationMutation, DeleteDamageLocationMutationVariables>;

/**
 * __useDeleteDamageLocationMutation__
 *
 * To run a mutation, you first call `useDeleteDamageLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDamageLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDamageLocationMutation, { data, loading, error }] = useDeleteDamageLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDamageLocationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDamageLocationMutation, DeleteDamageLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDamageLocationMutation, DeleteDamageLocationMutationVariables>(DeleteDamageLocationDocument, options);
      }
export type DeleteDamageLocationMutationHookResult = ReturnType<typeof useDeleteDamageLocationMutation>;
export type DeleteDamageLocationMutationResult = Apollo.MutationResult<DeleteDamageLocationMutation>;
export type DeleteDamageLocationMutationOptions = Apollo.BaseMutationOptions<DeleteDamageLocationMutation, DeleteDamageLocationMutationVariables>;
export const GetAllDamageLocationsDocument = gql`
    query GetAllDamageLocations {
  allDamageLocations {
    id
    name
  }
}
    `;

/**
 * __useGetAllDamageLocationsQuery__
 *
 * To run a query within a React component, call `useGetAllDamageLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDamageLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDamageLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllDamageLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllDamageLocationsQuery, GetAllDamageLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDamageLocationsQuery, GetAllDamageLocationsQueryVariables>(GetAllDamageLocationsDocument, options);
      }
export function useGetAllDamageLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDamageLocationsQuery, GetAllDamageLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDamageLocationsQuery, GetAllDamageLocationsQueryVariables>(GetAllDamageLocationsDocument, options);
        }
export function useGetAllDamageLocationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllDamageLocationsQuery, GetAllDamageLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllDamageLocationsQuery, GetAllDamageLocationsQueryVariables>(GetAllDamageLocationsDocument, options);
        }
export type GetAllDamageLocationsQueryHookResult = ReturnType<typeof useGetAllDamageLocationsQuery>;
export type GetAllDamageLocationsLazyQueryHookResult = ReturnType<typeof useGetAllDamageLocationsLazyQuery>;
export type GetAllDamageLocationsSuspenseQueryHookResult = ReturnType<typeof useGetAllDamageLocationsSuspenseQuery>;
export type GetAllDamageLocationsQueryResult = Apollo.QueryResult<GetAllDamageLocationsQuery, GetAllDamageLocationsQueryVariables>;
export const GetAllDamagesDocument = gql`
    query GetAllDamages {
  allDamages {
    id
    name
    dealerId
  }
}
    `;

/**
 * __useGetAllDamagesQuery__
 *
 * To run a query within a React component, call `useGetAllDamagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDamagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDamagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllDamagesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllDamagesQuery, GetAllDamagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDamagesQuery, GetAllDamagesQueryVariables>(GetAllDamagesDocument, options);
      }
export function useGetAllDamagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDamagesQuery, GetAllDamagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDamagesQuery, GetAllDamagesQueryVariables>(GetAllDamagesDocument, options);
        }
export function useGetAllDamagesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllDamagesQuery, GetAllDamagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllDamagesQuery, GetAllDamagesQueryVariables>(GetAllDamagesDocument, options);
        }
export type GetAllDamagesQueryHookResult = ReturnType<typeof useGetAllDamagesQuery>;
export type GetAllDamagesLazyQueryHookResult = ReturnType<typeof useGetAllDamagesLazyQuery>;
export type GetAllDamagesSuspenseQueryHookResult = ReturnType<typeof useGetAllDamagesSuspenseQuery>;
export type GetAllDamagesQueryResult = Apollo.QueryResult<GetAllDamagesQuery, GetAllDamagesQueryVariables>;
export const SaveDamageDocument = gql`
    mutation SaveDamage($dto: SaveDamageDto!) {
  saveDamage(dto: $dto) {
    id
    name
  }
}
    `;
export type SaveDamageMutationFn = Apollo.MutationFunction<SaveDamageMutation, SaveDamageMutationVariables>;

/**
 * __useSaveDamageMutation__
 *
 * To run a mutation, you first call `useSaveDamageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDamageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDamageMutation, { data, loading, error }] = useSaveDamageMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useSaveDamageMutation(baseOptions?: Apollo.MutationHookOptions<SaveDamageMutation, SaveDamageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveDamageMutation, SaveDamageMutationVariables>(SaveDamageDocument, options);
      }
export type SaveDamageMutationHookResult = ReturnType<typeof useSaveDamageMutation>;
export type SaveDamageMutationResult = Apollo.MutationResult<SaveDamageMutation>;
export type SaveDamageMutationOptions = Apollo.BaseMutationOptions<SaveDamageMutation, SaveDamageMutationVariables>;
export const SaveDamageLocationDocument = gql`
    mutation SaveDamageLocation($dto: SaveDamageLocationDto!) {
  saveDamageLocation(dto: $dto) {
    id
    name
  }
}
    `;
export type SaveDamageLocationMutationFn = Apollo.MutationFunction<SaveDamageLocationMutation, SaveDamageLocationMutationVariables>;

/**
 * __useSaveDamageLocationMutation__
 *
 * To run a mutation, you first call `useSaveDamageLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDamageLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDamageLocationMutation, { data, loading, error }] = useSaveDamageLocationMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useSaveDamageLocationMutation(baseOptions?: Apollo.MutationHookOptions<SaveDamageLocationMutation, SaveDamageLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveDamageLocationMutation, SaveDamageLocationMutationVariables>(SaveDamageLocationDocument, options);
      }
export type SaveDamageLocationMutationHookResult = ReturnType<typeof useSaveDamageLocationMutation>;
export type SaveDamageLocationMutationResult = Apollo.MutationResult<SaveDamageLocationMutation>;
export type SaveDamageLocationMutationOptions = Apollo.BaseMutationOptions<SaveDamageLocationMutation, SaveDamageLocationMutationVariables>;
export const GetMyDealerDocument = gql`
    query GetMyDealer {
  myDealer {
    id
    email
    phoneNumber
    vatNumber
    addresses {
      id
      street
      number
      city
      zip
      country
      type
    }
    contacts {
      id
      email
      firstName
      lastName
      phoneNumber
    }
  }
}
    `;

/**
 * __useGetMyDealerQuery__
 *
 * To run a query within a React component, call `useGetMyDealerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyDealerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyDealerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyDealerQuery(baseOptions?: Apollo.QueryHookOptions<GetMyDealerQuery, GetMyDealerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyDealerQuery, GetMyDealerQueryVariables>(GetMyDealerDocument, options);
      }
export function useGetMyDealerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyDealerQuery, GetMyDealerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyDealerQuery, GetMyDealerQueryVariables>(GetMyDealerDocument, options);
        }
export function useGetMyDealerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyDealerQuery, GetMyDealerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyDealerQuery, GetMyDealerQueryVariables>(GetMyDealerDocument, options);
        }
export type GetMyDealerQueryHookResult = ReturnType<typeof useGetMyDealerQuery>;
export type GetMyDealerLazyQueryHookResult = ReturnType<typeof useGetMyDealerLazyQuery>;
export type GetMyDealerSuspenseQueryHookResult = ReturnType<typeof useGetMyDealerSuspenseQuery>;
export type GetMyDealerQueryResult = Apollo.QueryResult<GetMyDealerQuery, GetMyDealerQueryVariables>;
export const GetOnePublicDealerDocument = gql`
    query GetOnePublicDealer($dealerId: Int!) {
  publicDealer(dealerId: $dealerId) {
    name
    numberOfPublishedVehicles
  }
}
    `;

/**
 * __useGetOnePublicDealerQuery__
 *
 * To run a query within a React component, call `useGetOnePublicDealerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnePublicDealerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnePublicDealerQuery({
 *   variables: {
 *      dealerId: // value for 'dealerId'
 *   },
 * });
 */
export function useGetOnePublicDealerQuery(baseOptions: Apollo.QueryHookOptions<GetOnePublicDealerQuery, GetOnePublicDealerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOnePublicDealerQuery, GetOnePublicDealerQueryVariables>(GetOnePublicDealerDocument, options);
      }
export function useGetOnePublicDealerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOnePublicDealerQuery, GetOnePublicDealerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOnePublicDealerQuery, GetOnePublicDealerQueryVariables>(GetOnePublicDealerDocument, options);
        }
export function useGetOnePublicDealerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetOnePublicDealerQuery, GetOnePublicDealerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOnePublicDealerQuery, GetOnePublicDealerQueryVariables>(GetOnePublicDealerDocument, options);
        }
export type GetOnePublicDealerQueryHookResult = ReturnType<typeof useGetOnePublicDealerQuery>;
export type GetOnePublicDealerLazyQueryHookResult = ReturnType<typeof useGetOnePublicDealerLazyQuery>;
export type GetOnePublicDealerSuspenseQueryHookResult = ReturnType<typeof useGetOnePublicDealerSuspenseQuery>;
export type GetOnePublicDealerQueryResult = Apollo.QueryResult<GetOnePublicDealerQuery, GetOnePublicDealerQueryVariables>;
export const SaveDealerDocument = gql`
    mutation SaveDealer($dto: SaveDealerDto!) {
  saveDealer(dto: $dto) {
    id
    name
    email
    phoneNumber
    vatNumber
    iban
  }
}
    `;
export type SaveDealerMutationFn = Apollo.MutationFunction<SaveDealerMutation, SaveDealerMutationVariables>;

/**
 * __useSaveDealerMutation__
 *
 * To run a mutation, you first call `useSaveDealerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDealerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDealerMutation, { data, loading, error }] = useSaveDealerMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useSaveDealerMutation(baseOptions?: Apollo.MutationHookOptions<SaveDealerMutation, SaveDealerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveDealerMutation, SaveDealerMutationVariables>(SaveDealerDocument, options);
      }
export type SaveDealerMutationHookResult = ReturnType<typeof useSaveDealerMutation>;
export type SaveDealerMutationResult = Apollo.MutationResult<SaveDealerMutation>;
export type SaveDealerMutationOptions = Apollo.BaseMutationOptions<SaveDealerMutation, SaveDealerMutationVariables>;
export const SaveDealerAddressDocument = gql`
    mutation SaveDealerAddress($dto: SaveDealerAddressDto!) {
  saveDealerAddress(dto: $dto) {
    id
  }
}
    `;
export type SaveDealerAddressMutationFn = Apollo.MutationFunction<SaveDealerAddressMutation, SaveDealerAddressMutationVariables>;

/**
 * __useSaveDealerAddressMutation__
 *
 * To run a mutation, you first call `useSaveDealerAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDealerAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDealerAddressMutation, { data, loading, error }] = useSaveDealerAddressMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useSaveDealerAddressMutation(baseOptions?: Apollo.MutationHookOptions<SaveDealerAddressMutation, SaveDealerAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveDealerAddressMutation, SaveDealerAddressMutationVariables>(SaveDealerAddressDocument, options);
      }
export type SaveDealerAddressMutationHookResult = ReturnType<typeof useSaveDealerAddressMutation>;
export type SaveDealerAddressMutationResult = Apollo.MutationResult<SaveDealerAddressMutation>;
export type SaveDealerAddressMutationOptions = Apollo.BaseMutationOptions<SaveDealerAddressMutation, SaveDealerAddressMutationVariables>;
export const SaveDealerContactDocument = gql`
    mutation SaveDealerContact($dto: SaveDealerContactDto!) {
  saveDealerContact(dto: $dto) {
    id
  }
}
    `;
export type SaveDealerContactMutationFn = Apollo.MutationFunction<SaveDealerContactMutation, SaveDealerContactMutationVariables>;

/**
 * __useSaveDealerContactMutation__
 *
 * To run a mutation, you first call `useSaveDealerContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDealerContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDealerContactMutation, { data, loading, error }] = useSaveDealerContactMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useSaveDealerContactMutation(baseOptions?: Apollo.MutationHookOptions<SaveDealerContactMutation, SaveDealerContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveDealerContactMutation, SaveDealerContactMutationVariables>(SaveDealerContactDocument, options);
      }
export type SaveDealerContactMutationHookResult = ReturnType<typeof useSaveDealerContactMutation>;
export type SaveDealerContactMutationResult = Apollo.MutationResult<SaveDealerContactMutation>;
export type SaveDealerContactMutationOptions = Apollo.BaseMutationOptions<SaveDealerContactMutation, SaveDealerContactMutationVariables>;
export const SaveDealerLogoDocument = gql`
    mutation SaveDealerLogo($dto: SaveDealerLogoDto!) {
  saveDealerLogo(dto: $dto) {
    id
  }
}
    `;
export type SaveDealerLogoMutationFn = Apollo.MutationFunction<SaveDealerLogoMutation, SaveDealerLogoMutationVariables>;

/**
 * __useSaveDealerLogoMutation__
 *
 * To run a mutation, you first call `useSaveDealerLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDealerLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDealerLogoMutation, { data, loading, error }] = useSaveDealerLogoMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useSaveDealerLogoMutation(baseOptions?: Apollo.MutationHookOptions<SaveDealerLogoMutation, SaveDealerLogoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveDealerLogoMutation, SaveDealerLogoMutationVariables>(SaveDealerLogoDocument, options);
      }
export type SaveDealerLogoMutationHookResult = ReturnType<typeof useSaveDealerLogoMutation>;
export type SaveDealerLogoMutationResult = Apollo.MutationResult<SaveDealerLogoMutation>;
export type SaveDealerLogoMutationOptions = Apollo.BaseMutationOptions<SaveDealerLogoMutation, SaveDealerLogoMutationVariables>;
export const DeleteEquipmentDocument = gql`
    mutation DeleteEquipment($id: Int!) {
  deleteEquipment(id: $id)
}
    `;
export type DeleteEquipmentMutationFn = Apollo.MutationFunction<DeleteEquipmentMutation, DeleteEquipmentMutationVariables>;

/**
 * __useDeleteEquipmentMutation__
 *
 * To run a mutation, you first call `useDeleteEquipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEquipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEquipmentMutation, { data, loading, error }] = useDeleteEquipmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEquipmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEquipmentMutation, DeleteEquipmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEquipmentMutation, DeleteEquipmentMutationVariables>(DeleteEquipmentDocument, options);
      }
export type DeleteEquipmentMutationHookResult = ReturnType<typeof useDeleteEquipmentMutation>;
export type DeleteEquipmentMutationResult = Apollo.MutationResult<DeleteEquipmentMutation>;
export type DeleteEquipmentMutationOptions = Apollo.BaseMutationOptions<DeleteEquipmentMutation, DeleteEquipmentMutationVariables>;
export const GetAllEquipmentsDocument = gql`
    query GetAllEquipments {
  allEquipments {
    id
    name
    dealerId
  }
}
    `;

/**
 * __useGetAllEquipmentsQuery__
 *
 * To run a query within a React component, call `useGetAllEquipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEquipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEquipmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllEquipmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllEquipmentsQuery, GetAllEquipmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllEquipmentsQuery, GetAllEquipmentsQueryVariables>(GetAllEquipmentsDocument, options);
      }
export function useGetAllEquipmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllEquipmentsQuery, GetAllEquipmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllEquipmentsQuery, GetAllEquipmentsQueryVariables>(GetAllEquipmentsDocument, options);
        }
export function useGetAllEquipmentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllEquipmentsQuery, GetAllEquipmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllEquipmentsQuery, GetAllEquipmentsQueryVariables>(GetAllEquipmentsDocument, options);
        }
export type GetAllEquipmentsQueryHookResult = ReturnType<typeof useGetAllEquipmentsQuery>;
export type GetAllEquipmentsLazyQueryHookResult = ReturnType<typeof useGetAllEquipmentsLazyQuery>;
export type GetAllEquipmentsSuspenseQueryHookResult = ReturnType<typeof useGetAllEquipmentsSuspenseQuery>;
export type GetAllEquipmentsQueryResult = Apollo.QueryResult<GetAllEquipmentsQuery, GetAllEquipmentsQueryVariables>;
export const SaveEquipmentDocument = gql`
    mutation SaveEquipment($dto: SaveEquipmentDto!) {
  saveEquipment(dto: $dto) {
    id
    name
  }
}
    `;
export type SaveEquipmentMutationFn = Apollo.MutationFunction<SaveEquipmentMutation, SaveEquipmentMutationVariables>;

/**
 * __useSaveEquipmentMutation__
 *
 * To run a mutation, you first call `useSaveEquipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEquipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEquipmentMutation, { data, loading, error }] = useSaveEquipmentMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useSaveEquipmentMutation(baseOptions?: Apollo.MutationHookOptions<SaveEquipmentMutation, SaveEquipmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveEquipmentMutation, SaveEquipmentMutationVariables>(SaveEquipmentDocument, options);
      }
export type SaveEquipmentMutationHookResult = ReturnType<typeof useSaveEquipmentMutation>;
export type SaveEquipmentMutationResult = Apollo.MutationResult<SaveEquipmentMutation>;
export type SaveEquipmentMutationOptions = Apollo.BaseMutationOptions<SaveEquipmentMutation, SaveEquipmentMutationVariables>;
export const SaveModelDocument = gql`
    mutation SaveModel($dto: SaveModelDto!) {
  saveModel(dto: $dto) {
    id
    name
    brandId
  }
}
    `;
export type SaveModelMutationFn = Apollo.MutationFunction<SaveModelMutation, SaveModelMutationVariables>;

/**
 * __useSaveModelMutation__
 *
 * To run a mutation, you first call `useSaveModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveModelMutation, { data, loading, error }] = useSaveModelMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useSaveModelMutation(baseOptions?: Apollo.MutationHookOptions<SaveModelMutation, SaveModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveModelMutation, SaveModelMutationVariables>(SaveModelDocument, options);
      }
export type SaveModelMutationHookResult = ReturnType<typeof useSaveModelMutation>;
export type SaveModelMutationResult = Apollo.MutationResult<SaveModelMutation>;
export type SaveModelMutationOptions = Apollo.BaseMutationOptions<SaveModelMutation, SaveModelMutationVariables>;
export const MarkInAppNotificationsAsReadDocument = gql`
    mutation MarkInAppNotificationsAsRead {
  markInAppNotificationsAsRead
}
    `;
export type MarkInAppNotificationsAsReadMutationFn = Apollo.MutationFunction<MarkInAppNotificationsAsReadMutation, MarkInAppNotificationsAsReadMutationVariables>;

/**
 * __useMarkInAppNotificationsAsReadMutation__
 *
 * To run a mutation, you first call `useMarkInAppNotificationsAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkInAppNotificationsAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markInAppNotificationsAsReadMutation, { data, loading, error }] = useMarkInAppNotificationsAsReadMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkInAppNotificationsAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkInAppNotificationsAsReadMutation, MarkInAppNotificationsAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkInAppNotificationsAsReadMutation, MarkInAppNotificationsAsReadMutationVariables>(MarkInAppNotificationsAsReadDocument, options);
      }
export type MarkInAppNotificationsAsReadMutationHookResult = ReturnType<typeof useMarkInAppNotificationsAsReadMutation>;
export type MarkInAppNotificationsAsReadMutationResult = Apollo.MutationResult<MarkInAppNotificationsAsReadMutation>;
export type MarkInAppNotificationsAsReadMutationOptions = Apollo.BaseMutationOptions<MarkInAppNotificationsAsReadMutation, MarkInAppNotificationsAsReadMutationVariables>;
export const AcceptOfferDocument = gql`
    mutation AcceptOffer($offerId: Int!) {
  acceptOffer(offerId: $offerId) {
    id
  }
}
    `;
export type AcceptOfferMutationFn = Apollo.MutationFunction<AcceptOfferMutation, AcceptOfferMutationVariables>;

/**
 * __useAcceptOfferMutation__
 *
 * To run a mutation, you first call `useAcceptOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptOfferMutation, { data, loading, error }] = useAcceptOfferMutation({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useAcceptOfferMutation(baseOptions?: Apollo.MutationHookOptions<AcceptOfferMutation, AcceptOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptOfferMutation, AcceptOfferMutationVariables>(AcceptOfferDocument, options);
      }
export type AcceptOfferMutationHookResult = ReturnType<typeof useAcceptOfferMutation>;
export type AcceptOfferMutationResult = Apollo.MutationResult<AcceptOfferMutation>;
export type AcceptOfferMutationOptions = Apollo.BaseMutationOptions<AcceptOfferMutation, AcceptOfferMutationVariables>;
export const CancelOfferDocument = gql`
    mutation CancelOffer($offerId: Int!) {
  cancelOffer(offerId: $offerId) {
    id
  }
}
    `;
export type CancelOfferMutationFn = Apollo.MutationFunction<CancelOfferMutation, CancelOfferMutationVariables>;

/**
 * __useCancelOfferMutation__
 *
 * To run a mutation, you first call `useCancelOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOfferMutation, { data, loading, error }] = useCancelOfferMutation({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useCancelOfferMutation(baseOptions?: Apollo.MutationHookOptions<CancelOfferMutation, CancelOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelOfferMutation, CancelOfferMutationVariables>(CancelOfferDocument, options);
      }
export type CancelOfferMutationHookResult = ReturnType<typeof useCancelOfferMutation>;
export type CancelOfferMutationResult = Apollo.MutationResult<CancelOfferMutation>;
export type CancelOfferMutationOptions = Apollo.BaseMutationOptions<CancelOfferMutation, CancelOfferMutationVariables>;
export const DeclineOfferDocument = gql`
    mutation DeclineOffer($dto: DeclineOfferDto!) {
  declineOffer(dto: $dto) {
    id
  }
}
    `;
export type DeclineOfferMutationFn = Apollo.MutationFunction<DeclineOfferMutation, DeclineOfferMutationVariables>;

/**
 * __useDeclineOfferMutation__
 *
 * To run a mutation, you first call `useDeclineOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineOfferMutation, { data, loading, error }] = useDeclineOfferMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useDeclineOfferMutation(baseOptions?: Apollo.MutationHookOptions<DeclineOfferMutation, DeclineOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeclineOfferMutation, DeclineOfferMutationVariables>(DeclineOfferDocument, options);
      }
export type DeclineOfferMutationHookResult = ReturnType<typeof useDeclineOfferMutation>;
export type DeclineOfferMutationResult = Apollo.MutationResult<DeclineOfferMutation>;
export type DeclineOfferMutationOptions = Apollo.BaseMutationOptions<DeclineOfferMutation, DeclineOfferMutationVariables>;
export const GetAllOffersOfMyVehicleDocument = gql`
    query GetAllOffersOfMyVehicle($vehicleId: Int!) {
  offersOfMyVehicle(vehicleId: $vehicleId) {
    id
    type
    amount
    userId
    statuses {
      id
      type
      reason
      createdAt
    }
    user {
      id
      lastName
      firstName
      dealer {
        name
        phoneNumber
      }
      averageRatingScore
    }
  }
}
    `;

/**
 * __useGetAllOffersOfMyVehicleQuery__
 *
 * To run a query within a React component, call `useGetAllOffersOfMyVehicleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOffersOfMyVehicleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOffersOfMyVehicleQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useGetAllOffersOfMyVehicleQuery(baseOptions: Apollo.QueryHookOptions<GetAllOffersOfMyVehicleQuery, GetAllOffersOfMyVehicleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllOffersOfMyVehicleQuery, GetAllOffersOfMyVehicleQueryVariables>(GetAllOffersOfMyVehicleDocument, options);
      }
export function useGetAllOffersOfMyVehicleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllOffersOfMyVehicleQuery, GetAllOffersOfMyVehicleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllOffersOfMyVehicleQuery, GetAllOffersOfMyVehicleQueryVariables>(GetAllOffersOfMyVehicleDocument, options);
        }
export function useGetAllOffersOfMyVehicleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllOffersOfMyVehicleQuery, GetAllOffersOfMyVehicleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllOffersOfMyVehicleQuery, GetAllOffersOfMyVehicleQueryVariables>(GetAllOffersOfMyVehicleDocument, options);
        }
export type GetAllOffersOfMyVehicleQueryHookResult = ReturnType<typeof useGetAllOffersOfMyVehicleQuery>;
export type GetAllOffersOfMyVehicleLazyQueryHookResult = ReturnType<typeof useGetAllOffersOfMyVehicleLazyQuery>;
export type GetAllOffersOfMyVehicleSuspenseQueryHookResult = ReturnType<typeof useGetAllOffersOfMyVehicleSuspenseQuery>;
export type GetAllOffersOfMyVehicleQueryResult = Apollo.QueryResult<GetAllOffersOfMyVehicleQuery, GetAllOffersOfMyVehicleQueryVariables>;
export const GetMyOffersForVehiclesDocument = gql`
    query GetMyOffersForVehicles($vehicleIds: [Int!]!) {
  myOffersForVehicles(vehicleIds: $vehicleIds) {
    id
    vehicleId
    type
    amount
    statuses {
      id
      type
      reason
    }
  }
}
    `;

/**
 * __useGetMyOffersForVehiclesQuery__
 *
 * To run a query within a React component, call `useGetMyOffersForVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyOffersForVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyOffersForVehiclesQuery({
 *   variables: {
 *      vehicleIds: // value for 'vehicleIds'
 *   },
 * });
 */
export function useGetMyOffersForVehiclesQuery(baseOptions: Apollo.QueryHookOptions<GetMyOffersForVehiclesQuery, GetMyOffersForVehiclesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyOffersForVehiclesQuery, GetMyOffersForVehiclesQueryVariables>(GetMyOffersForVehiclesDocument, options);
      }
export function useGetMyOffersForVehiclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyOffersForVehiclesQuery, GetMyOffersForVehiclesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyOffersForVehiclesQuery, GetMyOffersForVehiclesQueryVariables>(GetMyOffersForVehiclesDocument, options);
        }
export function useGetMyOffersForVehiclesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyOffersForVehiclesQuery, GetMyOffersForVehiclesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyOffersForVehiclesQuery, GetMyOffersForVehiclesQueryVariables>(GetMyOffersForVehiclesDocument, options);
        }
export type GetMyOffersForVehiclesQueryHookResult = ReturnType<typeof useGetMyOffersForVehiclesQuery>;
export type GetMyOffersForVehiclesLazyQueryHookResult = ReturnType<typeof useGetMyOffersForVehiclesLazyQuery>;
export type GetMyOffersForVehiclesSuspenseQueryHookResult = ReturnType<typeof useGetMyOffersForVehiclesSuspenseQuery>;
export type GetMyOffersForVehiclesQueryResult = Apollo.QueryResult<GetMyOffersForVehiclesQuery, GetMyOffersForVehiclesQueryVariables>;
export const SaveOfferDocument = gql`
    mutation SaveOffer($dto: SaveOfferDto!) {
  saveOffer(dto: $dto) {
    id
  }
}
    `;
export type SaveOfferMutationFn = Apollo.MutationFunction<SaveOfferMutation, SaveOfferMutationVariables>;

/**
 * __useSaveOfferMutation__
 *
 * To run a mutation, you first call `useSaveOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveOfferMutation, { data, loading, error }] = useSaveOfferMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useSaveOfferMutation(baseOptions?: Apollo.MutationHookOptions<SaveOfferMutation, SaveOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveOfferMutation, SaveOfferMutationVariables>(SaveOfferDocument, options);
      }
export type SaveOfferMutationHookResult = ReturnType<typeof useSaveOfferMutation>;
export type SaveOfferMutationResult = Apollo.MutationResult<SaveOfferMutation>;
export type SaveOfferMutationOptions = Apollo.BaseMutationOptions<SaveOfferMutation, SaveOfferMutationVariables>;
export const AskToBecomeClientDocument = gql`
    mutation AskToBecomeClient {
  askToBecomeClient {
    id
  }
}
    `;
export type AskToBecomeClientMutationFn = Apollo.MutationFunction<AskToBecomeClientMutation, AskToBecomeClientMutationVariables>;

/**
 * __useAskToBecomeClientMutation__
 *
 * To run a mutation, you first call `useAskToBecomeClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAskToBecomeClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [askToBecomeClientMutation, { data, loading, error }] = useAskToBecomeClientMutation({
 *   variables: {
 *   },
 * });
 */
export function useAskToBecomeClientMutation(baseOptions?: Apollo.MutationHookOptions<AskToBecomeClientMutation, AskToBecomeClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AskToBecomeClientMutation, AskToBecomeClientMutationVariables>(AskToBecomeClientDocument, options);
      }
export type AskToBecomeClientMutationHookResult = ReturnType<typeof useAskToBecomeClientMutation>;
export type AskToBecomeClientMutationResult = Apollo.MutationResult<AskToBecomeClientMutation>;
export type AskToBecomeClientMutationOptions = Apollo.BaseMutationOptions<AskToBecomeClientMutation, AskToBecomeClientMutationVariables>;
export const AskToBecomeSellerDocument = gql`
    mutation AskToBecomeSeller {
  askToBecomeSeller {
    id
  }
}
    `;
export type AskToBecomeSellerMutationFn = Apollo.MutationFunction<AskToBecomeSellerMutation, AskToBecomeSellerMutationVariables>;

/**
 * __useAskToBecomeSellerMutation__
 *
 * To run a mutation, you first call `useAskToBecomeSellerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAskToBecomeSellerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [askToBecomeSellerMutation, { data, loading, error }] = useAskToBecomeSellerMutation({
 *   variables: {
 *   },
 * });
 */
export function useAskToBecomeSellerMutation(baseOptions?: Apollo.MutationHookOptions<AskToBecomeSellerMutation, AskToBecomeSellerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AskToBecomeSellerMutation, AskToBecomeSellerMutationVariables>(AskToBecomeSellerDocument, options);
      }
export type AskToBecomeSellerMutationHookResult = ReturnType<typeof useAskToBecomeSellerMutation>;
export type AskToBecomeSellerMutationResult = Apollo.MutationResult<AskToBecomeSellerMutation>;
export type AskToBecomeSellerMutationOptions = Apollo.BaseMutationOptions<AskToBecomeSellerMutation, AskToBecomeSellerMutationVariables>;
export const ChangeMyLanguageDocument = gql`
    mutation ChangeMyLanguage($language: Language!) {
  changeLanguage(language: $language)
}
    `;
export type ChangeMyLanguageMutationFn = Apollo.MutationFunction<ChangeMyLanguageMutation, ChangeMyLanguageMutationVariables>;

/**
 * __useChangeMyLanguageMutation__
 *
 * To run a mutation, you first call `useChangeMyLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeMyLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeMyLanguageMutation, { data, loading, error }] = useChangeMyLanguageMutation({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useChangeMyLanguageMutation(baseOptions?: Apollo.MutationHookOptions<ChangeMyLanguageMutation, ChangeMyLanguageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeMyLanguageMutation, ChangeMyLanguageMutationVariables>(ChangeMyLanguageDocument, options);
      }
export type ChangeMyLanguageMutationHookResult = ReturnType<typeof useChangeMyLanguageMutation>;
export type ChangeMyLanguageMutationResult = Apollo.MutationResult<ChangeMyLanguageMutation>;
export type ChangeMyLanguageMutationOptions = Apollo.BaseMutationOptions<ChangeMyLanguageMutation, ChangeMyLanguageMutationVariables>;
export const ChangeMyPasswordDocument = gql`
    mutation ChangeMyPassword($dto: ChangeMyPasswordDto!) {
  changePassword(dto: $dto)
}
    `;
export type ChangeMyPasswordMutationFn = Apollo.MutationFunction<ChangeMyPasswordMutation, ChangeMyPasswordMutationVariables>;

/**
 * __useChangeMyPasswordMutation__
 *
 * To run a mutation, you first call `useChangeMyPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeMyPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeMyPasswordMutation, { data, loading, error }] = useChangeMyPasswordMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useChangeMyPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangeMyPasswordMutation, ChangeMyPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeMyPasswordMutation, ChangeMyPasswordMutationVariables>(ChangeMyPasswordDocument, options);
      }
export type ChangeMyPasswordMutationHookResult = ReturnType<typeof useChangeMyPasswordMutation>;
export type ChangeMyPasswordMutationResult = Apollo.MutationResult<ChangeMyPasswordMutation>;
export type ChangeMyPasswordMutationOptions = Apollo.BaseMutationOptions<ChangeMyPasswordMutation, ChangeMyPasswordMutationVariables>;
export const MeDocument = gql`
    query Me {
  getLoggedUser {
    id
    email
    firstName
    lastName
    phoneNumber
    gender
    roles
    validatedAt
    kpi {
      numberOfMyFavorites
      numberOfMyOffers
      numberOfMyAcceptedOffers
      numberOfMyWonOffers
      numberOfMyDeclinedOffers
      numberOfMyWaitingOffers
      numberOfMyVehicles
      numberOfMyDraftVehicles
      numberOfMyPublishedVehicles
      numberOfMyExpiredVehicles
      numberOfMyValidatedVehicles
      numberOfMyFinishedVehicles
      numberOfMyDeletedVehicles
      numberOfVehiclesOnSale
      numberOfMyHiddenVehicles
      numberOfMyOpenedVehicles
      averageRatingScore
    }
    dealer {
      id
      name
      vatNumber
      phoneNumber
      email
      iban
      logo {
        id
        uri
      }
      addresses {
        id
        street
        number
        city
        zip
        country
        type
      }
      contacts {
        id
        firstName
        lastName
        phoneNumber
        email
      }
    }
    latestClientSubscription {
      id
      userId
      startAt
      endAt
      status
    }
    latestSellerSubscription {
      id
      userId
      startAt
      endAt
      status
    }
    vehicleIdsInFavorites
    averageRatingScore
    language
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const RegisterDocument = gql`
    mutation Register($dto: RegisterDto!) {
  register(dto: $dto) {
    id
    email
    firstName
    lastName
    phoneNumber
    gender
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const SaveUserDocument = gql`
    mutation SaveUser($dto: SaveUserDto!) {
  saveUser(dto: $dto) {
    id
    email
    firstName
    lastName
    phoneNumber
    gender
  }
}
    `;
export type SaveUserMutationFn = Apollo.MutationFunction<SaveUserMutation, SaveUserMutationVariables>;

/**
 * __useSaveUserMutation__
 *
 * To run a mutation, you first call `useSaveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserMutation, { data, loading, error }] = useSaveUserMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useSaveUserMutation(baseOptions?: Apollo.MutationHookOptions<SaveUserMutation, SaveUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveUserMutation, SaveUserMutationVariables>(SaveUserDocument, options);
      }
export type SaveUserMutationHookResult = ReturnType<typeof useSaveUserMutation>;
export type SaveUserMutationResult = Apollo.MutationResult<SaveUserMutation>;
export type SaveUserMutationOptions = Apollo.BaseMutationOptions<SaveUserMutation, SaveUserMutationVariables>;
export const ValidateUserAccountDocument = gql`
    mutation ValidateUserAccount($dto: ValidateUserAccountDto!) {
  validateUser(dto: $dto)
}
    `;
export type ValidateUserAccountMutationFn = Apollo.MutationFunction<ValidateUserAccountMutation, ValidateUserAccountMutationVariables>;

/**
 * __useValidateUserAccountMutation__
 *
 * To run a mutation, you first call `useValidateUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateUserAccountMutation, { data, loading, error }] = useValidateUserAccountMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useValidateUserAccountMutation(baseOptions?: Apollo.MutationHookOptions<ValidateUserAccountMutation, ValidateUserAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateUserAccountMutation, ValidateUserAccountMutationVariables>(ValidateUserAccountDocument, options);
      }
export type ValidateUserAccountMutationHookResult = ReturnType<typeof useValidateUserAccountMutation>;
export type ValidateUserAccountMutationResult = Apollo.MutationResult<ValidateUserAccountMutation>;
export type ValidateUserAccountMutationOptions = Apollo.BaseMutationOptions<ValidateUserAccountMutation, ValidateUserAccountMutationVariables>;
export const AddVehicleToFavoritesDocument = gql`
    mutation AddVehicleToFavorites($vehicleId: Int!) {
  addVehicleToFavorites(vehicleId: $vehicleId) {
    id
  }
}
    `;
export type AddVehicleToFavoritesMutationFn = Apollo.MutationFunction<AddVehicleToFavoritesMutation, AddVehicleToFavoritesMutationVariables>;

/**
 * __useAddVehicleToFavoritesMutation__
 *
 * To run a mutation, you first call `useAddVehicleToFavoritesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVehicleToFavoritesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVehicleToFavoritesMutation, { data, loading, error }] = useAddVehicleToFavoritesMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useAddVehicleToFavoritesMutation(baseOptions?: Apollo.MutationHookOptions<AddVehicleToFavoritesMutation, AddVehicleToFavoritesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddVehicleToFavoritesMutation, AddVehicleToFavoritesMutationVariables>(AddVehicleToFavoritesDocument, options);
      }
export type AddVehicleToFavoritesMutationHookResult = ReturnType<typeof useAddVehicleToFavoritesMutation>;
export type AddVehicleToFavoritesMutationResult = Apollo.MutationResult<AddVehicleToFavoritesMutation>;
export type AddVehicleToFavoritesMutationOptions = Apollo.BaseMutationOptions<AddVehicleToFavoritesMutation, AddVehicleToFavoritesMutationVariables>;
export const DeleteVehicleFromFavoritesDocument = gql`
    mutation DeleteVehicleFromFavorites($vehicleId: Int!) {
  deleteVehicleFromFavorites(vehicleId: $vehicleId)
}
    `;
export type DeleteVehicleFromFavoritesMutationFn = Apollo.MutationFunction<DeleteVehicleFromFavoritesMutation, DeleteVehicleFromFavoritesMutationVariables>;

/**
 * __useDeleteVehicleFromFavoritesMutation__
 *
 * To run a mutation, you first call `useDeleteVehicleFromFavoritesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVehicleFromFavoritesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVehicleFromFavoritesMutation, { data, loading, error }] = useDeleteVehicleFromFavoritesMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useDeleteVehicleFromFavoritesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVehicleFromFavoritesMutation, DeleteVehicleFromFavoritesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVehicleFromFavoritesMutation, DeleteVehicleFromFavoritesMutationVariables>(DeleteVehicleFromFavoritesDocument, options);
      }
export type DeleteVehicleFromFavoritesMutationHookResult = ReturnType<typeof useDeleteVehicleFromFavoritesMutation>;
export type DeleteVehicleFromFavoritesMutationResult = Apollo.MutationResult<DeleteVehicleFromFavoritesMutation>;
export type DeleteVehicleFromFavoritesMutationOptions = Apollo.BaseMutationOptions<DeleteVehicleFromFavoritesMutation, DeleteVehicleFromFavoritesMutationVariables>;
export const AddUserHiddenVehicleDocument = gql`
    mutation AddUserHiddenVehicle($vehicleId: Int!) {
  addUserHiddenVehicle(vehicleId: $vehicleId) {
    id
  }
}
    `;
export type AddUserHiddenVehicleMutationFn = Apollo.MutationFunction<AddUserHiddenVehicleMutation, AddUserHiddenVehicleMutationVariables>;

/**
 * __useAddUserHiddenVehicleMutation__
 *
 * To run a mutation, you first call `useAddUserHiddenVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserHiddenVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserHiddenVehicleMutation, { data, loading, error }] = useAddUserHiddenVehicleMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useAddUserHiddenVehicleMutation(baseOptions?: Apollo.MutationHookOptions<AddUserHiddenVehicleMutation, AddUserHiddenVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserHiddenVehicleMutation, AddUserHiddenVehicleMutationVariables>(AddUserHiddenVehicleDocument, options);
      }
export type AddUserHiddenVehicleMutationHookResult = ReturnType<typeof useAddUserHiddenVehicleMutation>;
export type AddUserHiddenVehicleMutationResult = Apollo.MutationResult<AddUserHiddenVehicleMutation>;
export type AddUserHiddenVehicleMutationOptions = Apollo.BaseMutationOptions<AddUserHiddenVehicleMutation, AddUserHiddenVehicleMutationVariables>;
export const AddUserOpenedVehicleDocument = gql`
    mutation AddUserOpenedVehicle($vehicleId: Int!) {
  addUserOpenedVehicle(vehicleId: $vehicleId) {
    id
  }
}
    `;
export type AddUserOpenedVehicleMutationFn = Apollo.MutationFunction<AddUserOpenedVehicleMutation, AddUserOpenedVehicleMutationVariables>;

/**
 * __useAddUserOpenedVehicleMutation__
 *
 * To run a mutation, you first call `useAddUserOpenedVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserOpenedVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserOpenedVehicleMutation, { data, loading, error }] = useAddUserOpenedVehicleMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useAddUserOpenedVehicleMutation(baseOptions?: Apollo.MutationHookOptions<AddUserOpenedVehicleMutation, AddUserOpenedVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserOpenedVehicleMutation, AddUserOpenedVehicleMutationVariables>(AddUserOpenedVehicleDocument, options);
      }
export type AddUserOpenedVehicleMutationHookResult = ReturnType<typeof useAddUserOpenedVehicleMutation>;
export type AddUserOpenedVehicleMutationResult = Apollo.MutationResult<AddUserOpenedVehicleMutation>;
export type AddUserOpenedVehicleMutationOptions = Apollo.BaseMutationOptions<AddUserOpenedVehicleMutation, AddUserOpenedVehicleMutationVariables>;
export const RateSomeoneDocument = gql`
    mutation RateSomeone($dto: RateSomeoneDto!) {
  rateSomeone(dto: $dto) {
    id
  }
}
    `;
export type RateSomeoneMutationFn = Apollo.MutationFunction<RateSomeoneMutation, RateSomeoneMutationVariables>;

/**
 * __useRateSomeoneMutation__
 *
 * To run a mutation, you first call `useRateSomeoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRateSomeoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rateSomeoneMutation, { data, loading, error }] = useRateSomeoneMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useRateSomeoneMutation(baseOptions?: Apollo.MutationHookOptions<RateSomeoneMutation, RateSomeoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RateSomeoneMutation, RateSomeoneMutationVariables>(RateSomeoneDocument, options);
      }
export type RateSomeoneMutationHookResult = ReturnType<typeof useRateSomeoneMutation>;
export type RateSomeoneMutationResult = Apollo.MutationResult<RateSomeoneMutation>;
export type RateSomeoneMutationOptions = Apollo.BaseMutationOptions<RateSomeoneMutation, RateSomeoneMutationVariables>;
export const AskRecoveryPasswordCodeDocument = gql`
    mutation AskRecoveryPasswordCode($email: String!) {
  askRecoveryPasswordCode(email: $email)
}
    `;
export type AskRecoveryPasswordCodeMutationFn = Apollo.MutationFunction<AskRecoveryPasswordCodeMutation, AskRecoveryPasswordCodeMutationVariables>;

/**
 * __useAskRecoveryPasswordCodeMutation__
 *
 * To run a mutation, you first call `useAskRecoveryPasswordCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAskRecoveryPasswordCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [askRecoveryPasswordCodeMutation, { data, loading, error }] = useAskRecoveryPasswordCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAskRecoveryPasswordCodeMutation(baseOptions?: Apollo.MutationHookOptions<AskRecoveryPasswordCodeMutation, AskRecoveryPasswordCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AskRecoveryPasswordCodeMutation, AskRecoveryPasswordCodeMutationVariables>(AskRecoveryPasswordCodeDocument, options);
      }
export type AskRecoveryPasswordCodeMutationHookResult = ReturnType<typeof useAskRecoveryPasswordCodeMutation>;
export type AskRecoveryPasswordCodeMutationResult = Apollo.MutationResult<AskRecoveryPasswordCodeMutation>;
export type AskRecoveryPasswordCodeMutationOptions = Apollo.BaseMutationOptions<AskRecoveryPasswordCodeMutation, AskRecoveryPasswordCodeMutationVariables>;
export const RecoverPasswordDocument = gql`
    mutation RecoverPassword($dto: RecoverPasswordDto!) {
  recoverPassword(dto: $dto) {
    id
  }
}
    `;
export type RecoverPasswordMutationFn = Apollo.MutationFunction<RecoverPasswordMutation, RecoverPasswordMutationVariables>;

/**
 * __useRecoverPasswordMutation__
 *
 * To run a mutation, you first call `useRecoverPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverPasswordMutation, { data, loading, error }] = useRecoverPasswordMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useRecoverPasswordMutation(baseOptions?: Apollo.MutationHookOptions<RecoverPasswordMutation, RecoverPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecoverPasswordMutation, RecoverPasswordMutationVariables>(RecoverPasswordDocument, options);
      }
export type RecoverPasswordMutationHookResult = ReturnType<typeof useRecoverPasswordMutation>;
export type RecoverPasswordMutationResult = Apollo.MutationResult<RecoverPasswordMutation>;
export type RecoverPasswordMutationOptions = Apollo.BaseMutationOptions<RecoverPasswordMutation, RecoverPasswordMutationVariables>;
export const ValidateRecoveryPasswordCodeDocument = gql`
    query ValidateRecoveryPasswordCode($dto: ValidateRecoveryPasswordCodeDto!) {
  validateRecoveryPasswordCode(dto: $dto) {
    id
  }
}
    `;

/**
 * __useValidateRecoveryPasswordCodeQuery__
 *
 * To run a query within a React component, call `useValidateRecoveryPasswordCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateRecoveryPasswordCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateRecoveryPasswordCodeQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useValidateRecoveryPasswordCodeQuery(baseOptions: Apollo.QueryHookOptions<ValidateRecoveryPasswordCodeQuery, ValidateRecoveryPasswordCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateRecoveryPasswordCodeQuery, ValidateRecoveryPasswordCodeQueryVariables>(ValidateRecoveryPasswordCodeDocument, options);
      }
export function useValidateRecoveryPasswordCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateRecoveryPasswordCodeQuery, ValidateRecoveryPasswordCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateRecoveryPasswordCodeQuery, ValidateRecoveryPasswordCodeQueryVariables>(ValidateRecoveryPasswordCodeDocument, options);
        }
export function useValidateRecoveryPasswordCodeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ValidateRecoveryPasswordCodeQuery, ValidateRecoveryPasswordCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ValidateRecoveryPasswordCodeQuery, ValidateRecoveryPasswordCodeQueryVariables>(ValidateRecoveryPasswordCodeDocument, options);
        }
export type ValidateRecoveryPasswordCodeQueryHookResult = ReturnType<typeof useValidateRecoveryPasswordCodeQuery>;
export type ValidateRecoveryPasswordCodeLazyQueryHookResult = ReturnType<typeof useValidateRecoveryPasswordCodeLazyQuery>;
export type ValidateRecoveryPasswordCodeSuspenseQueryHookResult = ReturnType<typeof useValidateRecoveryPasswordCodeSuspenseQuery>;
export type ValidateRecoveryPasswordCodeQueryResult = Apollo.QueryResult<ValidateRecoveryPasswordCodeQuery, ValidateRecoveryPasswordCodeQueryVariables>;
export const CancelVehicleDocument = gql`
    mutation CancelVehicle($dto: CancelVehicleDto!) {
  cancelVehicle(dto: $dto) {
    id
  }
}
    `;
export type CancelVehicleMutationFn = Apollo.MutationFunction<CancelVehicleMutation, CancelVehicleMutationVariables>;

/**
 * __useCancelVehicleMutation__
 *
 * To run a mutation, you first call `useCancelVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelVehicleMutation, { data, loading, error }] = useCancelVehicleMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useCancelVehicleMutation(baseOptions?: Apollo.MutationHookOptions<CancelVehicleMutation, CancelVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelVehicleMutation, CancelVehicleMutationVariables>(CancelVehicleDocument, options);
      }
export type CancelVehicleMutationHookResult = ReturnType<typeof useCancelVehicleMutation>;
export type CancelVehicleMutationResult = Apollo.MutationResult<CancelVehicleMutation>;
export type CancelVehicleMutationOptions = Apollo.BaseMutationOptions<CancelVehicleMutation, CancelVehicleMutationVariables>;
export const DeleteOneVehicleMediaDocument = gql`
    mutation DeleteOneVehicleMedia($mediaId: Int!) {
  deleteVehicleMedia(mediaId: $mediaId)
}
    `;
export type DeleteOneVehicleMediaMutationFn = Apollo.MutationFunction<DeleteOneVehicleMediaMutation, DeleteOneVehicleMediaMutationVariables>;

/**
 * __useDeleteOneVehicleMediaMutation__
 *
 * To run a mutation, you first call `useDeleteOneVehicleMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneVehicleMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneVehicleMediaMutation, { data, loading, error }] = useDeleteOneVehicleMediaMutation({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useDeleteOneVehicleMediaMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneVehicleMediaMutation, DeleteOneVehicleMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneVehicleMediaMutation, DeleteOneVehicleMediaMutationVariables>(DeleteOneVehicleMediaDocument, options);
      }
export type DeleteOneVehicleMediaMutationHookResult = ReturnType<typeof useDeleteOneVehicleMediaMutation>;
export type DeleteOneVehicleMediaMutationResult = Apollo.MutationResult<DeleteOneVehicleMediaMutation>;
export type DeleteOneVehicleMediaMutationOptions = Apollo.BaseMutationOptions<DeleteOneVehicleMediaMutation, DeleteOneVehicleMediaMutationVariables>;
export const FinalizeVehicleSaleDocument = gql`
    mutation FinalizeVehicleSale($vehicleId: Int!) {
  finalizeVehicleSale(id: $vehicleId) {
    id
  }
}
    `;
export type FinalizeVehicleSaleMutationFn = Apollo.MutationFunction<FinalizeVehicleSaleMutation, FinalizeVehicleSaleMutationVariables>;

/**
 * __useFinalizeVehicleSaleMutation__
 *
 * To run a mutation, you first call `useFinalizeVehicleSaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeVehicleSaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeVehicleSaleMutation, { data, loading, error }] = useFinalizeVehicleSaleMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useFinalizeVehicleSaleMutation(baseOptions?: Apollo.MutationHookOptions<FinalizeVehicleSaleMutation, FinalizeVehicleSaleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinalizeVehicleSaleMutation, FinalizeVehicleSaleMutationVariables>(FinalizeVehicleSaleDocument, options);
      }
export type FinalizeVehicleSaleMutationHookResult = ReturnType<typeof useFinalizeVehicleSaleMutation>;
export type FinalizeVehicleSaleMutationResult = Apollo.MutationResult<FinalizeVehicleSaleMutation>;
export type FinalizeVehicleSaleMutationOptions = Apollo.BaseMutationOptions<FinalizeVehicleSaleMutation, FinalizeVehicleSaleMutationVariables>;
export const GenerateVehicleMediaThumbnailsDocument = gql`
    mutation GenerateVehicleMediaThumbnails($dto: GenerateVehicleMediaThumbnailDto!) {
  generateVehicleMediaThumbnails(dto: $dto)
}
    `;
export type GenerateVehicleMediaThumbnailsMutationFn = Apollo.MutationFunction<GenerateVehicleMediaThumbnailsMutation, GenerateVehicleMediaThumbnailsMutationVariables>;

/**
 * __useGenerateVehicleMediaThumbnailsMutation__
 *
 * To run a mutation, you first call `useGenerateVehicleMediaThumbnailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateVehicleMediaThumbnailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateVehicleMediaThumbnailsMutation, { data, loading, error }] = useGenerateVehicleMediaThumbnailsMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useGenerateVehicleMediaThumbnailsMutation(baseOptions?: Apollo.MutationHookOptions<GenerateVehicleMediaThumbnailsMutation, GenerateVehicleMediaThumbnailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateVehicleMediaThumbnailsMutation, GenerateVehicleMediaThumbnailsMutationVariables>(GenerateVehicleMediaThumbnailsDocument, options);
      }
export type GenerateVehicleMediaThumbnailsMutationHookResult = ReturnType<typeof useGenerateVehicleMediaThumbnailsMutation>;
export type GenerateVehicleMediaThumbnailsMutationResult = Apollo.MutationResult<GenerateVehicleMediaThumbnailsMutation>;
export type GenerateVehicleMediaThumbnailsMutationOptions = Apollo.BaseMutationOptions<GenerateVehicleMediaThumbnailsMutation, GenerateVehicleMediaThumbnailsMutationVariables>;
export const GetMyPaginatedVehiclesDocument = gql`
    query GetMyPaginatedVehicles($dto: GetMyPaginatedVehiclesDto!) {
  myPaginatedVehicles(dto: $dto) {
    results {
      id
      status
      dealer {
        id
        name
      }
      immediatePurchase {
        enabled
        price
      }
      brand {
        name
      }
      model {
        name
      }
      attributes {
        attributeId
        value
        attributeValueId
      }
      frontSideThumbnailMedia {
        file {
          uri
        }
      }
      numberOfOffers
      addressToCollect {
        city
        country
      }
      addedByUserId
      publishedAt
      availableAt
      rating {
        isBuyerRated
      }
    }
    total
  }
}
    `;

/**
 * __useGetMyPaginatedVehiclesQuery__
 *
 * To run a query within a React component, call `useGetMyPaginatedVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyPaginatedVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyPaginatedVehiclesQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useGetMyPaginatedVehiclesQuery(baseOptions: Apollo.QueryHookOptions<GetMyPaginatedVehiclesQuery, GetMyPaginatedVehiclesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyPaginatedVehiclesQuery, GetMyPaginatedVehiclesQueryVariables>(GetMyPaginatedVehiclesDocument, options);
      }
export function useGetMyPaginatedVehiclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyPaginatedVehiclesQuery, GetMyPaginatedVehiclesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyPaginatedVehiclesQuery, GetMyPaginatedVehiclesQueryVariables>(GetMyPaginatedVehiclesDocument, options);
        }
export function useGetMyPaginatedVehiclesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyPaginatedVehiclesQuery, GetMyPaginatedVehiclesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyPaginatedVehiclesQuery, GetMyPaginatedVehiclesQueryVariables>(GetMyPaginatedVehiclesDocument, options);
        }
export type GetMyPaginatedVehiclesQueryHookResult = ReturnType<typeof useGetMyPaginatedVehiclesQuery>;
export type GetMyPaginatedVehiclesLazyQueryHookResult = ReturnType<typeof useGetMyPaginatedVehiclesLazyQuery>;
export type GetMyPaginatedVehiclesSuspenseQueryHookResult = ReturnType<typeof useGetMyPaginatedVehiclesSuspenseQuery>;
export type GetMyPaginatedVehiclesQueryResult = Apollo.QueryResult<GetMyPaginatedVehiclesQuery, GetMyPaginatedVehiclesQueryVariables>;
export const GetOneVehicleDocument = gql`
    query GetOneVehicle($id: Int!) {
  vehicleById(id: $id) {
    id
    status
    addedByUserId
    addedByUser {
      firstName
      lastName
      averageRatingScore
      dealer {
        id
        name
        logo {
          uri
        }
      }
    }
    addressId
    contactId
    immediatePurchase {
      enabled
      isVatExcluded
      price
    }
    brand {
      name
    }
    model {
      name
    }
    brandId
    modelId
    availableAt
    addressToCollect {
      street
      number
      city
      zip
      country
    }
    contact {
      lastName
      firstName
      email
      phoneNumber
    }
    attributes {
      id
      attributeId
      value
      attributeValueId
      attribute {
        name
        type
        categoryId
      }
      attributeValue {
        value
      }
    }
    equipments {
      id
      equipmentId
      equipment {
        name
      }
    }
    damages {
      id
      damageId
      damage {
        name
      }
    }
    medias {
      id
      fileId
      type
      tag
      file {
        id
        uri
      }
      vehicleDamageId
      vehicleEquipmentId
    }
    expertiseDocumentMedia {
      file {
        uri
      }
    }
  }
}
    `;

/**
 * __useGetOneVehicleQuery__
 *
 * To run a query within a React component, call `useGetOneVehicleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneVehicleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneVehicleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOneVehicleQuery(baseOptions: Apollo.QueryHookOptions<GetOneVehicleQuery, GetOneVehicleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOneVehicleQuery, GetOneVehicleQueryVariables>(GetOneVehicleDocument, options);
      }
export function useGetOneVehicleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneVehicleQuery, GetOneVehicleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOneVehicleQuery, GetOneVehicleQueryVariables>(GetOneVehicleDocument, options);
        }
export function useGetOneVehicleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetOneVehicleQuery, GetOneVehicleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOneVehicleQuery, GetOneVehicleQueryVariables>(GetOneVehicleDocument, options);
        }
export type GetOneVehicleQueryHookResult = ReturnType<typeof useGetOneVehicleQuery>;
export type GetOneVehicleLazyQueryHookResult = ReturnType<typeof useGetOneVehicleLazyQuery>;
export type GetOneVehicleSuspenseQueryHookResult = ReturnType<typeof useGetOneVehicleSuspenseQuery>;
export type GetOneVehicleQueryResult = Apollo.QueryResult<GetOneVehicleQuery, GetOneVehicleQueryVariables>;
export const GetPaginatedOpenedVehiclesDocument = gql`
    query GetPaginatedOpenedVehicles($dto: GetPaginatedOpenedVehiclesDto!) {
  paginatedOpenedVehicles(dto: $dto) {
    results {
      id
      status
      dealer {
        id
        name
      }
      immediatePurchase {
        enabled
        price
      }
      brand {
        name
      }
      model {
        name
      }
      attributes {
        attributeId
        value
        attributeValueId
      }
      frontSideThumbnailMedia {
        file {
          uri
        }
      }
      numberOfOffers
      addressToCollect {
        city
        country
      }
      addedByUserId
      publishedAt
      availableAt
    }
    total
  }
}
    `;

/**
 * __useGetPaginatedOpenedVehiclesQuery__
 *
 * To run a query within a React component, call `useGetPaginatedOpenedVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedOpenedVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedOpenedVehiclesQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useGetPaginatedOpenedVehiclesQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedOpenedVehiclesQuery, GetPaginatedOpenedVehiclesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedOpenedVehiclesQuery, GetPaginatedOpenedVehiclesQueryVariables>(GetPaginatedOpenedVehiclesDocument, options);
      }
export function useGetPaginatedOpenedVehiclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedOpenedVehiclesQuery, GetPaginatedOpenedVehiclesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedOpenedVehiclesQuery, GetPaginatedOpenedVehiclesQueryVariables>(GetPaginatedOpenedVehiclesDocument, options);
        }
export function useGetPaginatedOpenedVehiclesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPaginatedOpenedVehiclesQuery, GetPaginatedOpenedVehiclesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaginatedOpenedVehiclesQuery, GetPaginatedOpenedVehiclesQueryVariables>(GetPaginatedOpenedVehiclesDocument, options);
        }
export type GetPaginatedOpenedVehiclesQueryHookResult = ReturnType<typeof useGetPaginatedOpenedVehiclesQuery>;
export type GetPaginatedOpenedVehiclesLazyQueryHookResult = ReturnType<typeof useGetPaginatedOpenedVehiclesLazyQuery>;
export type GetPaginatedOpenedVehiclesSuspenseQueryHookResult = ReturnType<typeof useGetPaginatedOpenedVehiclesSuspenseQuery>;
export type GetPaginatedOpenedVehiclesQueryResult = Apollo.QueryResult<GetPaginatedOpenedVehiclesQuery, GetPaginatedOpenedVehiclesQueryVariables>;
export const GetPaginatedPublishedVehiclesDocument = gql`
    query GetPaginatedPublishedVehicles($dto: GetPaginatedPublishedVehiclesDto!) {
  paginatedPublishedVehicles(dto: $dto) {
    results {
      id
      status
      dealer {
        id
        name
      }
      immediatePurchase {
        enabled
        price
      }
      brand {
        name
      }
      model {
        name
      }
      attributes {
        attributeId
        value
        attributeValueId
      }
      frontSideThumbnailMedia {
        file {
          uri
        }
      }
      numberOfOffers
      addressToCollect {
        city
        country
      }
      addedByUserId
      publishedAt
      availableAt
    }
    total
  }
}
    `;

/**
 * __useGetPaginatedPublishedVehiclesQuery__
 *
 * To run a query within a React component, call `useGetPaginatedPublishedVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedPublishedVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedPublishedVehiclesQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useGetPaginatedPublishedVehiclesQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedPublishedVehiclesQuery, GetPaginatedPublishedVehiclesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedPublishedVehiclesQuery, GetPaginatedPublishedVehiclesQueryVariables>(GetPaginatedPublishedVehiclesDocument, options);
      }
export function useGetPaginatedPublishedVehiclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedPublishedVehiclesQuery, GetPaginatedPublishedVehiclesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedPublishedVehiclesQuery, GetPaginatedPublishedVehiclesQueryVariables>(GetPaginatedPublishedVehiclesDocument, options);
        }
export function useGetPaginatedPublishedVehiclesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPaginatedPublishedVehiclesQuery, GetPaginatedPublishedVehiclesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaginatedPublishedVehiclesQuery, GetPaginatedPublishedVehiclesQueryVariables>(GetPaginatedPublishedVehiclesDocument, options);
        }
export type GetPaginatedPublishedVehiclesQueryHookResult = ReturnType<typeof useGetPaginatedPublishedVehiclesQuery>;
export type GetPaginatedPublishedVehiclesLazyQueryHookResult = ReturnType<typeof useGetPaginatedPublishedVehiclesLazyQuery>;
export type GetPaginatedPublishedVehiclesSuspenseQueryHookResult = ReturnType<typeof useGetPaginatedPublishedVehiclesSuspenseQuery>;
export type GetPaginatedPublishedVehiclesQueryResult = Apollo.QueryResult<GetPaginatedPublishedVehiclesQuery, GetPaginatedPublishedVehiclesQueryVariables>;
export const GetPaginatedPublishedVehiclesByDealerDocument = gql`
    query GetPaginatedPublishedVehiclesByDealer($dto: GetPaginatedPublishedVehiclesByDealerDto!) {
  paginatedPublishedVehiclesByDealer(dto: $dto) {
    results {
      id
      status
      dealer {
        id
        name
      }
      immediatePurchase {
        enabled
        price
      }
      brand {
        name
      }
      model {
        name
      }
      attributes {
        attributeId
        value
        attributeValueId
      }
      frontSideThumbnailMedia {
        file {
          uri
        }
      }
      numberOfOffers
      addressToCollect {
        city
        country
      }
      addedByUserId
      publishedAt
      availableAt
    }
    total
  }
}
    `;

/**
 * __useGetPaginatedPublishedVehiclesByDealerQuery__
 *
 * To run a query within a React component, call `useGetPaginatedPublishedVehiclesByDealerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedPublishedVehiclesByDealerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedPublishedVehiclesByDealerQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useGetPaginatedPublishedVehiclesByDealerQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedPublishedVehiclesByDealerQuery, GetPaginatedPublishedVehiclesByDealerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedPublishedVehiclesByDealerQuery, GetPaginatedPublishedVehiclesByDealerQueryVariables>(GetPaginatedPublishedVehiclesByDealerDocument, options);
      }
export function useGetPaginatedPublishedVehiclesByDealerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedPublishedVehiclesByDealerQuery, GetPaginatedPublishedVehiclesByDealerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedPublishedVehiclesByDealerQuery, GetPaginatedPublishedVehiclesByDealerQueryVariables>(GetPaginatedPublishedVehiclesByDealerDocument, options);
        }
export function useGetPaginatedPublishedVehiclesByDealerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPaginatedPublishedVehiclesByDealerQuery, GetPaginatedPublishedVehiclesByDealerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaginatedPublishedVehiclesByDealerQuery, GetPaginatedPublishedVehiclesByDealerQueryVariables>(GetPaginatedPublishedVehiclesByDealerDocument, options);
        }
export type GetPaginatedPublishedVehiclesByDealerQueryHookResult = ReturnType<typeof useGetPaginatedPublishedVehiclesByDealerQuery>;
export type GetPaginatedPublishedVehiclesByDealerLazyQueryHookResult = ReturnType<typeof useGetPaginatedPublishedVehiclesByDealerLazyQuery>;
export type GetPaginatedPublishedVehiclesByDealerSuspenseQueryHookResult = ReturnType<typeof useGetPaginatedPublishedVehiclesByDealerSuspenseQuery>;
export type GetPaginatedPublishedVehiclesByDealerQueryResult = Apollo.QueryResult<GetPaginatedPublishedVehiclesByDealerQuery, GetPaginatedPublishedVehiclesByDealerQueryVariables>;
export const GetPaginatedVehiclesFromMyFavoritesDocument = gql`
    query GetPaginatedVehiclesFromMyFavorites($dto: GetPaginatedVehiclesFromMyFavoritesDto!) {
  paginatedVehiclesFromMyFavorites(dto: $dto) {
    results {
      id
      status
      dealer {
        id
        name
      }
      immediatePurchase {
        enabled
        price
      }
      brand {
        name
      }
      model {
        name
      }
      attributes {
        attributeId
        value
        attributeValueId
      }
      frontSideThumbnailMedia {
        file {
          uri
        }
      }
      numberOfOffers
      addressToCollect {
        city
        country
      }
      addedByUserId
      publishedAt
      availableAt
    }
    total
  }
}
    `;

/**
 * __useGetPaginatedVehiclesFromMyFavoritesQuery__
 *
 * To run a query within a React component, call `useGetPaginatedVehiclesFromMyFavoritesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedVehiclesFromMyFavoritesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedVehiclesFromMyFavoritesQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useGetPaginatedVehiclesFromMyFavoritesQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedVehiclesFromMyFavoritesQuery, GetPaginatedVehiclesFromMyFavoritesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedVehiclesFromMyFavoritesQuery, GetPaginatedVehiclesFromMyFavoritesQueryVariables>(GetPaginatedVehiclesFromMyFavoritesDocument, options);
      }
export function useGetPaginatedVehiclesFromMyFavoritesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedVehiclesFromMyFavoritesQuery, GetPaginatedVehiclesFromMyFavoritesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedVehiclesFromMyFavoritesQuery, GetPaginatedVehiclesFromMyFavoritesQueryVariables>(GetPaginatedVehiclesFromMyFavoritesDocument, options);
        }
export function useGetPaginatedVehiclesFromMyFavoritesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPaginatedVehiclesFromMyFavoritesQuery, GetPaginatedVehiclesFromMyFavoritesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaginatedVehiclesFromMyFavoritesQuery, GetPaginatedVehiclesFromMyFavoritesQueryVariables>(GetPaginatedVehiclesFromMyFavoritesDocument, options);
        }
export type GetPaginatedVehiclesFromMyFavoritesQueryHookResult = ReturnType<typeof useGetPaginatedVehiclesFromMyFavoritesQuery>;
export type GetPaginatedVehiclesFromMyFavoritesLazyQueryHookResult = ReturnType<typeof useGetPaginatedVehiclesFromMyFavoritesLazyQuery>;
export type GetPaginatedVehiclesFromMyFavoritesSuspenseQueryHookResult = ReturnType<typeof useGetPaginatedVehiclesFromMyFavoritesSuspenseQuery>;
export type GetPaginatedVehiclesFromMyFavoritesQueryResult = Apollo.QueryResult<GetPaginatedVehiclesFromMyFavoritesQuery, GetPaginatedVehiclesFromMyFavoritesQueryVariables>;
export const GetPaginatedVehiclesFromMyOffersDocument = gql`
    query GetPaginatedVehiclesFromMyOffers($dto: GetPaginatedVehiclesFromMyOffersDto!) {
  paginatedVehiclesFromMyOffers(dto: $dto) {
    results {
      id
      status
      dealer {
        id
        name
      }
      immediatePurchase {
        enabled
        price
      }
      brand {
        name
      }
      model {
        name
      }
      attributes {
        attributeId
        value
        attributeValueId
      }
      frontSideThumbnailMedia {
        file {
          uri
        }
      }
      numberOfOffers
      addressToCollect {
        city
        country
      }
      addedByUserId
      publishedAt
      availableAt
    }
    total
  }
}
    `;

/**
 * __useGetPaginatedVehiclesFromMyOffersQuery__
 *
 * To run a query within a React component, call `useGetPaginatedVehiclesFromMyOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedVehiclesFromMyOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedVehiclesFromMyOffersQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useGetPaginatedVehiclesFromMyOffersQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedVehiclesFromMyOffersQuery, GetPaginatedVehiclesFromMyOffersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedVehiclesFromMyOffersQuery, GetPaginatedVehiclesFromMyOffersQueryVariables>(GetPaginatedVehiclesFromMyOffersDocument, options);
      }
export function useGetPaginatedVehiclesFromMyOffersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedVehiclesFromMyOffersQuery, GetPaginatedVehiclesFromMyOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedVehiclesFromMyOffersQuery, GetPaginatedVehiclesFromMyOffersQueryVariables>(GetPaginatedVehiclesFromMyOffersDocument, options);
        }
export function useGetPaginatedVehiclesFromMyOffersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPaginatedVehiclesFromMyOffersQuery, GetPaginatedVehiclesFromMyOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaginatedVehiclesFromMyOffersQuery, GetPaginatedVehiclesFromMyOffersQueryVariables>(GetPaginatedVehiclesFromMyOffersDocument, options);
        }
export type GetPaginatedVehiclesFromMyOffersQueryHookResult = ReturnType<typeof useGetPaginatedVehiclesFromMyOffersQuery>;
export type GetPaginatedVehiclesFromMyOffersLazyQueryHookResult = ReturnType<typeof useGetPaginatedVehiclesFromMyOffersLazyQuery>;
export type GetPaginatedVehiclesFromMyOffersSuspenseQueryHookResult = ReturnType<typeof useGetPaginatedVehiclesFromMyOffersSuspenseQuery>;
export type GetPaginatedVehiclesFromMyOffersQueryResult = Apollo.QueryResult<GetPaginatedVehiclesFromMyOffersQuery, GetPaginatedVehiclesFromMyOffersQueryVariables>;
export const GetVehicleSaleOrderFormDocument = gql`
    query GetVehicleSaleOrderForm($id: Int!) {
  vehicleSaleOrderForm(id: $id)
}
    `;

/**
 * __useGetVehicleSaleOrderFormQuery__
 *
 * To run a query within a React component, call `useGetVehicleSaleOrderFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleSaleOrderFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleSaleOrderFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVehicleSaleOrderFormQuery(baseOptions: Apollo.QueryHookOptions<GetVehicleSaleOrderFormQuery, GetVehicleSaleOrderFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVehicleSaleOrderFormQuery, GetVehicleSaleOrderFormQueryVariables>(GetVehicleSaleOrderFormDocument, options);
      }
export function useGetVehicleSaleOrderFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVehicleSaleOrderFormQuery, GetVehicleSaleOrderFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVehicleSaleOrderFormQuery, GetVehicleSaleOrderFormQueryVariables>(GetVehicleSaleOrderFormDocument, options);
        }
export function useGetVehicleSaleOrderFormSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetVehicleSaleOrderFormQuery, GetVehicleSaleOrderFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetVehicleSaleOrderFormQuery, GetVehicleSaleOrderFormQueryVariables>(GetVehicleSaleOrderFormDocument, options);
        }
export type GetVehicleSaleOrderFormQueryHookResult = ReturnType<typeof useGetVehicleSaleOrderFormQuery>;
export type GetVehicleSaleOrderFormLazyQueryHookResult = ReturnType<typeof useGetVehicleSaleOrderFormLazyQuery>;
export type GetVehicleSaleOrderFormSuspenseQueryHookResult = ReturnType<typeof useGetVehicleSaleOrderFormSuspenseQuery>;
export type GetVehicleSaleOrderFormQueryResult = Apollo.QueryResult<GetVehicleSaleOrderFormQuery, GetVehicleSaleOrderFormQueryVariables>;
export const GetVehicleToUpdateDocument = gql`
    query GetVehicleToUpdate($id: Int!) {
  vehicleById(id: $id) {
    id
    status
    addedByUserId
    addressId
    contactId
    immediatePurchase {
      enabled
      isVatExcluded
      price
    }
    brandId
    modelId
    availableAt
    attributes {
      id
      attributeId
      value
      attributeValueId
    }
    equipments {
      id
      equipmentId
    }
    damages {
      id
      damageId
    }
    medias {
      id
      fileId
      type
      tag
      file {
        id
        uri
      }
      vehicleDamageId
      vehicleEquipmentId
    }
  }
}
    `;

/**
 * __useGetVehicleToUpdateQuery__
 *
 * To run a query within a React component, call `useGetVehicleToUpdateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleToUpdateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleToUpdateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVehicleToUpdateQuery(baseOptions: Apollo.QueryHookOptions<GetVehicleToUpdateQuery, GetVehicleToUpdateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVehicleToUpdateQuery, GetVehicleToUpdateQueryVariables>(GetVehicleToUpdateDocument, options);
      }
export function useGetVehicleToUpdateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVehicleToUpdateQuery, GetVehicleToUpdateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVehicleToUpdateQuery, GetVehicleToUpdateQueryVariables>(GetVehicleToUpdateDocument, options);
        }
export function useGetVehicleToUpdateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetVehicleToUpdateQuery, GetVehicleToUpdateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetVehicleToUpdateQuery, GetVehicleToUpdateQueryVariables>(GetVehicleToUpdateDocument, options);
        }
export type GetVehicleToUpdateQueryHookResult = ReturnType<typeof useGetVehicleToUpdateQuery>;
export type GetVehicleToUpdateLazyQueryHookResult = ReturnType<typeof useGetVehicleToUpdateLazyQuery>;
export type GetVehicleToUpdateSuspenseQueryHookResult = ReturnType<typeof useGetVehicleToUpdateSuspenseQuery>;
export type GetVehicleToUpdateQueryResult = Apollo.QueryResult<GetVehicleToUpdateQuery, GetVehicleToUpdateQueryVariables>;
export const PublishVehicleDocument = gql`
    mutation PublishVehicle($id: Int!) {
  publishVehicle(id: $id) {
    id
  }
}
    `;
export type PublishVehicleMutationFn = Apollo.MutationFunction<PublishVehicleMutation, PublishVehicleMutationVariables>;

/**
 * __usePublishVehicleMutation__
 *
 * To run a mutation, you first call `usePublishVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishVehicleMutation, { data, loading, error }] = usePublishVehicleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishVehicleMutation(baseOptions?: Apollo.MutationHookOptions<PublishVehicleMutation, PublishVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishVehicleMutation, PublishVehicleMutationVariables>(PublishVehicleDocument, options);
      }
export type PublishVehicleMutationHookResult = ReturnType<typeof usePublishVehicleMutation>;
export type PublishVehicleMutationResult = Apollo.MutationResult<PublishVehicleMutation>;
export type PublishVehicleMutationOptions = Apollo.BaseMutationOptions<PublishVehicleMutation, PublishVehicleMutationVariables>;
export const RepublishVehicleDocument = gql`
    mutation RepublishVehicle($id: Int!) {
  republishVehicle(id: $id) {
    id
  }
}
    `;
export type RepublishVehicleMutationFn = Apollo.MutationFunction<RepublishVehicleMutation, RepublishVehicleMutationVariables>;

/**
 * __useRepublishVehicleMutation__
 *
 * To run a mutation, you first call `useRepublishVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRepublishVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [republishVehicleMutation, { data, loading, error }] = useRepublishVehicleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRepublishVehicleMutation(baseOptions?: Apollo.MutationHookOptions<RepublishVehicleMutation, RepublishVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RepublishVehicleMutation, RepublishVehicleMutationVariables>(RepublishVehicleDocument, options);
      }
export type RepublishVehicleMutationHookResult = ReturnType<typeof useRepublishVehicleMutation>;
export type RepublishVehicleMutationResult = Apollo.MutationResult<RepublishVehicleMutation>;
export type RepublishVehicleMutationOptions = Apollo.BaseMutationOptions<RepublishVehicleMutation, RepublishVehicleMutationVariables>;
export const SaveVehicleDocument = gql`
    mutation SaveVehicle($dto: SaveVehicleDto!) {
  saveVehicle(dto: $dto) {
    id
    damages {
      id
      damageId
    }
    equipments {
      id
      equipmentId
    }
  }
}
    `;
export type SaveVehicleMutationFn = Apollo.MutationFunction<SaveVehicleMutation, SaveVehicleMutationVariables>;

/**
 * __useSaveVehicleMutation__
 *
 * To run a mutation, you first call `useSaveVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveVehicleMutation, { data, loading, error }] = useSaveVehicleMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useSaveVehicleMutation(baseOptions?: Apollo.MutationHookOptions<SaveVehicleMutation, SaveVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveVehicleMutation, SaveVehicleMutationVariables>(SaveVehicleDocument, options);
      }
export type SaveVehicleMutationHookResult = ReturnType<typeof useSaveVehicleMutation>;
export type SaveVehicleMutationResult = Apollo.MutationResult<SaveVehicleMutation>;
export type SaveVehicleMutationOptions = Apollo.BaseMutationOptions<SaveVehicleMutation, SaveVehicleMutationVariables>;
export const SaveVehicleMediasDocument = gql`
    mutation SaveVehicleMedias($dto: SaveVehicleMediasDto!) {
  saveVehicleMedias(dto: $dto) {
    id
  }
}
    `;
export type SaveVehicleMediasMutationFn = Apollo.MutationFunction<SaveVehicleMediasMutation, SaveVehicleMediasMutationVariables>;

/**
 * __useSaveVehicleMediasMutation__
 *
 * To run a mutation, you first call `useSaveVehicleMediasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveVehicleMediasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveVehicleMediasMutation, { data, loading, error }] = useSaveVehicleMediasMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useSaveVehicleMediasMutation(baseOptions?: Apollo.MutationHookOptions<SaveVehicleMediasMutation, SaveVehicleMediasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveVehicleMediasMutation, SaveVehicleMediasMutationVariables>(SaveVehicleMediasDocument, options);
      }
export type SaveVehicleMediasMutationHookResult = ReturnType<typeof useSaveVehicleMediasMutation>;
export type SaveVehicleMediasMutationResult = Apollo.MutationResult<SaveVehicleMediasMutation>;
export type SaveVehicleMediasMutationOptions = Apollo.BaseMutationOptions<SaveVehicleMediasMutation, SaveVehicleMediasMutationVariables>;
export const SaveWebsiteEventDocument = gql`
    mutation SaveWebsiteEvent($dto: SaveEventDto!) {
  saveWebsiteEvent(dto: $dto) {
    id
  }
}
    `;
export type SaveWebsiteEventMutationFn = Apollo.MutationFunction<SaveWebsiteEventMutation, SaveWebsiteEventMutationVariables>;

/**
 * __useSaveWebsiteEventMutation__
 *
 * To run a mutation, you first call `useSaveWebsiteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveWebsiteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveWebsiteEventMutation, { data, loading, error }] = useSaveWebsiteEventMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useSaveWebsiteEventMutation(baseOptions?: Apollo.MutationHookOptions<SaveWebsiteEventMutation, SaveWebsiteEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveWebsiteEventMutation, SaveWebsiteEventMutationVariables>(SaveWebsiteEventDocument, options);
      }
export type SaveWebsiteEventMutationHookResult = ReturnType<typeof useSaveWebsiteEventMutation>;
export type SaveWebsiteEventMutationResult = Apollo.MutationResult<SaveWebsiteEventMutation>;
export type SaveWebsiteEventMutationOptions = Apollo.BaseMutationOptions<SaveWebsiteEventMutation, SaveWebsiteEventMutationVariables>;
export const NotificationFeedDocument = gql`
    subscription NotificationFeed {
  notificationFeed {
    results {
      id
      type
      status
      channel
      payload
      userId
      readAt
      createdAt
    }
    total
  }
}
    `;

/**
 * __useNotificationFeedSubscription__
 *
 * To run a query within a React component, call `useNotificationFeedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationFeedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationFeedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNotificationFeedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<NotificationFeedSubscription, NotificationFeedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NotificationFeedSubscription, NotificationFeedSubscriptionVariables>(NotificationFeedDocument, options);
      }
export type NotificationFeedSubscriptionHookResult = ReturnType<typeof useNotificationFeedSubscription>;
export type NotificationFeedSubscriptionResult = Apollo.SubscriptionResult<NotificationFeedSubscription>;
export const namedOperations = {
  Query: {
    GetAllUsersForAdmin: 'GetAllUsersForAdmin',
    GetSocialMediaTokenExpirations: 'GetSocialMediaTokenExpirations',
    GetAllAttributeCategories: 'GetAllAttributeCategories',
    GetAllAttributes: 'GetAllAttributes',
    GetAttribute: 'GetAttribute',
    login: 'login',
    LoginUnderUser: 'LoginUnderUser',
    validateToken: 'validateToken',
    GetAllBrands: 'GetAllBrands',
    GetAllDamageLocations: 'GetAllDamageLocations',
    GetAllDamages: 'GetAllDamages',
    GetMyDealer: 'GetMyDealer',
    GetOnePublicDealer: 'GetOnePublicDealer',
    GetAllEquipments: 'GetAllEquipments',
    GetAllOffersOfMyVehicle: 'GetAllOffersOfMyVehicle',
    GetMyOffersForVehicles: 'GetMyOffersForVehicles',
    Me: 'Me',
    ValidateRecoveryPasswordCode: 'ValidateRecoveryPasswordCode',
    GetMyPaginatedVehicles: 'GetMyPaginatedVehicles',
    GetOneVehicle: 'GetOneVehicle',
    GetPaginatedOpenedVehicles: 'GetPaginatedOpenedVehicles',
    GetPaginatedPublishedVehicles: 'GetPaginatedPublishedVehicles',
    GetPaginatedPublishedVehiclesByDealer: 'GetPaginatedPublishedVehiclesByDealer',
    GetPaginatedVehiclesFromMyFavorites: 'GetPaginatedVehiclesFromMyFavorites',
    GetPaginatedVehiclesFromMyOffers: 'GetPaginatedVehiclesFromMyOffers',
    GetVehicleSaleOrderForm: 'GetVehicleSaleOrderForm',
    GetVehicleToUpdate: 'GetVehicleToUpdate'
  },
  Mutation: {
    DeleteUserAsAdmin: 'DeleteUserAsAdmin',
    ManageUserSubscription: 'ManageUserSubscription',
    PublishVehicleOnSocialMediaRandomly: 'PublishVehicleOnSocialMediaRandomly',
    RefreshPublishedVehiclesInCache: 'RefreshPublishedVehiclesInCache',
    ResetFacebookAccessToken: 'ResetFacebookAccessToken',
    ResetLinkedinAccessToken: 'ResetLinkedinAccessToken',
    RunImports: 'RunImports',
    RunWebsiteVehicleMediaGenerationJob: 'RunWebsiteVehicleMediaGenerationJob',
    DeleteAttribute: 'DeleteAttribute',
    DeleteAttributeValue: 'DeleteAttributeValue',
    SaveAttribute: 'SaveAttribute',
    SaveAttributeCategory: 'SaveAttributeCategory',
    SaveBrand: 'SaveBrand',
    DeleteDamage: 'DeleteDamage',
    DeleteDamageLocation: 'DeleteDamageLocation',
    SaveDamage: 'SaveDamage',
    SaveDamageLocation: 'SaveDamageLocation',
    SaveDealer: 'SaveDealer',
    SaveDealerAddress: 'SaveDealerAddress',
    SaveDealerContact: 'SaveDealerContact',
    SaveDealerLogo: 'SaveDealerLogo',
    DeleteEquipment: 'DeleteEquipment',
    SaveEquipment: 'SaveEquipment',
    SaveModel: 'SaveModel',
    MarkInAppNotificationsAsRead: 'MarkInAppNotificationsAsRead',
    AcceptOffer: 'AcceptOffer',
    CancelOffer: 'CancelOffer',
    DeclineOffer: 'DeclineOffer',
    SaveOffer: 'SaveOffer',
    AskToBecomeClient: 'AskToBecomeClient',
    AskToBecomeSeller: 'AskToBecomeSeller',
    ChangeMyLanguage: 'ChangeMyLanguage',
    ChangeMyPassword: 'ChangeMyPassword',
    Register: 'Register',
    SaveUser: 'SaveUser',
    ValidateUserAccount: 'ValidateUserAccount',
    AddVehicleToFavorites: 'AddVehicleToFavorites',
    DeleteVehicleFromFavorites: 'DeleteVehicleFromFavorites',
    AddUserHiddenVehicle: 'AddUserHiddenVehicle',
    AddUserOpenedVehicle: 'AddUserOpenedVehicle',
    RateSomeone: 'RateSomeone',
    AskRecoveryPasswordCode: 'AskRecoveryPasswordCode',
    RecoverPassword: 'RecoverPassword',
    CancelVehicle: 'CancelVehicle',
    DeleteOneVehicleMedia: 'DeleteOneVehicleMedia',
    FinalizeVehicleSale: 'FinalizeVehicleSale',
    GenerateVehicleMediaThumbnails: 'GenerateVehicleMediaThumbnails',
    PublishVehicle: 'PublishVehicle',
    RepublishVehicle: 'RepublishVehicle',
    SaveVehicle: 'SaveVehicle',
    SaveVehicleMedias: 'SaveVehicleMedias',
    SaveWebsiteEvent: 'SaveWebsiteEvent'
  },
  Subscription: {
    NotificationFeed: 'NotificationFeed'
  }
}