import { ReactNode, useEffect } from 'react';
import { useSaveWebsiteEventMutation } from '../../graphql';
import useLocationQueryParameters from '../../hooks/useLocationQueryParameters.ts';

interface Props {
  children: ReactNode,
}

const WebsiteEventProvider = ({ children }: Props) => {
  const [ saveWebsiteEvent ] = useSaveWebsiteEventMutation();
  const { parameters, getQueryParamByKey } = useLocationQueryParameters();

  useEffect(() => {
    const websiteEvent = getQueryParamByKey('w');

    if (websiteEvent) {
      saveWebsiteEvent({
        variables: {
          dto: {
            name: 'website_to_platform',
            payload: {
              w: websiteEvent,
              location: window.location,
              userAgent: window.navigator.userAgent,
            },
          },
        },
      });
    }
  }, [ parameters ]);

  return (
    <>{ children }</>
  );
};

export default WebsiteEventProvider;
