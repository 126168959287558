import { ReactNode } from 'react';
import { PhotoProvider } from 'react-photo-view';
import AutoLoginProvider from './providers/AutoLoginProvider.tsx';
import OfflineDialogsProvider from './providers/OfflineDialogsProvider.tsx';

interface Props {
  children: ReactNode,
}

const Route = ({ children }: Props) => (
  <AutoLoginProvider>
    <PhotoProvider>
      <OfflineDialogsProvider>
        { children }
      </OfflineDialogsProvider>
    </PhotoProvider>
  </AutoLoginProvider>
);

export default Route;
