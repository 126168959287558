import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Typography } from '@mui/material';
import { ReactNode, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import className from '../../../helpers/className.ts';
import useReactiveUserConnected from '../../../hooks/data/useReactiveUserConnected.ts';
import NotificationSubscriptionProvider from '../../providers/NotificationSubscriptionProvider.tsx';
import { OfflineDialogsContext } from '../../providers/OfflineDialogsContext.ts';
import VehicleDetailsDialogProvider from '../../providers/VehicleDetailsDialogProvider.tsx';
import Header from './Header.tsx';
import HStack from './HStack.tsx';
import LeftMenu from './LeftMenu.tsx';
import VStack from './VStack.tsx';
import '../../../styles/utils/trial.scss';

interface Props {
  id?: string,
  children: ReactNode,
}

const Page = ({ id, children }: Props) => {
  const { t } = useTranslation();
  const { refetchMe, isConnected } = useReactiveUserConnected();
  const { openRegisterDialog } = useContext(OfflineDialogsContext);

  useEffect(() => {
    if (isConnected) {
      refetchMe();
    }
  }, [ isConnected ]);

  return (
    <NotificationSubscriptionProvider>
      <VehicleDetailsDialogProvider>
        <div id={id} className={className('', { 'trial-enabled': !isConnected, 'logged-in': isConnected })}>
          <HStack id="trial-banner" dangerouslySetInnerHTML={{ __html: t('offline.trial-banner') }} onClick={openRegisterDialog} bgcolor="gold" width="100%" height={42} top={0} position="fixed" justifyContent="center" zIndex={1001} color="#030b21" fontWeight="bold" alignItems="center" sx={{ cursor: 'pointer', boxShadow: '0px 9px 20px 4px #ffd70069' }} />

          <Header />

          <main style={{ position: 'relative' }}>
            <HStack className="main-h-stack" alignItems="flex-start" padding="0 2rem" mt={2}>
              <LeftMenu />
              <HStack className="content-container-h-stack" flex={1} minHeight="calc(100vh - 260px)" padding="1rem 2rem" bgcolor="#f4f4f4" borderRadius={3}>
                <div id="content">
                  { children }
                </div>
              </HStack>
            </HStack>
            <footer style={{ position: 'relative', paddingBottom: 10 }}>
              <VStack className="version" width="20%" alignItems="center" gap={1}>
                <Typography fontWeight="bold">Version 1.0</Typography>
                <HStack alignItems="center" gap={1}>
                  <Link to="https://www.facebook.com/profile.php?id=61556254056623" target="_blank">
                    <FacebookIcon />
                  </Link>
                  <Link to="https://www.linkedin.com/company/autoconnect-solutions/" target="_blank">
                    <LinkedInIcon />
                  </Link>
                </HStack>
              </VStack>
              <HStack alignItems="center" flex={1} justifyContent="flex-end" gap={5}>
                <Link to="https://autoconnect-solutions.com/documents/fr/privacy.pdf" target="_blank">Privacy</Link>
                <Link to="https://autoconnect-solutions.com/documents/fr/cgu.pdf" target="_blank">CGU</Link>
              </HStack>
            </footer>
          </main>
        </div>
      </VehicleDetailsDialogProvider>
    </NotificationSubscriptionProvider>
  );
};

export default Page;
