import { useReactiveVar } from '@apollo/client';
import { ReactNode } from 'react';
import { isAppInitializedVar } from '../../graphql';
import PageLoader from '../ui-kit/utils/PageLoader.tsx';

interface Props {
  children: ReactNode,
}

const PublicGuard = ({ children }: Props) => {
  const isAppInitialized = useReactiveVar(isAppInitializedVar);

  if (!isAppInitialized) {
    return (
      <>
        <PageLoader />
      </>
    );
  }

  return (
    <>
      { children }
    </>
  );
};

export default PublicGuard;
