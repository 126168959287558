import AirlineSeatReclineExtraIcon from '@mui/icons-material/AirlineSeatReclineExtra';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import PanoramaIcon from '@mui/icons-material/Panorama';
import {
  Alert,
  Button, Dialog, Divider, IconButton, Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import {
  Fragment, ReactNode, useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { PhotoView } from 'react-photo-view';
import { Link } from 'react-router-dom';
import {
  AttributeType,
  useAddUserOpenedVehicleMutation,
  useGetOneVehicleQuery,
  User,
  Vehicle,
  VehicleMedia,
  VehicleMediaTag,
  VehicleMediaType,
} from '../../../../../../graphql';
import { inArray } from '../../../../../../helpers/array.ts';
import className from '../../../../../../helpers/className.ts';
import useReactiveUserConnected from '../../../../../../hooks/data/useReactiveUserConnected.ts';
import useLang from '../../../../../../hooks/useLang.ts';
import { OfflineDialogsContext } from '../../../../../providers/OfflineDialogsContext.ts';
import CloseButton from '../../../../../ui-kit/buttons/CloseButton.tsx';
import DialogTitle from '../../../../../ui-kit/dialog/DialogTitle.tsx';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../ui-kit/layout/VStack.tsx';
import PageLoader from '../../../../../ui-kit/utils/PageLoader.tsx';
import DealerCard from '../../../cards/DealerCard.tsx';
import UserCard from '../../../cards/UserCard.tsx';
import './VehicleDetailsDialog.scss';
import CarVerticalBanner from './CarVerticalBanner.tsx';

interface TitleProps {
  text: ReactNode,
}

const Title = ({ text }: TitleProps) => (
  <h2 style={{
    borderLeft: '0.25rem solid #cf0e10',
    paddingLeft: '1rem',
    fontSize: '1.6rem',
  }}
  >
    { text }
  </h2>
);

interface Props {
  vehicle: Vehicle,
  onClose: () => void,
}

const VehicleDetailsDialog = ({
  vehicle, onClose,
}: Props) => {
  const { t } = useTranslation();
  const lang = useLang();
  const { isConnected, user, refetchMe } = useReactiveUserConnected();
  const { data, loading } = useGetOneVehicleQuery({ variables: { id: vehicle.id } });
  const [ areEquipmentsVisible, setAreEquipmentsVisible ] = useState(false);
  const [ areDamagesVisible, setAreDamagesVisible ] = useState(false);
  const medias: VehicleMedia[] = useMemo(
    () => (data ? data.vehicleById.medias! as VehicleMedia[] : []),
    [ data ],
  );
  const frontSidePhoto = useMemo(
    () => medias.find((media) => media.type === VehicleMediaType.Image && media.tag === 'FRONT_SIDE'),
    [ medias ],
  );
  const [ addUserOpenedVehicle ] = useAddUserOpenedVehicleMutation();
  const { openLoginDialog } = useContext(OfflineDialogsContext);

  useEffect(() => {
    (async () => {
      if (!data) {
        return;
      }

      if (data?.vehicleById.addedByUserId === user!.id) {
        return;
      }

      await addUserOpenedVehicle({
        variables: {
          vehicleId: vehicle.id,
        },
      });

      refetchMe();
    })();
  }, [ data ]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <Dialog
      id="vehicle-details-dialog"
      onClose={onClose}
      open
      scroll="body"
      // @ts-ignore
      maxWidth="md"
      disableEscapeKeyDown
    >
      <VStack width={600} p={5}>
        <HStack justifyContent="space-between" alignItems="center">
          <DialogTitle
            loading={loading}
            text={(
              <VStack>
                <span style={{
                  color: '#989898',
                  fontSize: 16,
                }}
                >
                  {t('dictionary.id')
                    .toUpperCase()}
                  :
                  {' '}
                  {vehicle.id}
                </span>

                {`${data?.vehicleById.brand!.name || ''} ${data?.vehicleById.model!.name || ''}`}

                {/* { vehicle.status === 'PUBLISHED' && <RemainingTimer vehicle={vehicle} /> } */}
              </VStack>
            )}
          />
          <CloseButton onClose={onClose} />
        </HStack>

        {
          data && data.vehicleById.status !== 'PUBLISHED' && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              <strong>{t('alerts.vehicle-is-not-on-sale-anymore')}</strong>
              <br />
              <br />
              {t('alerts.discover-other-vehicles-on-our-marketplace')}
              <br />
              <br />
              <strong style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={onClose}>{t('buttons.click-here-to-see-other-vehicles')}</strong>
            </Alert>
          )
        }

        <VStack width="100%" mb={2} id="vehicle-photos">
          {
            frontSidePhoto && frontSidePhoto.file && (
              <PhotoView src={frontSidePhoto.file.uri}>
                <img src={frontSidePhoto.file.uri} style={{ maxWidth: '100%' }} />
              </PhotoView>
            )
          }

          <HStack flexWrap="wrap" gap={1} mt={1}>
            {
              medias.filter((media) => !media.tag.includes('FRONT_SIDE') && !media.tag.includes('WEBSITE') && (media.tag.includes('_THUMBNAIL') || media.tag.includes('INTERIOR') || media.tag.includes('NONE')) && media.file !== undefined && media.file !== null)
                .map((media) => (
                  <PhotoView
                    src={(media.tag.includes('INTERIOR') || media.tag.includes('NONE')) ? media.file!.uri : medias.find((mediaRelatedToThumbnail) => mediaRelatedToThumbnail.tag === media.tag.replace('_THUMBNAIL', ''))?.file?.uri}
                    key={media.id}
                  >
                    <img src={media.file!.uri} style={{ maxWidth: 100, maxHeight: '75px' }} />
                  </PhotoView>
                ))
            }
            {
              medias.filter((media) => media.type === 'VIDEO' && media.file !== undefined && media.file !== null)
                .map((media) => (
                  <video
                    key={media.id}
                    src={media.file!.uri}
                    style={{ maxHeight: 100 }}
                    controls
                  />
                ))
            }
          </HStack>
        </VStack>

        {
          isConnected && (
            <VStack>
              <Title
                text={(
                  <HStack alignItems="center" gap={1}>
                    <EmojiTransportationIcon sx={{ fontSize: 40 }} />
                    {t('dialogs.vehicle-details.about-seller')}
                  </HStack>
                )}
              />

              {data && data.vehicleById.addedByUser
                && <DealerCard user={data.vehicleById.addedByUser as User} ratingEnabled />}
              {data && data.vehicleById.contact
                && (
                  <UserCard
                    user={data.vehicleById.contact as User}
                    topTitle={t('my-vehicle.dealer-contact')}
                    fullNameEnabled
                  />
                )}
            </VStack>
          )
        }

        <CarVerticalBanner />

        <VStack mt={2}>
          <Title
            text={(
              <HStack alignItems="center" gap={1}>
                <DirectionsCarIcon sx={{ fontSize: 40 }} />
                {t('dictionary.characteristics')}
              </HStack>
            )}
          />

          <HStack justifyContent="space-between" alignItems="center" my={1}>
            <Typography fontWeight="bolder">{t('dialogs.vehicle-details.vehicle-id')}</Typography>
            <Typography textAlign="right">{vehicle.id}</Typography>
          </HStack>
          <Divider />

          {
            data && data.vehicleById.availableAt && (
              <>
                <HStack justifyContent="space-between" alignItems="center" my={1}>
                  <Typography fontWeight="bolder">{t('dictionary.availability-date')}</Typography>
                  <Typography textAlign="right">
                    { `${dayjs(data?.vehicleById.availableAt) > dayjs() ? dayjs(data?.vehicleById.availableAt).format('DD/MM/YYYY') : t('dictionary.available')}` }
                  </Typography>
                </HStack>
                <Divider />
              </>
            )
          }

          {/* { */}
          {/*   addressToCollect && ( */}
          {/*     <> */}
          {/*       <HStack justifyContent="space-between" alignItems="center" my={1}> */}
          {/*         <Typography fontWeight="bolder">{t('dialogs.vehicle-details.vehicle-emplacement')}</Typography> */}
          {/*         <a */}
          {/*           href={`https://www.google.com/maps/place/${addressToCollect.street}+${addressToCollect.number}+${addressToCollect.zip}+${addressToCollect.city}+${addressToCollect.country}`} */}
          {/*           target="_blank" */}
          {/*           rel="noreferrer" */}
          {/*           style={{ color: colors.red }} */}
          {/*           title="Ouvrir dans Google Maps" */}
          {/*         > */}
          {/*           <Typography textAlign="right"> */}
          {/*             {`${addressToCollect.street} ${addressToCollect.number},`} */}
          {/*             <br /> */}
          {/*             {`${addressToCollect.zip} ${addressToCollect.city}`} */}
          {/*             <br /> */}
          {/*             {`${addressToCollect.country}`} */}
          {/*           </Typography> */}
          {/*         </a> */}
          {/*       </HStack> */}
          {/*       <Divider /> */}
          {/*     </> */}
          {/*   ) */}
          {/* } */}

          {
            data && data.vehicleById.attributes!.map((vehicleAttribute) => (
              <Fragment key={vehicleAttribute.id}>
                <HStack justifyContent="space-between" alignItems="center" my={1}>
                  <Typography fontWeight="bolder">{vehicleAttribute.attribute?.name[lang]}</Typography>
                  <Typography>
                    {inArray(vehicleAttribute.attribute!.type, [ AttributeType.Number, AttributeType.Text ]) && vehicleAttribute.value}
                    {vehicleAttribute.attribute!.type === AttributeType.Boolean && (vehicleAttribute.value === true ? t('dictionary.yes') : t('dictionary.no'))}
                    {vehicleAttribute.attribute!.type === AttributeType.Select && vehicleAttribute.attributeValue && (vehicleAttribute.attributeValue.value[lang])}
                    {vehicleAttribute.attribute!.type === AttributeType.Date && dayjs(vehicleAttribute.value).isValid() && dayjs(vehicleAttribute.value).format('DD/MM/YYYY') }
                    {vehicleAttribute.attribute!.type === AttributeType.Date && !dayjs(vehicleAttribute.value).isValid() && 'Non définie'}
                  </Typography>
                </HStack>
                <Divider />
              </Fragment>
            ))
          }
        </VStack>

        {
          data && data.vehicleById.equipments!.length > 0 && (
            <VStack my={2}>
              <Title
                text={(
                  <HStack alignItems="center" gap={1}>
                    <AirlineSeatReclineExtraIcon sx={{ fontSize: 40 }} />
                    {t('dictionary.equipments')}
                    {' '}
                    (
                    {data.vehicleById.equipments!.length}
                    )
                  </HStack>
                )}
              />

              <HStack
                alignItems="flex-start"
                flexWrap="wrap"
                className={className('', {
                  'collapsable-h-stack': true,
                  full: data.vehicleById.equipments!.length < 12 || areEquipmentsVisible,
                })}
              >
                {
                  data.vehicleById.equipments!.map((vehicleEquipment) => {
                    const media = medias.find((mediaToFind) => mediaToFind.tag === VehicleMediaTag.Equipment && mediaToFind.vehicleEquipmentId === vehicleEquipment.id);

                    return (
                      <HStack
                        alignItems="center"
                        justifyContent="space-between"
                        className="vehicle-equipment"
                        style={{ flexBasis: 'calc(50% - 10px)' }}
                        key={vehicleEquipment.id}
                        my={1}
                      >
                        <Typography fontWeight="bolder">{vehicleEquipment.equipment!.name[lang]}</Typography>
                        {
                          media && media.file && (
                            <IconButton color="primary">
                              <PhotoView src={media.file.uri}>
                                <PanoramaIcon />
                              </PhotoView>
                            </IconButton>
                          )
                        }
                      </HStack>
                    );
                  })
                }
              </HStack>

              {
                data.vehicleById.equipments!.length >= 12 && (
                  <HStack justifyContent="center" mt={2}>
                    <Button onClick={() => setAreEquipmentsVisible(!areEquipmentsVisible)}>
                      {areEquipmentsVisible ? t('buttons.reduce-content') : t('buttons.see-all')}
                    </Button>
                  </HStack>
                )
              }
            </VStack>
          )
        }

        {
          data && (data.vehicleById.damages!.length > 0 || data.vehicleById.expertiseDocumentMedia) && (
            <VStack my={2}>
              <Title
                text={(
                  <HStack alignItems="center" gap={1}>
                    <CarCrashIcon sx={{ fontSize: 40 }} />
                    {t('dictionary.damages')}
                    { `${data.vehicleById.expertiseDocumentMedia && data.vehicleById.damages!.length === 0 ? '' : `(${data.vehicleById.damages!.length})`}` }
                  </HStack>
                )}
              />

              <HStack
                alignItems="flex-start"
                flexWrap="wrap"
                className={className('', {
                  'collapsable-h-stack': true,
                  full: data.vehicleById.damages!.length < 12 || areDamagesVisible,
                })}
              >
                {
                  data.vehicleById.damages!.map((vehicleDamage) => {
                    const media = medias.find((mediaToFind) => mediaToFind.tag === VehicleMediaTag.Damage && mediaToFind.vehicleDamageId === vehicleDamage.id);

                    return (
                      <HStack
                        alignItems="center"
                        justifyContent="space-between"
                        className="vehicle-damage"
                        style={{ flexBasis: 'calc(50% - 10px)' }}
                        key={vehicleDamage.id}
                        my={1}
                      >
                        <Typography fontWeight="bolder">{vehicleDamage.damage!.name[lang]}</Typography>
                        {
                          media && media.file && (
                            <IconButton color="primary">
                              <PhotoView src={media.file.uri}>
                                <PanoramaIcon />
                              </PhotoView>
                            </IconButton>
                          )
                        }
                      </HStack>
                    );
                  })
                }
              </HStack>

              {
                data.vehicleById.damages!.length >= 12 && (
                  <HStack justifyContent="center" mt={2}>
                    <Button onClick={() => setAreDamagesVisible(!areDamagesVisible)}>
                      {areDamagesVisible ? t('buttons.reduce-content') : t('buttons.see-all')}
                    </Button>
                  </HStack>
                )
              }

              {
                data.vehicleById.expertiseDocumentMedia && (
                  <HStack justifyContent="center">
                    {
                      isConnected && (
                        <Button
                          component={Link}
                          to={data.vehicleById.expertiseDocumentMedia.file!.uri}
                          target="_blank"
                          variant="outlined"
                        >
                          Ouvrir le fichier d'expertise
                        </Button>
                      )
                    }

                    {
                      !isConnected && (
                        <Button variant="outlined" onClick={openLoginDialog}>
                          Ouvrir le fichier d'expertise
                        </Button>
                      )
                    }
                  </HStack>
                )
              }
            </VStack>
          )
        }

      </VStack>
    </Dialog>
  );
};

export default VehicleDetailsDialog;
