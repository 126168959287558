import { Dialog } from '@mui/material';
import { useContext } from 'react';
import { OfflineDialogsContext } from '../../../../providers/OfflineDialogsContext.ts';
import VStack from '../../../../ui-kit/layout/VStack.tsx';
import LoginForm from './LoginForm.tsx';

interface Props {
  onClose: () => void,
}

const LoginDialog = ({
  onClose,
}: Props) => {
  const { openForgottenPasswordDialog } = useContext(OfflineDialogsContext);

  return (
    <Dialog
      id="login-dialog"
      onClose={onClose}
      open
      scroll="body"
      // @ts-ignore
      maxWidth="md"
      disableEscapeKeyDown
    >
      <VStack width={450} p={5}>
        <LoginForm
          onCancel={onClose}
          onLoggedIn={onClose}
          onForgotPasswordClick={() => {
            onClose();
            openForgottenPasswordDialog();
          }}
        />
      </VStack>
    </Dialog>
  );
};

export default LoginDialog;
