import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../../../config/theming.ts';
import { RegisterDto, User, UserGender } from '../../../../../graphql';
import { generatePassword } from '../../../../../helpers/password-generator.ts';
import HStack from '../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../ui-kit/layout/VStack.tsx';

function createDto(user?: User): RegisterDto {
  return {
    email: user?.email || '',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    gender: user?.gender || UserGender.Mr,
    phoneNumber: user?.phoneNumber || '',
    hasAcceptedPrivacyPolicy: false,
    hasAcceptedTermsAndConditions: false,
  };
}

interface Props {
  onChange: (dto: RegisterDto|null) => void,
}

const RegisterFormCreateProfile = ({ onChange }: Props) => {
  const { t } = useTranslation();
  const [ showPassword, setShowPassword ] = useState(false);
  const [ dto, setDto ] = useState<RegisterDto>(createDto());

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleGeneratePassword = () => {
    setDto({ ...dto, password: generatePassword(12) });
  };

  useEffect(() => {
    onChange(dto);
  }, [ dto ]);

  return (
    <VStack className="v-stack-form" alignItems="center" mt={5} px={5}>
      <VStack width="100%" maxWidth={425}>
        <Typography fontWeight="bolder">
          {t('form.labels.email')}
          <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          sx={{ width: '100%' }}
          value={dto.email}
          onChange={({ target: { value: email } }) => setDto({ ...dto, email: email.trim() })}
        />
      </VStack>

      <VStack width="100%" maxWidth={425} mt={2}>
        <Typography fontWeight="bolder">
          {t('form.labels.password')}
          <span style={{ color: 'red' }}>*</span>
        </Typography>

        <OutlinedInput
          size="small"
          value={dto.password}
          type={showPassword ? 'text' : 'password'}
          onChange={({ target: { value: password } }) => setDto({ ...dto, password })}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )}
        />

        <HStack justifyContent="flex-end" mt={1}>
          <Button onClick={handleGeneratePassword} size="small">{t('buttons.generate-password')}</Button>
        </HStack>
      </VStack>

      <VStack width="100%" maxWidth={425} mt={2}>
        <Typography fontWeight="bolder">
          {t('form.labels.civility')}
          <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          select
          variant="outlined"
          size="small"
          sx={{ width: '100%' }}
          value={dto.gender}
          onChange={({ target: { value: gender } }) => setDto({ ...dto, gender: gender as UserGender })}
        >
          {
            [ UserGender.Mr, UserGender.Mrs, UserGender.Miss ].map((gender) => (
              <MenuItem value={gender} key={gender}>{ t(`enums.civilities.${gender}`) }</MenuItem>
            ))
          }
        </TextField>

        <VStack width="100%" maxWidth={425} mt={2}>
          <Typography fontWeight="bolder">
            {t('form.labels.lastname')}
            <span style={{ color: 'red' }}>*</span>
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            sx={{ width: '100%' }}
            value={dto.lastName}
            onChange={({ target: { value: lastName } }) => setDto({ ...dto, lastName })}
          />
        </VStack>

        <VStack width="100%" maxWidth={425} mt={2}>
          <Typography fontWeight="bolder">
            {t('form.labels.firstname')}
            <span style={{ color: 'red' }}>*</span>
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            sx={{ width: '100%' }}
            value={dto.firstName}
            onChange={({ target: { value: firstName } }) => setDto({ ...dto, firstName })}
          />
        </VStack>

        <VStack width="100%" maxWidth={425} mt={2}>
          <Typography fontWeight="bolder">
            {t('form.labels.phone-number')}
            <span style={{ color: 'red' }}>*</span>
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            sx={{ width: '100%' }}
            value={dto.phoneNumber}
            onChange={({ target: { value: phoneNumber } }) => setDto({ ...dto, phoneNumber: phoneNumber.trim() })}
          />
        </VStack>

        <VStack width="100%" maxWidth={425} mt={2}>
          <FormControlLabel
            control={<Checkbox onChange={(_e, hasAcceptedPrivacyPolicy) => setDto({ ...dto, hasAcceptedPrivacyPolicy })} />}
            label={(
              <>
                {t('offline.registration.i-agree')}
                { ' ' }
                <a href="https://autoconnect-solutions.com/documents/fr/privacy.pdf" target="_blank" rel="noreferrer" style={{ color: colors.secondary.main, textDecoration: 'none' }}>{t('offline.registration.privacy-policy')}</a>
              </>
            )}
          />
          <FormControlLabel
            control={<Checkbox onChange={(_e, hasAcceptedTermsAndConditions) => setDto({ ...dto, hasAcceptedTermsAndConditions })} />}
            label={(
              <>
                {t('offline.registration.i-agree')}
                { ' ' }
                <a href="https://autoconnect-solutions.com/documents/fr/cgu.pdf" target="_blank" rel="noreferrer" style={{ color: colors.secondary.main, textDecoration: 'none' }}>{t('offline.registration.terms-and-conditions')}</a>
              </>
            )}
          />
        </VStack>
      </VStack>
    </VStack>
  );
};

export default RegisterFormCreateProfile;
