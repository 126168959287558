import { Dialog } from '@mui/material';
import { useSaveWebsiteEventMutation } from '../../../../../graphql';
import VStack from '../../../../ui-kit/layout/VStack.tsx';
import RegisterForm from './RegisterForm.tsx';

interface Props {
  onClose: () => void,
}

const LoginDialog = ({
  onClose,
}: Props) => {
  const [ saveWebsiteEvent ] = useSaveWebsiteEventMutation();

  return (
    <Dialog
      id="register-dialog"
      onClose={() => {
        onClose();

        saveWebsiteEvent({
          variables: {
            dto: {
              name: 'register_close',
              payload: {
                location: window.location,
                userAgent: window.navigator.userAgent,
              },
            },
          },
        });
      }}
      open
      scroll="body"
      // @ts-ignore
      maxWidth="md"
      disableEscapeKeyDown
    >
      <VStack width={728} p={5}>
        <RegisterForm
          onCancel={onClose}
          onLoggedIn={onClose}
        />
      </VStack>
    </Dialog>
  );
};

export default LoginDialog;
